import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AnalyticnHIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/Analytics head icon.png'
import GridIconOn from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/grid icon on.png'
import GridIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/grid icon off.png'
import ListIconOn from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/list icon on.png'
import ListIconOff from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/list icon off.png'
import RankIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/rank icon.png'
import AccuracyIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/accurecy icon.png'
import ScoreIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/score icon.png'
import AttemptIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/attemped icon.png'
// Activities
import EditIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/edit icon.png'
import DeleteIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/delete icon.png'
// Course Chart
import ChartAccurancy from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/Accuracy dashboard.png'
import ChartHardest from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/Hardest Questions dashboard.png'
import ChartEasiest from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/Easiest Questions dashboard.png'
import ChartComplished from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/Complished dashboard.png'
import sadDog from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/AnalyticsCourse/sadDog.png'

import { useRef } from 'react';
import Chart from 'chart.js/auto';
import LoginHeading from '../../../../../LoginHeading/LoginHeading';

const BarChart = ({ days, gamesPlayed, players }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    // Check if there is an existing chart instance
    if (chartInstance.current) {
      // Destroy the existing chart instance
      chartInstance.current.destroy();
    }

    // Initialize Chart.js with line chart configuration
    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels: days,
        datasets: [
          {
            label: 'Game Played', // Label for the first data set
            data: gamesPlayed, // First set of values
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255, 99, 132, 1)',
            yAxisID: 'y',
            pointRadius: 3,
            borderWidth: 5,
            tension: 0.4,
          },
          {
            label: 'Players', // Label for the second data set
            data: players, // Second set of values
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            pointRadius: 3,
            borderWidth: 5,
            tension: 0.4,
            yAxisID: 'y1',
            borderRadius: '25px',
            tension: 0.4,
          }
        ]
      },
      options: {
        plugins: {
          legend: {
            labels: {
              pointStyle: 'circle', // Set the desired shape of the labels to 'circle'
            }
          }
        },
        responsive: true,
        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,
        scales: {
          y: {
            type: 'linear',
            display: true,
            position: 'left',
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'right',

            // grid line settings
            grid: {
              drawOnChartArea: false, // only want the grid lines for one axis to show up
              display: false,
            },
          },
          x: {
            grid: {
              display: false
            }
          },
        }
      }
    });

    // Cleanup function to destroy the chart instance when component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [days, gamesPlayed, players]);

  return <canvas ref={chartRef} />
};

  // Get students analytics values from endpoint
  const AnalyticsComponent = ({ student, selectedCourse ,viewType, gridOff, gridOn}) => {
    const [analyticsData, setAnalyticsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // formate date 
    function formatTime(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
  
      return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m:${String(
        Math.floor(remainingSeconds)
      ).padStart(2, '0')}s`;
    }
  
    useEffect(() => {
      const fetchData = async (student_id, course_id) => {
        try {
          const response = await fetch(`https://gamizign.co:8000/analytics/courses/students/${student_id}?course_id=${course_id}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          return data;
        } catch (error) {
          throw error;
        }
      };
  
      const fetchAnalyticsData = async () => {
        try {
          const data = await fetchData(student.student_id, selectedCourse.course_id);
          setAnalyticsData(data);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchAnalyticsData();
    }, [student.student_id, selectedCourse.course_id]);
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error.message}</div>;
    }
  
    return (
     <div>
      {/* Grid view */}
      <div>
      <div className={`bg-gray-100 p-5 ${viewType === gridOn ? '' : 'hidden'}`} style={{ borderRadius: '25px' }}>
        <div className='flex'>
          <div>
            <p><b>{student.firstName}</b></p>
            <b>{student.lastName}</b>
          </div>
          <div className='grid sm:grid-cols-4 gap-3 bg-white w-full p-3 ms-5' style={{ borderRadius: '20px' }}>
            {/* Rank of player */}
            <div className='mt-1.5 flex justify-center'>
              {/* <div className='relative'><img className='' width={40} src={RankIcon} alt="" /><span className='absolute inset-0 mt-2 ms-3' style={{}}>{student.grade}</span></div> */}
            </div>
            {/* Accurancy */}
            <div className=''>
              <div className='flex justify-center'><img src={AccuracyIcon} alt="" /></div>
              <div className='flex justify-center'>
                {analyticsData.total_correctAnswers > 0 ? (
                  <div>{((analyticsData.total_correctAnswers/(analyticsData.total_correctAnswers + analyticsData.total_incorrectAnswers))*100).toFixed(1)}%</div>
                ) : <>0.0%</>}
              </div>
            </div>
            {/* Score */}
            <div className=''>
              <div className='flex justify-center'><img src={ScoreIcon} alt="" /></div>
              <div className='flex justify-center'>
                {analyticsData ? (
                  <div>{analyticsData.total_score}</div>
                ) : <>0</>}
              </div>
            </div>
            {/* Sessions */}
            <div className=''>
              <div className='flex justify-center'><img src={AttemptIcon} alt="" /></div>
              <div className='flex justify-center'>
                {analyticsData ? (
                  <div>{analyticsData.total_sessions}</div>
                ) : <>0</>}
              </div>
            </div>
          </div>
        </div>
        <p className='flex justify-center mt-2'>{student.email}</p>
      </div>
      </div>
     
     </div>
    );
  };





const CourseAnalytics = () => {

  const [SelectedCourse, setSelectedCourse] = useState(null)
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getCourses = async () => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        setLoading(true);

        const response = await fetch(`https://gamizign.co:8000/courses/${course_id}/`, requestOptions);
        const data = await response.json();
        setSelectedCourse(data);
        setfilteredStudent(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching courses:', error);
        // Handle the error if needed
      } finally {
        setLoading(false);
      }
    };
    getCourses();
  }, []);


  // Function to flatten analyticsList arrays
  function flattenAnalyticsList(data) {
    let analytics = [];
    if (Array.isArray(data)) {
      data.forEach((item) => {
        if (item.analyticsList) {
          analytics = analytics.concat(item.analyticsList);
        }
        if (item.activities) {
          analytics = analytics.concat(flattenAnalyticsList(item.activities));
        }
      });
    }
    return analytics;
  }
  const flattenedAnalytics = SelectedCourse?.stages ? flattenAnalyticsList(SelectedCourse.stages) : [];
  // const flattenedAnalytics = flattenAnalyticsList(SelectedCourse.stages);
  const uniquePlayersPerDay = new Map();
  const days = [];
  const playersPlayed = [];
  const gamesPlayed = [];
  flattenedAnalytics.forEach((item) => {
    // Check if timeDateList exists and is an array before iterating over it
    if (Array.isArray(item.timeDateList)) {
      item.timeDateList.forEach((dateString) => {
        const date = dateString; // Ensure parseDateString is defined
        const playersCount = item.sessions;
        const gamesCount = item.sessions;
        const studentId = item.student_id;

        const existingIndex = days.findIndex((day) => day === date);
        if (existingIndex === -1) {
          days.push(date);
          playersPlayed.push(1);
          gamesPlayed.push(gamesCount);
          uniquePlayersPerDay.set(date, new Set([studentId]));
        } else {
          const uniquePlayers = uniquePlayersPerDay.get(date);
          if (!uniquePlayers.has(studentId)) {
            playersPlayed[existingIndex]++;
            uniquePlayers.add(studentId);
          }
          gamesPlayed[existingIndex] += gamesCount;
        }
      });
    }
  });
  // Function to extract date from different date-time string formats
  const extractDate = dateTimeString => {
    // Regular expression to match different date-time formats
    const regex = /(\d{2})[\/,-](\d{2})[\/,-](\d{4}).*$/;
    const match = dateTimeString.match(regex);
    if (match) {
      const day = match[1];
      const month = match[2];
      const year = match[3];
      return `${year}/${month}/${day}`;
    } else {
      return null; // Return null if the format doesn't match
    }
  };

  // Combine dates with players played and games played
  const combinedData = days.map((dateTime, index) => ({
    date: extractDate(dateTime),
    playersPlayed: playersPlayed[index],
    gamesPlayed: gamesPlayed[index]
  }));

  // Group combined data by date and calculate total players played and games played for each date
  const groupedData = combinedData.reduce((acc, curr) => {
    const { date, playersPlayed, gamesPlayed } = curr;
    if (!date) return acc; // Skip if date format is invalid
    if (!acc[date]) {
      acc[date] = { date, totalPlayersPlayed: 0, totalGamesPlayed: 0 };
    }
    acc[date].totalPlayersPlayed += playersPlayed;
    acc[date].totalGamesPlayed += gamesPlayed;
    return acc;
  }, {});

  // Sort grouped data by date in ascending order
  const sortedDates = Object.keys(groupedData).sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    if (dateA.getMonth() !== dateB.getMonth()) {
      return dateA.getMonth() - dateB.getMonth();
    }
    return dateA.getDate() - dateB.getDate();
  });

  // Extract sorted players played and games played
  const sortedPlayersPlayed = sortedDates.map(date => groupedData[date].totalPlayersPlayed);
  const sortedGamesPlayed = sortedDates.map(date => groupedData[date].totalGamesPlayed);

  // selecters range for dates start and end in chart 
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [filteredDates, setFilteredDates] = useState([]);
  const [filteredPlayersPlayed, setFilteredPlayersPlayed] = useState([]);
  const [filteredGamesPlayed, setFilteredGamesPlayed] = useState([]);
  const initialEffectRan = useRef(false);

  useEffect(() => {
    if (!initialEffectRan.current && sortedDates.length > 0) {
      setStartDate(sortedDates[0]);
      setEndDate(sortedDates[sortedDates.length - 1]);
      setFilteredDates(sortedDates);
      setFilteredPlayersPlayed(sortedPlayersPlayed);
      setFilteredGamesPlayed(sortedGamesPlayed);
      initialEffectRan.current = true; // Mark as ran
    }
  }, [sortedDates, sortedPlayersPlayed, sortedGamesPlayed])

  useEffect(() => {
    if (initialEffectRan.current) {
      filterData();
    }
  }, [startDate, endDate]);

  const filterData = () => {
    const startIndex = sortedDates.indexOf(startDate);
    const endIndex = sortedDates.indexOf(endDate);

    if (startIndex !== -1 && endIndex !== -1 && startIndex <= endIndex) {
      const newDates = sortedDates.slice(startIndex, endIndex + 1);
      const newPlayersPlayed = sortedPlayersPlayed.slice(startIndex, endIndex + 1);
      const newGamesPlayed = sortedGamesPlayed.slice(startIndex, endIndex + 1);

      setFilteredDates(newDates);
      setFilteredPlayersPlayed(newPlayersPlayed);
      setFilteredGamesPlayed(newGamesPlayed);
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const course_id = queryParams.get('course_id');

  // Grid and List view icon toggle
  const [currentImageGrid, setCurrentImageGrid] = useState(GridIconOn);
  const toggleImageView = () => {
    setCurrentImageGrid((prevImage) => (prevImage === GridIconOff ? GridIconOn : GridIconOff));
  };

  // course will  be here

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m:${String(
      Math.floor(remainingSeconds)
    ).padStart(2, '0')}s`;
  }
  // search players
  const [filteredStudent, setfilteredStudent] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const lowercasedSearchTerm = e.target.value.toLowerCase();

    const filteredLists = SelectedCourse.students.filter((students) => {
      return students.firstName.toLowerCase().includes(lowercasedSearchTerm);
    });

    setfilteredStudent(filteredLists);
  };
  // Course accuraccy culculate 
  let totalStudents = 0;
  let totalScore = 0;
  let correctAnswers = 0;
  let incorrectAnswers = 0;

  if (SelectedCourse) {
    // totalStudents = SelectedCourse.students.length;
    SelectedCourse.stages.forEach(stages => {
      stages.activities.forEach(activities => {
        (activities.analyticsList.forEach(analyticsList => {
          totalScore += analyticsList.score;
          correctAnswers += analyticsList.correctAnswers;
          incorrectAnswers += analyticsList.incorrectAnswers;

        }))
      })

    });
  }
  const accuraccy = ((correctAnswers / (correctAnswers + incorrectAnswers)) * 100).toFixed(2);

  // seprated analytics lists
  const [analyticsfilterd, setAnalyticsfilterd] = useState([]);
  // Function to extract all analytics from selectedCourse and set them in state
  const extractAllanalyticsList = () => {
    const allanalyticsList = SelectedCourse?.stages.flatMap(stage =>
      stage.activities.flatMap(activity =>
        activity.analyticsList.map(analytics => analytics.analytics_id)
      )
    );
    setAnalyticsfilterd(allanalyticsList);
  };

  // Call analytics when SelectedCourse changes or when needed
  useEffect(() => {
    extractAllanalyticsList();
  }, [SelectedCourse]);

  // Variable renderings Analytics, player, activities, journey
  const [selectedVariable, setSelectedVariable] = useState(1);
  const variables = [
    // Course OverView
    {
      id: 1, Name: 'Overview', data: () =>
        <div className='sm:mx-20 mx-5'>
          <div className='my-5'>
            {/* <input type="text" id="datePicker" className="datepicker border" autocomplete="off" placeholder="undefined-undefined" style={{ textAlign: 'center', marginLeft: '10px' }} /> */}
            {/* <MyChartComponent days={days} gamesPlayed={gamesPlayed} players={playersPlayed} /> */}
            {loading ? (
              <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                <svg
                  className="text-gray-300 animate-spin"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100">
                  <path
                    d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                    stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                  </path>
                  <path
                    d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                    stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                  </path>
                </svg>
              </div>
            ) : (
              sortedDates.length > 0 ? <div>
                <div>
                  {/* <input
                    type="text"
                    className="border py-3 px-10 w-64 "
                    value={`${sortedDates.length > 0 ? (() => { const parts = sortedDates.sort()[0].substring(0, 10).replace(/[-,]/g, '/').split('/'); return `${parts[2]}/${parts[1]}/${parts[0]}`; })() : ''} - ${sortedDates.length > 0 ? (() => { const parts = sortedDates.sort()[sortedDates.length - 1].substring(0, 10).replace(/[-,]/g, '/').split('/'); return `${parts[2]}/${parts[1]}/${parts[0]}`; })() : ''}`}
                    name=""
                    id=""
                  /> */}
                  {/* slecte srat and end dates  */}
                  <div className='flex border py-3 px-5 w-64'>
                    <div>
                      <select
                        className="no-arrow"
                        id="start-date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      >
                        {sortedDates.map((date) => {
                          const [year, month, day] = date.split('/');
                          return (
                            <option key={date} value={date}>
                              {`${day}/${month}/${year}`}
                            </option>
                          );
                        })}
                      </select>
                    </div> -

                    <div>
                      <select
                        id="end-date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      >
                        {sortedDates.map((date) => {
                          const [year, month, day] = date.split('/');
                          return (
                            <option key={date} value={date}>
                              {`${day}/${month}/${year}`}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <BarChart //days={sortedDates.map(date => date.split(' ')[0].substring(0, 10).replace(/[-,]/g, '/'))}
                    days={filteredDates.map(date => {
                      const parts = date.split(' ')[0].substring(0, 10).replace(/[-,]/g, '/').split('/');
                      return `${parts[2]}/${parts[1]}/${parts[0]}`;
                    })}
                    gamesPlayed={filteredGamesPlayed} players={filteredPlayersPlayed} loading={loading} />
                  <div className=' grid grid-cols-4 flex gap-5'>
                    <div className='relative'>
                      <p className='flex justify-center absolute inset-x-0 top-4 text-xl'><b>Accurancy</b></p>
                      <img className='w-full' src={ChartAccurancy} alt="" />
                      <p className='flex justify-center absolute inset-x-0 bottom-8 text-xl'><b>{accuraccy > 0 ? accuraccy : "0.00"}</b>%</p>
                    </div>
                    {/* <div className='relative'>
              <p className='flex justify-center absolute inset-x-0 top-4 text-xl'><b>Hardest Questions</b></p>
              <img className='w-full' src={ChartHardest} alt="" />
              <p className='flex justify-center absolute inset-x-0 bottom-8 text-xl'>Top  <b>20</b></p>
            </div>
            <div className='relative'>
              <p className='flex justify-center absolute inset-x-0 top-4 text-xl'><b>Easiest Quesyions</b></p>
              <img className='w-full' src={ChartEasiest} alt="" />
              <p className='flex justify-center absolute inset-x-0 bottom-8 text-xl'>Top <b> 10</b></p>
            </div>
            <div className='relative'>
              <p className='flex justify-center absolute inset-x-0 top-4 text-xl'><b>Complished</b></p>
              <img className='w-full' src={ChartComplished} alt="" />
              <p className='flex justify-center absolute inset-x-0 bottom-8 text-xl'><b>60</b>%</p>
            </div> */}
                  </div>
                </div>

              </div> : <div className='flex justify-center'>
                <div> <p className="text-xl font-bold flex justify-center">No players played yet  </p>
                  <p className='flex justify-center'><img src={sadDog} alt="" /></p>
                </div>
              </div>
            )}

          </div>
        </div>
    },
    // Players Of course
    {
      id: 2, Name: 'Players', data: () =>
        <div>
          <div className='flex my-5 px-3'>
            <p className='text-xl'>Players Analysis</p>
            {/* <div className='absolute right-20'>
              <select className='border py-2 px-4' name="" id="">
                <option value="">Last 30 Days: 11/2/2024-10/3/2024 <b></b></option>
              </select>
            </div> */}
          </div>
          {/* List view Players Analysis */}

          <div className={`${currentImageGrid === GridIconOn ? 'hidden' : ''}`}>
            <div class="relative mx-2 sm:mx-5 mt-5 overflow-x-auto max-w-80 sm:max-w-full">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-3 py-3">
                      Name
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Email
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Score
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Accuracy
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Activities
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Sessions
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Avg Time
                    </th>
                    {/* <th scope="col" class="">
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {SelectedCourse.students.map((student, studentIndex) => (
                    <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                      <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {student.firstName} {student.lastName}
                      </th>
                      <td class="px-3 py-6">
                        {student.email}
                      </td>
                      <td class="px-3 py-6">
                        {student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).length > 0 ? (
                          <div>
                            {student.analyticsList
                              .filter(analytics => analyticsfilterd.includes(analytics.analytics_id))
                              .reduce((acc, analytics) => acc + analytics.score, 0)
                              .toFixed(0)}
                          </div>
                        ) : <>0</>}
                      </td>
                      <td class="px-3 py-6">
                        {student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).length > 0 ? (
                          <div>
                            {" "}
                            {(((student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).reduce((acc, analytics) => acc + analytics.correctAnswers, 0)) /
                              (student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).reduce((acc, analytics) => acc + analytics.correctAnswers, 0) +
                                student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).reduce((acc, analytics) => acc + analytics.incorrectAnswers, 0))) * 100).toFixed(1)}%
                          </div>
                        ) : <>0.0%</>}
                      </td>
                      <td class="px-3 py-6">
                        {student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).length}
                      </td>
                      <td class="px-3 py-6">
                        {student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).length > 0 ? (
                          <div>
                            {" "}
                            {student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).reduce((acc, analytics) => acc + analytics.sessions, 0).toFixed(0)}
                          </div>
                        ) : <>0</>}
                      </td>
                      <td class="px-3 py-6">
                        {student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).length > 0 ? (
                          <div>
                            {" "}
                            {formatTime(
                              student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).reduce((acc, analytics) => acc + analytics.totalTime, 0) /
                              student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).reduce((acc, analytics) => acc + analytics.sessions, 0).toFixed(0)
                            )}
                          </div>
                        ) : <>00h:00m:00s</>}
                      </td>
                      {/* <td class="px-3 py-6">
                          <div className="flex justify-center">
                            <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                            <span><img src={DeleteIcon} alt="" /></span>
                            </div>
                          </div>
                        </td> */}
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
          {/* Grid view Players Analysis */}
          <div>
            <div  className='grid md:grid-cols-2 xl:grid-cols-3 gap-3 mx-3 my-5'>
              {SelectedCourse.students.map(student => (
                <AnalyticsComponent key={student.student_id} student={student} selectedCourse={SelectedCourse} viewType={currentImageGrid} gridOff={GridIconOff} gridOn={GridIconOn} />
              ))}
            </div>
          
          </div>
        </div>
    },
    // Journeys of course
    {
      id: 3, Name: 'Journey', data: () =>
        <div>
          {/* List view Stages */}
          <div>
            <div className='flex my-5 px-3'>
              <p className='text-xl'>Journey</p>
            </div>
            <div class="relative mx-2 sm:mx-5 mt-5 overflow-x-auto max-w-80 sm:max-w-full">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-3 py-3">
                      Name
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Activities
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Date Created
                    </th>
                    <th scope="col" class="">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {SelectedCourse.stages.map((stage, stageIndex) => (
                    <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                      <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {stage.name}
                      </th>
                      <td class="px-3 py-6">
                        {stage.activities.length}
                      </td>
                      <td class="px-3 py-6">
                        {stage.startingDate}
                      </td>
                      <td class="px-3 py-6">
                        <div className="flex justify-center">
                          <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                            <span><img src={DeleteIcon} alt="" /></span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>
            </div>
          </div>
        </div>
    },
    // Actiities of Course
    {
      id: 4, Name: 'Activities', data: () =>
        <div>
          <div>
            <div className='flex my-5 px-3'>
              <p className='text-xl'>Activities</p>
            </div>
            <div class="relative ms-2 sm:mx-5 mt-5 overflow-x-auto max-w-80 sm:max-w-full">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" class="px-3 py-3">
                      Name
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Type
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Stage
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Created by
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Date Created
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Last Modified
                    </th>
                    <th scope="col" class="px-3 py-3">
                      Last Modified By
                    </th>
                    <th scope="col" class="">
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {SelectedCourse.stages.map((stage, stageIndex) => (
                    stage.activities.map((activity) => (
                      <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                        <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {activity.name}
                        </th>
                        <td class="px-3 py-6">
                          {activity.activityType}
                        </td>
                        <td class="px-3 py-6">
                          {stageIndex + 1}
                        </td>
                        <td class="px-3 py-6">
                          {activity.createdBy}
                        </td>
                        <td class="px-3 py-6">
                          {activity.dateCreated}
                        </td>
                        <td class="px-3 py-6">
                          {activity.lastModified !== null ? activity.lastModified : 'Not modified yet'}
                        </td>
                        <td class="px-3 py-6">
                          {activity.lastModified !== null ? activity.createdBy : 'Not modified yet'}
                        </td>
                        <td class="px-3 py-6">
                          <div className="flex justify-center">
                            <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                              <span><img src={DeleteIcon} alt="" /></span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))))}

                </tbody>
              </table>
            </div>
          </div>

        </div>
    },
    // Rewards of Course
    // {
    //   id: 5, Name: 'Rewards', data: () =>
    //     <div><p>Rewards you collected</p></div>
    // },
  ];

  const handleButtonClick = (id) => {
    // Set the selected variable when a button is clicked
    // setSelectedVariable(variables.find((variable) => variable.id === id));
    setSelectedVariable(id);
  };


  return (
    <div className='w-full'>
      <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg '>
        <h3 className="text-xl"><b>{SelectedCourse?.name}</b>/Course Analytics</h3>
        <LoginHeading />
      </div>
      <div className="m-7  pb-5 bg-white border border-2 ">
        <div className='flex justify-center static'><img className='absolute top-6' src={AnalyticnHIcon} alt="" /></div>
        <div>
          <div className='bg-gray-100 p-2 text-xl  border-b-2 sm:flex '>
            {variables.map((variable) => (
              <button
                key={variable.id}
                onClick={() => handleButtonClick(variable.id)}
                // style={{ margin: '5px' }}
                className={`sm:m-2 ${selectedVariable === variable.id ? "font-bold" : ""}`}
              >
                {variable.Name} <b>|</b>
              </button>
            ))}
            <div className="flex gap-3 sm:absolute right-16 mt-2.5">
              <img
                src={currentImageGrid}
                alt='Grid View'
                onClick={toggleImageView}
              />
              <img src={currentImageGrid === GridIconOff ? ListIconOn : ListIconOff} alt="3" onClick={toggleImageView} />
            </div>
          </div>
          <div>
            {loading ? (
              <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                <svg
                  className="text-gray-300 animate-spin"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100">
                  <path
                    d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                    stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                  </path>
                  <path
                    d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                    stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                  </path>
                </svg>
              </div>
            ) : (
              <div className=''>
                {variables.find((variable) => variable.id === selectedVariable)?.data()}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseAnalytics;
