import React from "react";
import { useLocation } from "react-router-dom";
import LoginHeading from "../../../../../LoginHeading/LoginHeading";


const PublishCourse = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const course_id = queryParams.get('course_id');
    return(
        <div className='w-full'>
        <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
          <h3 className="text-xl">Publish Course</h3>
          <LoginHeading />
          </div>
        <div className="m-7 bg-white contentSide">
        <h1 className="text-2xl text-green-500">This Is  Publish the Course Component</h1>
        </div>
    </div>
    )
};

export default PublishCourse;
