import React, { useState } from "react";
import './saving.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';

import CloseIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/GameDone/close ìcon.png'
import AddNewActivity from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/GameDone/Add New Manual Question icon.png'
import SelectIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/GameDone/select icon for poster.png'
import PdfImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/PDF icon hover off color.png'
import VideoImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/video icon hover off color.png'
import AudioImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/Audio icon hover off color.png'
import ImageImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/image icon hover off color.png'
import UrlImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/URL icon hover off color.png'
import MultiActivityImage from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/multiactivity  icon hover off.png'

import axios from "axios";
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";

const GameDoneJourney = () => {
    const { course, activity, selectedgame, stageIndex, courseFormatDate } = useLocation().state;
    const navigate = useNavigate();
    var instructor_id = parseInt(localStorage.getItem("instructor_id"));
    var organization = parseInt(localStorage.getItem('organization'));

    const imageMap = {
        pdf: PdfImage,
        video: VideoImage,
        audio: AudioImage,
        image: ImageImage,
        url: UrlImage,
        game: selectedgame,
        multiActivity: MultiActivityImage,
    };
    const getImageSrc = () => {
        return imageMap[activity.activityType];
    };

    const handleAddNewActivity = () => {
        course.stages[stageIndex].activities.push(activity);
        navigate('/Dashboard/activityType', { state: { course: course, courseFormatDate: courseFormatDate } });
    }
    const submitCourse = async () => {
        var course_id;
        course.stages[stageIndex].activities.push(activity);
        let class_id = course.grade;
        try {
            const createCourse = await axios.post("https://gamizign.co:8000/courses/" + instructor_id, course)
                .then(async (response) => {
                    if (response.data) {
                        course_id = response.data.course_id;
                        const stageCourses = await axios.post("https://gamizign.co:8000/courses/" + response.data.course_id + "/stages", course.stages);
                        return stageCourses;
                    } else {
                        console.log("An error occurred during creating course. Please try again.");
                        return Promise.reject("Error creating course");
                    }
                })
                .then(stageReq => {
                    if (stageReq.data) {
                        let savedCourse = stageReq.data;
                        localStorage.setItem("courseName", "");
                    } else {
                        console.log("An error occurred during creating course stages. Please try again.");
                    }
                }).then(async ()=>{
                    axios.post(`https://gamizign.co:8000/organization/${organization}/class/${class_id}/courses/${course_id}/students/`).then(response =>{
                        if (response.status === 200) {
                            navigate('/Dashboard/CreateCourse');
                        } else {
                            console.log('An error occurred while registering students. Please try again later.');
                        }
                    })
                })
                .catch(error => {
                    console.log("An error occurred: ", error);
                });
        } catch (error) {
            console.log("An error occurred during creating course. Please try again.");
        }

    }

    return (

        <div className='w-full'>
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Create New Activity</h3>
                <LoginHeading />
            </div>
            <div className="m-7 bg-white contentAreaGameDone">
                {/* progress line */}
                <div>
                    <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className="flex w-full items-center text-green-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Adding</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-green-600 after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Creating</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-green-600 after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Customizing</b>
                            </span>
                        </li>
                        <li className="flex items-center text-green-600">
                            <span className="flex items-center">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Saving</b>
                            </span>
                        </li>
                    </ol>
                </div>
                <div>
                    <div className="mt-10">
                        <h1 className="text-2xl font-bold flex justify-center">Activity {activity.activityType} is Done</h1>
                        <p className="text-blue-800  flex justify-center">in your course {course.name}</p>
                        <div className="flex justify-center mt-3 relative">
                            <img className="" src={getImageSrc()} alt="activityIMG" />
                            <span className="absolute bottom-10"><img src={SelectIcon} alt="" /></span>
                        </div>
                    </div>
                </div>
                <div className='flex text-center mt-5 mb-5 items-center justify-center gap-3'>
                    <Link to='/Dashboard/CreateCourse'>
                        <button type='submit' onClick={submitCourse} className='BackBtn flex p-2 px-7 hover:bg-blue-200' ><img className='mr-5 mt-1' src={CloseIcon} alt="Next" /><b> Close</b></button>
                    </Link>
                </div>
                <div className="mt-10 ">
                    <div className="flex justify-center"><img className="cursor-pointer" src={AddNewActivity} alt="" onClick={handleAddNewActivity} /></div>
                    <p className="flex justify-center mt-2">Add New Activity</p>
                </div>
            </div>
        </div>
    )
}

export default GameDoneJourney;