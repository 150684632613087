import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";
import Modal from 'react-modal'

import GamizignHIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Gamizign head icon.png'
import PdfImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/PDF icon hover off color.png'
import GameImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/game icon hover off color.png'
import VideoImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/video icon hover off color.png'
import AudioImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/Audio icon hover off color.png'
import ImageImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/image icon hover off color.png'
import UrlImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/URL icon hover off color.png'
import HideIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/Next icon.png'
import BackIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/back iconB.png'
import SaveIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/save icon.png'
import DeletIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/delete icon.png'
import EditIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/edit icon.png'
import EyeIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/course active icon.png'
import StarIconOn from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/star for rank icon on.png'
import StarIconOff from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/star for rank off icon.png'
import RadioOnIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/radio button on.png'
import RadioOffIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/radio button off.png'
import GenuisBadgeOn from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/genus icon on.png'
import GenuisBadgeOff from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/genus icon off.png'
import HelperBadgeOn from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/helper icon on.png'
import HelperBadgeOff from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/helper icon off.png'
import LeaderBadgeOn from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/leader icon on.png'
import LeaderBadgeOff from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/leader icon off.png'
import CreativeBadgeOn from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/creative icon on.png'
import CreativeBadgeOff from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/creative icon off.png'
import SpeedBadgeOn from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/speed icon on.png'
import SpeedBadgeOff from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/speed icon off.png'


const LearnerEva = () => {
    const { activity, Course_name, Students, course_id, learner } = useLocation().state;
    const [modelAnswer, setModelAnswer] = useState(false);
    const ModelAnswer = () => {
        setModelAnswer((prevImage) => (prevImage === false ? true : false));
    };
    const [evaluation, setEvaluation] = useState("5");
    const [activityType, setActivityType] = useState(null)
    const AddingActivityType = () => {
        switch (activity.activityType) {
            case 'pdf':
                setActivityType(PdfImage);
                break
            case 'image':
                setActivityType(ImageImage)
                break;
            case 'video':
                setActivityType(VideoImage);
                break
            case 'audio':
                setActivityType(AudioImage);
                break
            case 'game':
                setActivityType(GameImage);
                break
            case 'url':
                setActivityType(UrlImage);
                break
            default:
                setActivityType(ImageImage);
        }

    };
    // Score volume and stars evaluation
    const [StarValue, setStarValue] = useState(learner.evaluation)
    const [ScoreVolume, setScoreVolume] = useState(null)
    const CalculateScoreVolume = () => {
        switch (StarValue) {
            case '1':
                setScoreVolume(0);
                break
            case '2':
                setScoreVolume((activity.scoreVolume/100)*40);
                break
            case '3':
                setScoreVolume((activity.scoreVolume/100)*80);
                break
            case '4':
                setScoreVolume((activity.scoreVolume/100)*90);
                break
            case '5':
                setScoreVolume(activity.scoreVolume);
                break
            default:
                setScoreVolume(0);
        }

    };
    useEffect(() => {
        AddingActivityType();
        CalculateScoreVolume();
    }, [StarValue]);
    // View learner Answer
    const [ViewAnswer, setViewAnswer] = useState(false)
    const ViewAnswerHandle = () => {
        setViewAnswer((prevImage) => (prevImage === false ? true : false));
    };
    // post Evaluation
    const [evaluationData, setEvaluationData] = useState(StarValue);
    const [CommentOption, setCommentOption] = useState();
    const EvaluationDataText = (e) => {
        setCommentOption(e.target.value);
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        
        try {
          const response = await fetch(`https://gamizign.co:8000/activities/${activity.activity_id}/answers/evaluation?student_id=${learner.student_id}&eval=${StarValue}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();
          setEvaluationmodalIsOpen(true);
          // Handle success (e.g., update state, display message, etc.)
        } catch (error) {
          console.error('Error:', error);
          // Handle error (e.g., display error message, etc.)
        }
      };
    //   Model for success response of post/ update Evaluation
    const [EvaluationmodalIsOpen, setEvaluationmodalIsOpen] = useState(false);
    const EvaluationBotModal = () => {
        setEvaluationmodalIsOpen(true);
    };
    const EvaluationBotcloseModal = () => {
        setEvaluationmodalIsOpen(false);
    }; 
    const navigate = useNavigate();
    const sendDataActivitypage = () => {
        navigate(`/Course/ActivityEvaluation?course_id=${course_id}`, { state: { activity: activity, Course_name: Course_name, Students: Students, course_id: course_id, learner: learner } })
    }

    return (
        <div className='w-full'>
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Students Homework</h3>
                <LoginHeading />
            </div>
            <div className="m-7 bg-white border-4 pb-5">
                <div className='flex justify-center static'><img className='absolute top-6' src={GamizignHIcon} alt="" /></div>
                <div className="p-5  bg-gray-50 border-b font-bold text-xl">{Course_name} / {activity.name} / {learner.firstName} {learner.lastName}</div>
                <div className="ms-14 me-20 mt-10 flex relative">
                    <label htmlFor="Name of Course"><b>Learner name</b>
                        <span className="border py-3 px-5 rounded w-80 ms-2">{learner.firstName} {learner.lastName}</span>
                    </label>
                    <label className="flex-1 text-right" htmlFor="Starting Date"> <b>Answering Date </b>
                        <span className="text-blue-600 ms-2">{learner.answerTime.replace(/[-,]/g, '/')}</span>
                    </label>
                </div>
                <div className="mx-20 mt-5">
                    <div className="flex">
                        <lable className="mt-7 w-56">1. Learner answer</lable>
                        {/* <span className="ms-5"><img className="cursor-pointer" onClick={ViewAnswerHandle} src={activityType} alt="" /></span> */}
                        {/* {ViewAnswer &&  */}
                        <span className="ms-5 my-5 border p-2">{learner.activity_answer}</span>
                        {/* // } */}
                    </div>
                    {/* Model Answer */}
                    <div className="flex mt-5">
                        <label className="mt-2.5 w-56" htmlFor="">2. Model answer </label>
                        <div className="flex-1 text-center">
                            {modelAnswer === false ? (
                                <button onClick={ModelAnswer} className='BackBtn ms-5 flex p-2 px-7 hover:bg-blue-200'>show<img className='ml-5 mt-1 rotate-90' src={HideIcon} alt="Back" /></button>
                            ) : (
                                <button onClick={ModelAnswer} className='bg-green-50 ms-5 rounded border flex p-2 px-7 hover:bg-blue-200'>Hide<img className='ml-5 mt-1 rotate-90' src={BackIcon} alt="Back" /></button>
                            )}
                        </div>
                    </div>
                    {modelAnswer &&
                        <div>
                            <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex justify-end">
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='is exactly' value={activity.answer_exact}>
                                    <span>
                                        <img
                                            src={activity.answer_exact === 'is exactly' ? RadioOnIcon : RadioOffIcon}
                                            alt="Task"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.answer_exact === 'is exactly' ? 'font-bold' : 'font-light'}`}>is exactly</span>
                                </div>
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='contain' value={activity.answer_exact}>
                                    <span>
                                        <img
                                            src={activity.answer_exact === 'contain' ? RadioOnIcon : RadioOffIcon}
                                            alt="Project"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.answer_exact === 'contain' ? 'font-bold' : 'font-light'}`}>contain</span>
                                </div>
                            </div>
                            <textarea className='w-full border ps-5' disabled value={activity.answer_requirement} id="ActivityAnswer" name="ActivityAnswer" placeholder='Text ....' rows="4" cols="95" ></textarea>
                        </div>
                    }
                    <div className="flex mt-5">
                        <label className="mt-2.5 w-56" htmlFor="">3. Score</label>
                        <span className="border rounded p-2 ms-5">{ScoreVolume}</span>
                        <div className="flex-1 flex justify-center">
                        <div className={`flex gap-3 ${StarValue === null ? '' : 'hidden'}`}>
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('1')}  src={StarIconOff} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('2')}  src={StarIconOff} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('3')}  src={StarIconOff} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('4')}  src={StarIconOff} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('5')}  src={StarIconOff} alt="" />
                        </div>
                        <div className={`flex gap-3 ${StarValue === '1' ? '' : 'hidden'}`}>
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('1')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('2')}  src={StarIconOff} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('3')}  src={StarIconOff} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('4')}  src={StarIconOff} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('5')}  src={StarIconOff} alt="" />
                        </div>
                        <div className={`flex gap-3 ${StarValue === '2' ? '' : 'hidden'}`}>
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('1')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('2')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('3')}  src={StarIconOff} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('4')}  src={StarIconOff} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('5')}  src={StarIconOff} alt="" />
                        </div>
                        <div className={`flex gap-3 ${StarValue === '3' ? '' : 'hidden'}`}>
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('1')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('2')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('3')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('4')}  src={StarIconOff} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('5')}  src={StarIconOff} alt="" />
                        </div>
                        <div className={`flex gap-3 ${StarValue === '4' ? '' : 'hidden'}`}>
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('1')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('2')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('3')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('4')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('5')}  src={StarIconOff} alt="" />
                        </div>
                        <div className={`flex gap-3 ${StarValue === '5' ? '' : 'hidden'}`}>
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('1')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('2')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('3')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('4')}  src={StarIconOn} alt="" />
                            <img className="cursor-pointer" onMouseEnter={()=>setStarValue('5')}  src={StarIconOn} alt="" />
                        </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <label className="mt-2.5 w-56" htmlFor="">4. Comment(option)</label>
                        <textarea className='w-full border ps-5 mt-3' id="evaluationData" value={CommentOption} onChange={EvaluationDataText} name="evaluationData" placeholder='Type your answer ....' rows="4" cols="95" ></textarea>

                    </div>
                    {/* <div className="mt-5">
                        <label className="mt-2.5 w-56" htmlFor="">4. Badges(select one if active)</label>
                        <div className="flex flex-wrap my-5">
                            <div className="">
                                <span className="flex justify-center"><img src={GenuisBadgeOn} alt="" /></span>
                                <span className="flex justify-center"><img src={RadioOffIcon} alt="" /></span>
                            </div>
                            <div className="">
                                <span className="flex justify-center"><img src={HelperBadgeOn} alt="" /></span>
                                <span className="flex justify-center"><img src={RadioOffIcon} alt="" /></span>
                            </div>
                            <div className="">
                                <span className="flex justify-center"><img src={LeaderBadgeOn} alt="" /></span>
                                <span className="flex justify-center"><img src={RadioOnIcon} alt="" /></span>
                            </div>
                            <div className="">
                                <span className="flex justify-center"><img src={CreativeBadgeOn} alt="" /></span>
                                <span className="flex justify-center"><img src={RadioOffIcon} alt="" /></span>
                            </div>
                            <div className="">
                                <span className="flex justify-center"><img src={SpeedBadgeOn} alt="" /></span>
                                <span className="flex justify-center"><img src={RadioOffIcon} alt="" /></span>
                            </div>

                        </div>

                    </div> */}

                </div>
                <div className="flex justify-center gap-5">
                    <button onClick={sendDataActivitypage} className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button>
                    <button onClick={handleSubmit} className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Save </b><img className='ml-5 mt-1' src={SaveIcon} alt="Back" /></button>
                </div>
            </div>
             {/* PopUp for Activity Delete */}
        <Modal
          isOpen={EvaluationmodalIsOpen}
          onRequestClose={EvaluationBotcloseModal}
          className="Modal flex justify-center mt-40"
        // overlayClassName="Overlay"
        >
          {/* Modal Content */}
          <div className="flex sm:ms-20" >
            <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-green-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
              <div className="mt-5">
                {/* <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div> */}
                <h1 className={`sm:text-2xl `}>Evaluation of <b> {learner.firstName} {learner.lastName}</b> has been done successfully !</h1>
                <div className="flex justify-center gap-8 my-4">
                  <button className="hover:scale-110 hover:text-xl font-bold cursor-pointer" onClick={()=>{EvaluationBotcloseModal();sendDataActivitypage()}}>Ok</button>
                  <button className="hover:scale-110 hover:text-xl font-bold cursor-pointer" onClick={()=>{EvaluationBotcloseModal()}}>Cancel</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        </div>

    )
};

export default LearnerEva;