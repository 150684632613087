import React, { useState } from "react";
import './style.css'
import GamizignLogo from '../../../assets/images/SigninPageImages/Gamizign-logo.png'
import LoginIcon from '../../../assets/images/SigninPageImages/login_icons.png'
import Lockicon from '../../../assets/images/SigninPageImages/sign in icon.png'
import HeartIcon from '../../../assets/images/SigninPageImages/kindly icon.png'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import CircularProgress from '@mui/material/CircularProgress';
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const SignIn = () => {
    // const history = useHistory(); // Use useHistory hook for navigation
    const navigate = useNavigate();
    const [state, setState] = useState({
        showPassword: false,
        password: '',
        error: '',
        isLoading: false,
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        setState((prevState) => ({ ...prevState, error: '', isLoading: true }));
        handleLoginResult(true);
        const reFormData = {
            email: event.target.email.value,
            password: event.target.password.value,
        };

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify(reFormData),
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const response = await fetch("https://gamizign.co:8000/signin", requestOptions);

        try {
            if (!response.ok) {
                handleLoginResult(false);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();
            localStorage.setItem("instructor_id", responseData.instructor_id);
            localStorage.setItem("name", responseData.firstName);
            localStorage.setItem("email", responseData.email);
            localStorage.setItem("organization", responseData.organization_id);
            navigate("/Dashboard/CreateCourse");
            handleLoginResult(true);
            setState((prevState) => ({ ...prevState, error: '', isLoading: false }));
        } catch (error) {
            const errorMessage = 'Invalid email or password';
            setState((prevState) => ({ ...prevState, error: errorMessage, isLoading: false }));
            handleLoginResult(false);
        }
    }

    const togglePasswordVisibility = () => {
        setState((prevState) => ({ ...prevState, showPassword: !prevState.showPassword }));
    };

    const handleLoginResult = (isCorrectLogin) => {
        const inputs = document.querySelectorAll("input");

        inputs.forEach((input) => {
            input.readOnly = isCorrectLogin;
        });
    }
    const handleGoogleLogin = async (credentialResponse) => {
        var jwtToken = credentialResponse.credential.toString();
        setState((prevState) => ({ ...prevState, error: '', isLoading: true }));
        fetch('https://gamizign.co:8000/handle_token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: jwtToken,
            }),
        })
            .then(response => response.json())
            .then(decodedData => {
                    localStorage.setItem("instructor_id", decodedData.instructor_id);
                localStorage.setItem("name", decodedData.firstName);
                localStorage.setItem("email", decodedData.email);
                localStorage.setItem("organization", decodedData.organization_id);
                setState((prevState) => ({ ...prevState, error: '', isLoading: false }));

                navigate("/Dashboard/CreateCourse");
                // setData(decodedData);
            })
            .catch(error => {
                const errorMessage = 'Error logging in with Google';
                setState((prevState) => ({ ...prevState, error: errorMessage, isLoading: false }));
                console.error('Error decoding JWT on the server:', error);
            });
    }
    return (
        <div>
            <div className="flex justify-center items-center">
                <div className="flex-1"></div>
                <div className="flex-none SignIn text-center justify-center px-0 py-0" >
                    <div className="flex justify-center"><img className="logo" src={GamizignLogo} width={200} alt="LogoImage" /></div>
                    <div className="flex justify-center"><img className="img-fluid" src={LoginIcon} alt="LoginImage" /></div>
                    <div className="drop-shadow-xl rounded-b-3xl bg-transparent relative">
                        {state.isLoading && (
                            <div className="absolute inset-0 py-40 flex justify-center items-center bg-transparent bg-opacity-75 z-50">
                                <CircularProgress className="text-blue-800" />
                            </div>
                        )}
                        {/* <div className="InstructorBg flex justify-center items-center gap-1 py-3">
                            <img className="" src={Lockicon} alt="LockIcon" /> <h1 className="text-3xl">Sign IN</h1>
                        </div> */}
                        <form className="container p-3 px-5" action="post" onSubmit={handleSubmit}>
                            <input className="p-2 w-full text-blue-800 EmailInput" name="email" type="email" required placeholder="mohd@example.com" />
                            <div className="relative">
                                <input
                                    className="my-5 p-2 w-full text-blue-800 PaswordInput"
                                    name="password"
                                    placeholder="Enter your password"
                                    type={state.showPassword ? 'text' : 'password'}
                                    id="password"
                                    required />
                                <div
                                    className="absolute inset-y-0 right-0 flex items-center pr-2 cursor-pointer text-blue-800"
                                    onClick={togglePasswordVisibility}>
                                    {state.showPassword ? <FaEye size={24}/> : <FaEyeSlash size={24}/>}
                                </div>
                            </div>
                            {state.error && <p style={{ color: 'red' }}>{state.error}</p>}
                            <div className="flex justify-center items-center gap-1 py-1 mb-5"><img src={HeartIcon} alt="HeartIcon" /><p>Kindly!</p></div>
                            <button className="btn GameOn hover:scale-110 text-2xl py-2 w-full" type="submit" disabled={state.isLoading}>
                                <label><b>GAME ON!</b></label>
                            </button>

                            <GoogleOAuthProvider clientId="1071328084227-056krbch512vrpup6fgevl6j8i55g7ic.apps.googleusercontent.com">
                                <GoogleLogin
                                    onSuccess={handleGoogleLogin}
                                    shape="circle"
                                    type="standard"
                                    logo_alignment="left"
                                    text="continue_with"
                                    theme="filled_blue"
                                    size="large"
                                    disabled={state.isLoading} // Disable the button when loading
                                />
                            </GoogleOAuthProvider>
                        </form>
                    </div>
                    <p className="mt-4"><Link className="text-black" target="_blank" to="https://gamizign.com/privacy-policy/">Privacy Policy & Terms of Use</Link></p>
                </div>
                <div className="flex-1"></div>
            </div>
        </div>
    );


};
export default SignIn;
