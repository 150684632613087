import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import QListDashboardIcon from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Question Lists dashboard icon.png'
import AddNewActivity from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/Add New Manual Question icon.png'
import MCQsRight from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/MCQ right answer.png'
import DeletQuestion from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/delete icon.png'
import DuplicateQuestoin from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/Duplicate question icon.png'
import PotoIcon from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/photo icon 28-28.png'
import questionImg from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/image icon _ multi activity.png'
import BackBtn from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/back iconB.png'
import SaveBtn from '../../../../../../assets/icons//ActivityContentIcons/QuestionListsPatch5/save icon.png'
// Bloom level images
import remembering from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 1st level 36-36.png'
import understanding from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 2nd level 36-36.png'
import applying from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 3rd level 36-36.png'
import analyzing from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 4th level 36-36.png'
import evaluating from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 5th level 36-36.png'
import creating from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 6th level 36-36.png'
import AlertIcon from '../../../../../../assets/icons/Alert-icon.png'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoginHeading from "../../../../../LoginHeading/LoginHeading";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Modal from 'react-modal'
import * as mke from 'mathkeyboardengine';




const QuestionListEdit = () => {
    const [selectedQuestions, setSelectedQuestions] = useState(null);
    const [AddNewQuestion, setAddNewQuestion] = useState(false);
    const [emptyValues, setEmptyValues] = useState(false);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const questionList_id = queryParams.get('questionList_id')!==""? queryParams.get('questionList_id'):"";
    const [progress, setIsProgress] = useState(false);
    const [modalMessage, setModalMessage] = useState('The last question does not have all answers filled!');

    const navigate = useNavigate();
    const k = new mke.KeyboardMemory();

    
    const { course } = useLocation().state || "";

    const [updatedQuestions, setUpdatedQuestions] = useState({name: "Manual questions"});
    const initialNewQuestion = {
        question: "",
        answer1: "",
        answer2: "",
        answer3: "",
        answer4: "",
        answer1Image: null,
        answer2Image: null,
        answer3Image: null,
        answer4Image: null,
        questionImage: null,
        poll: false,
        bloom_level: "Remembering",
        slide_no: "manual",
        difficulty: "easy",
        questionList_id: questionList_id // You need to implement this function
    };
    const [newQuestion, setNewQuestion] = useState({
        question: "",
        answer1: "",
        answer2: "",
        answer3: "",
        answer4: "",
        answer1Image: null,
        answer2Image: null,
        answer3Image: null,
        answer4Image: null,
        questionImage: null,
        poll: false,
        bloom_level: "Remembering",
        slide_no: "manual",
        difficulty: "easy",
        questionList_id: questionList_id
    });
    const handleAddNew = () => {
        const updatedQuestionsCopy = { ...updatedQuestions };
        setNewQuestion(initialNewQuestion);
        const questions = updatedQuestionsCopy.questions;

        if (questions.length > 0) {
            const lastQuestion = questions[questions.length - 1];

            // Ensure lastQuestion has all answer properties that are non-empty strings
            if (
                lastQuestion.question !== '' &&
                lastQuestion.answer1 !== '' &&
                lastQuestion.answer2 !== '' &&
                lastQuestion.answer3 !== '' &&
                lastQuestion.answer4 !== ''
            ) {
                // All answers are non-empty strings, push the new question
                updatedQuestionsCopy.questions.push(newQuestion);
            } else {
                setModalMessage("Kindly fill all the fields of the question")
                setCoursebotmodalIsOpen(true);
            }
            setUpdatedQuestions(updatedQuestionsCopy);
        }else{
            updatedQuestions.name = "My Manual Questionlist";
            updatedQuestionsCopy.questions.push(newQuestion);
        }
        resetNewQuestion();
    };
    const resetNewQuestion = () => {
        setNewQuestion(initialNewQuestion);
    };


    useEffect(() => {
        // Fetch data from the API
        if(questionList_id !== null){
            fetch(`https://gamizign.co:8000/question-lists/${questionList_id}`)
                .then((response) => response.json())
                .then((data) => {
                    setUpdatedQuestions(data); // Initialize updatedQuestions with the fetched data
                })
                .catch((error) => console.error('Error fetching data:', error));
        }else{
            setUpdatedQuestions({name:"", "questions": []});  
        }
    }, []);

    const handleDelete = (questionIndex) => {
        // Create a shallow copy of the updatedQuestions object
        const updatedQuestionsCopy = { ...updatedQuestions };

        // Ensure that the questions array exists and is an array
        if (!updatedQuestionsCopy.questions || !Array.isArray(updatedQuestionsCopy.questions)) {
            console.error("questions array is not properly defined.");
            return;
        }

        // Remove the question at the specified index from the copied array
        updatedQuestionsCopy.questions.splice(questionIndex, 1);

        // Update the state with the edited object
        setUpdatedQuestions(updatedQuestionsCopy);
        setAddNewQuestion(false);
    };



    const checkEmptyValues = (obj) => {
        // Iterate over the keys of the object
        const allowedKeys = ['answer1Image', 'answer2Image', 'answer3Image', 'answer4Image', 'questionImage', 'questionList_id', 'poll'];
        for (const key in obj) {
            // Check if the value of the key is empty
            if (!allowedKeys.includes(key) && !obj[key]) {
                setEmptyValues(true);
                return false; // If empty value found, return false
            }
        }
        setEmptyValues(false);
        return true; // If no empty values found, return true
    }
    const copyQuestion = (questionIndex) => {
        const updatedQuestionsCopy = { ...updatedQuestions };
        const selectedQuestionCopy = { ...updatedQuestionsCopy.questions[questionIndex] };

        if(checkEmptyValues(updatedQuestions[questionIndex])){
            // Remove the question_id parameter from the copied question object
            delete selectedQuestionCopy.question_id;

            updatedQuestionsCopy.questions.push(selectedQuestionCopy);

            setUpdatedQuestions(updatedQuestionsCopy);
        }else{
            setModalMessage("Couldn't copy empty question");
            setCoursebotmodalIsOpen(true);
        }
    }
    var instructor_id = parseInt(localStorage.getItem("instructor_id"));
    const today = new Date();
    const dd = today.getDate().toString().padStart(2, '0');
    const mm = (today.getMonth() + 1).toString().padStart(2, '0');
    const yyyy = today.getFullYear().toString();
    const hh = today.getHours().toString().padStart(2, '0');
    const min = today.getMinutes().toString().padStart(2, '0');
    const ss = today.getSeconds().toString().padStart(2, '0');
    const formattedToday = dd + '/' + mm + '/' + yyyy + "  " + hh + ":" + min + ":" + ss;

    // Handler Update data on server
    const handleSave = async (questionListIndex) => {
        var selQuest = updatedQuestions;
        selQuest.lastModified = formattedToday;
        if(selQuest.questions.length >= 1){
            if(questionList_id !== null){
                // Check if there are new questions
                const isNewQuestionsAdded = selQuest.questions.some(question => !question.question_id);
                if(checkEmptyValues(selQuest.questions[selQuest.questions.length-1])){
                    if (isNewQuestionsAdded) {
                        // Create new questions one by one
                        for (let i = 0; i < selQuest.questions.length; i++) {
                            if (!selQuest.questions[i].question_id) {
                                const createQuestionResponse = await fetch(`https://gamizign.co:8000/instructors/${instructor_id}/questionlist/${selQuest.questionList_id}/questions`, {
                                    method: "POST",
                                    headers: {
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify(selQuest.questions[i]), // Use the actual question object
                                });

                                if (createQuestionResponse.ok) {
                                    const createdQuestion = await createQuestionResponse.json();
                                    selQuest.questions[i] = createdQuestion; // Update the original question with the created one
                                } else {
                                    console.error("Failed to create a new question");
                                }
                            }
                        }
                    }

                    // Continue with your existing PUT request code
                    const putOpt = {
                        method: "PUT",
                        redirect: "follow",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(selQuest),
                    };

                    try {
                        const response = await fetch(`https://gamizign.co:8000/instructors/${instructor_id}/questionlist/${selQuest.questionList_id}`, putOpt);
                        if (response.status === 204) {
                            navigate(`/Dashboard/questionList?questionList_id=${questionList_id}`);
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }else{
                    setModalMessage("Please fill question before saving");
                    setCoursebotmodalIsOpen(true);
                }
            }else{
                console.log(selQuest.questions[selQuest.questions.length-1]);
                console.log(checkEmptyValues(selQuest.questions[selQuest.questions.length-1]));
                if(checkEmptyValues(selQuest.questions[selQuest.questions.length-1])){
                    var inst_name = localStorage.getItem("name");
                    var req = {
                        "name" : updatedQuestions.name, 
                        "dateCreated":  formattedToday.split(' ')[0],
                        "createdBy": inst_name,
                        "lastModified": formattedToday.split(' ')[0], 
                        "questions": updatedQuestions.questions,
                    }
                    var activity = {
                        "name": "Activity" + course.stages[0].activities.length+1,
                        "activityType": "game",
                        "scoreVolume": 50,
                        "instructor_id": instructor_id,
                        "actionType": 0,
                        "dateCreated": formattedToday.split(' ')[0],
                        "createdBy": inst_name,
                    }

                    const response = await axios.post("https://gamizign.co:8000/instructors/" + instructor_id + "/questions/", req);
                    if (response.data) {
                        setIsProgress(false);
                        activity.questionList_id = response.data.questionList_id;
                        navigate('/Dashboard/chooseGame', {state:{ course: course , activity : activity, activity_index:course.stages[0].activities.length , questionList_id : response.data.questionList_id}});
                    } else {
                        setIsProgress(false);
                    }
                }else{
                    setModalMessage("Please fill question before saving");
                    setCoursebotmodalIsOpen(true);
                }
            }
        }else {
            setModalMessage('The questionlist must have at least one question');
            setCoursebotmodalIsOpen(true);
        }
    };


    useEffect(() => {

        const fetchData = async () => {
            try {
                if(questionList_id !== null){
                    const response = await fetch(`https://gamizign.co:8000/question-lists/${questionList_id}`);
                    const data = await response.json();

                    setSelectedQuestions(data);
                }
                else{
                    setSelectedQuestions({name: "", questions:[], dateCreated:""});
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [questionList_id]);
    // Question fields pop up
    const [CoursebotmodalIsOpen, setCoursebotmodalIsOpen] = useState(false);
    const EmptyQuestionBotcloseModal = () => {
        setCoursebotmodalIsOpen(false);
    };

    if (!selectedQuestions) {
        return <div>
            <div className="flex justify-center grid min-h-[140px] w-full place-question-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                <svg
                    className="text-gray-300 animate-spin"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100">
                    <path
                        d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                        stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                    </path>
                    <path
                        d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                        stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                    </path>
                </svg>
            </div>
        </div>;
    }

    return (
        <div>
            <div className='w-full'>
                <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                    <h3 className="text-xl">Question Lists</h3>
                    <LoginHeading />
                </div>
                <div className="sm:m-7 my-7 bg-white lg:px-20" disabled={progress}>
                {progress && (
                    <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                        <CircularProgress className="text-blue-800" />
                    </div>
                )}
                    {/* PopUp Fill all field Of question */}
                    <Modal
                        isOpen={CoursebotmodalIsOpen}
                        onRequestClose={EmptyQuestionBotcloseModal}
                        className="Modal flex justify-center mt-40"
                    // overlayClassName="Overlay"
                    >
                        {/* Modal Content */}
                        <div className="flex sm:ms-20" >
                            <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                                <div className="mt-5">
                                    <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                                    <h1 className={`sm:text-2xl `}>{modalMessage}</h1>
                                    <div className="flex justify-center gap-8 my-4">
                                        <button onClick={EmptyQuestionBotcloseModal}><b>OK</b></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
                    <div className="py-10 xl:px-20">
                        <div className="flex relative my-5 mx-2 sm:mx-5">
                            <label className="" htmlFor="Name of Course"><span><b>Title:</b></span>                                                   
                                <input className="border p-2 mx-5 rounded bg-gray-100" defaultValue={selectedQuestions.name !== ""?selectedQuestions.name:"My Manual Questionlist"} onChange={(e)=>updatedQuestions.name = e.target.value}/>
                            </label>
                            <label className="flex-1 text-right" htmlFor="Starting Date"> <b>Date Created: </b>
                                <span className="text-blue-600">{selectedQuestions.dateCreated !== ""? selectedQuestions.dateCreated: formattedToday.split(' ')[0]}</span>
                            </label>
                        </div>
                        <div>
                        {updatedQuestions.questions && updatedQuestions.questions.map((question, questionIndex) => (
                            <div key={Math.random()} id={Math.random()}>
                                <QuestionComponent 
                                question={question} 
                                questionIndex={questionIndex}  
                                onDelete={() => handleDelete(questionIndex)}
                                copyQuestion={() => copyQuestion(questionIndex)} 
                                />
                            </div>
                            ))}
                            {/* Here is New blank Question */}
                            <div>
                                {AddNewQuestion && (
                                    <QuestionComponent
                                        question={newQuestion}
                                        questionIndex={updatedQuestions.questions.length}
                                        // questionListIndex={questionListIndex}
                                        onDelete={() => handleDelete(updatedQuestions.questions.length)}
                                        copyQuestion={() => copyQuestion(updatedQuestions.questions.length)}
                                        onChange={() => checkEmptyValues(newQuestion)}
                                    />
                                )}
                            </div>

                            {/*  Add new Question Handler */}
                            {!emptyValues ?
                                <div className="mt-5 cursor-pointer" onClick={() => handleAddNew(updatedQuestions.questions.length)}>
                                    <div className="flex justify-center "><img src={AddNewActivity} alt="" /></div>
                                    <p className="flex justify-center mt-2">Add New Question</p>
                                </div>

                                : <></>
                            }

                                    <div className="flex justify-center gap-5 py-10">
                                            <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={(e)=>{questionList_id !== null?navigate('/Dashboard/questionList'):navigate(-1)}} >
                                                <b>Back </b>
                                                <img className='mt-1 ms-2' src={BackBtn} alt="Back" />
                                            </button>
                                        <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200'
                                            onClick={() => handleSave()}>
                                            <img className='mt-1 mr-2' src={SaveBtn} alt="Save" />
                                            <b> Save</b></button>
                                        {/* </Link> */}
                                    </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};

const QuestionComponent = ({ question, questionIndex, onDelete, copyQuestion, newQuestion, onChange }) => {
    const [loading, setLoading] = useState(false);
    const [questImgHover, setQuestImgHover] = useState(false);
    const [answer1ImgHover, setanswer1ImgHover] = useState(false);
    const [answer2ImgHover, setanswer2ImgHover] = useState(false);
    const [answer3ImgHover, setanswer3ImgHover] = useState(false);
    const [answer4ImgHover, setanswer4ImgHover] = useState(false);
    const fileInputRef = useRef(null);
    const url = 'https://gamizign.co:8000/uploads/questions/';

    const [questimgSrc, setQuestImgSrc] = useState(`${url}${question.questionImage}`);
    const [answer1ImgSrc, setanswer1ImgSrc] = useState(`${url}${question.answer1Image}`);
    const [answer2ImgSrc, setanswer2ImgSrc] = useState(`${url}${question.answer2Image}`);
    const [answer3ImgSrc, setanswer3ImgSrc] = useState(`${url}${question.answer3Image}`);
    const [answer4ImgSrc, setanswer4ImgSrc] = useState(`${url}${question.answer4Image}`);

    const handleClose = () => {
        if (!loading) {
            setQuestImgHover(false);
            setanswer1ImgHover(false);
            setanswer2ImgHover(false);
            setanswer3ImgHover(false);
            setanswer4ImgHover(false);
        }
    };

    const onImgError = (img) => {
        // eslint-disable-next-line default-case
        switch (img) {
            case 'question':
                setQuestImgSrc(PotoIcon);
                break;
            case 'answer1Image':
                setanswer1ImgSrc(PotoIcon);
                break;
            case 'answer2Image':
                setanswer2ImgSrc(PotoIcon);
                break;
            case 'answer3Image':
                setanswer3ImgSrc(PotoIcon);
                break;
            case 'answer4Image':
                setanswer4ImgSrc(PotoIcon);
                break;
        }
    }
    const handleClick = () => {
        fileInputRef.current.click();
    };
    const handleImageReplace = async (event, obj) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            setLoading(true);
            try {
                const response = await axios.post(`https://gamizign.co:8000/attachment?type=question`, formData);
                const fileName = response.data;
                if (response.status === 200) {
                    // Update the obj's property directly
                    // eslint-disable-next-line default-case
                    switch (obj) {
                        case 'question':
                            question.questionImage = fileName.message;
                            setQuestImgSrc(`${url}${question.questionImage}`);
                            break;
                        case 'answer1Image':
                            question.answer1Image = fileName.message;
                            setanswer1ImgSrc(`${url}${question.answer1Image}`);
                            break;
                        case 'answer2Image':
                            question.answer2Image = fileName.message;
                            setanswer2ImgSrc(`${url}${question.answer2Image}`);
                            break;
                        case 'answer3Image':
                            question.answer3Image = fileName.message;
                            setanswer3ImgSrc(`${url}${question.answer3Image}`);
                            break;
                        case 'answer4Image':
                            question.answer4Image = fileName.message;
                            setanswer4ImgSrc(`${url}${question.answer4Image}`);
                            break;
                    }
                    setLoading(false);
                    return fileName;
                } else {
                    setLoading(false);
                    console.log('An error occurred while registering students. Please try again later.');
                }


            } catch (error) {
                setLoading(false);
                console.error('Error uploading file:', error);
                return null;
            }
        }
    };

    const handleMouseEnter = () => {
        if (question.questionImage !== " " || question.questionImage !== null) {
            setQuestImgHover(true);
        }
    };

    const handleMouseLeave = () => {
        if (!loading)
            setQuestImgHover(false);
    };
    // Question Difficulty Level
    const QuestionLevel = (difficulty) => {
        switch (difficulty.toLowerCase()) {
            case 'easy':
                return 1;
            case 'medium':
                return 2;
            case 'hard':
                return 3;
            default:
                return 1;
        }
    };

    const handleDifficulty = (value) => {
        let level;
        // eslint-disable-next-line default-case
        switch (parseInt(value)) {
            case 1:
                level = 'easy';
                break;
            case 2:
                level = 'medium';
                break;
            case 3:
                level = 'hard';
                break;
        }
        question.difficulty = level;
    }
    // Question Bloom leve
    const [changeBloomLevel, setChangeBloomLevel] = useState(false)
    const getBloomLevel = (bloom_level) => {

        switch (bloom_level.toLowerCase()) {
            case 'remembering':
                return remembering;
            case 'understanding':
                return understanding;
            case 'applying':
                return applying;
            case 'analyzing':
                return analyzing;
            case 'evaluating':
                return evaluating;
            case 'creating':
                return creating;
            default:
                return remembering;
        }
    };
    const handleInputChange = (event) => {
        // Call the onChange function with the updated value
        onChange && onChange(event.target.value);
    };
    // Remove image from Question/ any answer
    const RemoveImage = (obj) => {
        // eslint-disable-next-line default-case
        switch (obj) {
            case 'question':
                question.questionImage = null;
                setQuestImgSrc('');
                break;
            case 'answer1Image':
                question.answer1Image = null;
                setanswer1ImgSrc('');
                break;
            case 'answer2Image':
                question.answer2Image = null;
                setanswer2ImgSrc('');
                break;
            case 'answer3Image':
                question.answer3Image = null;
                setanswer3ImgSrc('');
                break;
            case 'answer4Image':
                question.answer4Image = null;
                setanswer4ImgSrc('');
                break;
        }

        // if (!loading) {
        //     setQuestImgHover(false);
        //     setanswer1ImgHover(false);
        //     setanswer2ImgHover(false);
        //     setanswer3ImgHover(false);
        //     setanswer4ImgHover(false);
        // }
    }
    // color palette
    // const QuestionsColors = ['#ACCCE5', '#BCE2BB', '#DDE6C0', '#FFF3C6', '#FEE7C3', '#FCDCBD', '#F6C1C1', '#DFBFDD'];
    const QuestionsColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];


    return (
        <div>
            <div className="mt-7 flex justify-end pe-20 gap-2">
                <div>
                    <div className="flex justify-end gap-2">
                        <div className={`border rounded px-6 `} style={{ backgroundColor: "#FFF3C6", }}><b>E</b></div>
                        <div className={`border rounded px-6 `} style={{ backgroundColor: "#BCE2BB", }}><b>M</b></div>
                        <div className={`border rounded px-6 `} style={{ backgroundColor: "#EDAEC3", }}><b>H</b></div>
                    </div>
                    <div className="flex justify-end">
                        <div>
                            <input
                                id="steps-range"
                                type="range"
                                min="1"
                                max="3"
                                defaultValue={question.difficulty ? QuestionLevel(question.difficulty) : 1}
                                step="1"
                                onChange={(e) => handleDifficulty(e.target.value)}
                                className="h-2 px-5 bg-gray-200 rounded-lg appearance-none cursor-pointer bg-transparent"
                                style={{
                                    '--thumb-color': 'gray', // Change thumb color as desired
                                    '--thumb-width': '15px', // Change thumb width as desired
                                    '--thumb-height': '15px', // Change thumb height as desired
                                    '--thumb-radius': '0', // Set thumb radius to 0 to make it square
                                    'WebkitAppearance': 'none', // Remove default appearance
                                    'appearance': 'none', // Remove default appearance
                                    'cursor': 'pointer', // Add cursor pointer
                                    width: '200px',
                                }}
                            />
                            <style >{`
                                #steps-range::-webkit-slider-thumb {
                                WebkitAppearance: none;
                                appearance: none;
                                width: var(--thumb-width);
                                height: var(--thumb-height);
                                border: none;
                                background-color: var(--thumb-color);
                                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                                border-radius: var(--thumb-radius);
                                cursor: pointer;
                                }

                                #steps-range::-moz-range-thumb {
                                width: var(--thumb-width);
                                height: var(--thumb-height);
                                border: none;
                                background-color: var(#b6dff7);
                                clip-path: polygon(#DBE6F5;
                                border-radius: var(--thumb-radius);
                                cursor: pointer;
                                }
                            `}</style>
                        </div>
                    </div>
                </div>
                <div className="">
                    <img src={getBloomLevel(question.bloom_level)} alt="" />
                </div>
            </div>
            <div className="ms-2 sm:ms-7 flex gap-1 sm:gap-3">
                <div className="flex">
                    <b className="mt-5">{questionIndex + 1}. </b>
                    <span className="mt-5 hidden sm:block">
                        <span className="sm:mx-2 border px-1 sm:px-3 py-5" style={{ borderRadius: '5px' }}>
                            MCQs
                        </span>
                    </span>
                </div>
                {question.questionImage !== "" ? (
                    questImgHover ? (
                        <Dialog open={questImgHover} onClose={handleMouseLeave} >
                            <DialogTitle> Question {questionIndex + 1} image</DialogTitle>
                            {loading ? (
                                <CircularProgress className="text-blue-800 cursor-progress m-auto my-10 " />
                            ) :
                                (<img src={`${questimgSrc}`} onError={(e) => onImgError(`question`)} placeholder={PotoIcon} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Question"></img>)
                            }
                            <DialogActions>
                                <Button onClick={handleClose}>Close</Button>
                                <Button onClick={handleClick}>Replace</Button>
                                <Button onClick={() => { RemoveImage("question") }}>Remove</Button>
                                <input
                                    type="file"
                                    id="fileInput"
                                    ref={fileInputRef}
                                    className="absolute h-0 w-0 overflow-hidden"
                                    accept="image/*"
                                    onChange={(event) => { handleImageReplace(event, `question`) }}
                                />
                            </DialogActions>
                        </Dialog>
                    ) : (
                        <img
                            className="m-auto cursor-pointer"
                            src={questionImg}
                            alt=""
                            onChange={(event) => { handleImageReplace(event, `question`) }}
                            onMouseDown={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        />
                    )
                ) : null}
                <Accordion className="grow">
                    <div className="" style={{ backgroundColor: QuestionsColors[questionIndex % QuestionsColors.length] }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2-content"
                            id="panel2-header"
                        >
                            <Typography></Typography>

                            <div className="QUESTION flex w-full gap-2 rounded mx-0">
                                <input
                                    className="flex-1 mx-5 py-2 sm:ps-5"
                                    defaultValue={question.question}
                                    placeholder="Your question"
                                    type="text"
                                    // onChange={handleInputChange}
                                    onChange={(e) => question.question = e.target.value}
                                />

                                {/* <span>
                                <img className="flex-none py-2" onClick={() => onDelete(question)} src={DeletQuestion} alt="delete" />
                            </span> */}
                            </div>
                        </AccordionSummary>
                    </div>
                    <AccordionDetails>
                        <Typography>
                            <div className="pt-1">
                                <div className="flex w-full gap-2">
                                    <img className="m-auto" src={MCQsRight} alt="" />
                                    <input
                                        className="w-full border my-1 p-1 ps-5"
                                        defaultValue={question.answer1}
                                        placeholder="Text"
                                        type="text"
                                        onChange={(e) => question.answer1 = e.target.value}
                                    />
                                    {question.answer1Image !== "" ?
                                        answer1ImgHover ? (
                                            <Dialog open={answer1ImgHover} onClose={handleClose} >
                                                <DialogTitle>1st answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer1ImgSrc} onError={(e) => onImgError(`answer1Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer1"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer1Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer1Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <img className="m-auto cursor-pointer" src={PotoIcon} alt="" onMouseDown={() => setanswer1ImgHover(true)} onMouseLeave={() => setanswer1ImgHover(false)} />
                                            )
                                        : (<></>)
                                    }
                                </div>
                                <div className="flex w-full gap-2">
                                    <input
                                        className="w-full border my-1 p-1 ps-5"
                                        defaultValue={question.answer2}
                                        placeholder="Text"
                                        type="text"
                                        onChange={(e) => question.answer2 = e.target.value}
                                    />
                                    {question.answer2Image !== "" ?
                                        answer2ImgHover ? (
                                            <Dialog open={answer2ImgHover} onClose={handleClose} >
                                                <DialogTitle>2nd answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer2ImgSrc} onError={(e) => onImgError(`answer2Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer2"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer2Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer2Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <img className="m-auto cursor-pointer" src={PotoIcon} alt="" onMouseDown={() => { setanswer2ImgHover(true) }} onMouseLeave={() => setanswer2ImgHover(false)} />
                                            )
                                        : (<></>)
                                    }
                                </div>
                                <div className="flex w-full gap-2">
                                    <input
                                        className="w-full border my-1 p-1 ps-5"
                                        defaultValue={question.answer3}
                                        placeholder="Text"
                                        type="text"
                                        onChange={(e) => question.answer3 = e.target.value}
                                    />
                                    {question.answer3Image !== "" ?
                                        (answer3ImgHover) ? (
                                            <Dialog open={answer3ImgHover} onClose={handleClose} >
                                                <DialogTitle>3rd answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer3ImgSrc} onError={(e) => onImgError(`answer3Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer3"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer3Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer3Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <img className="m-auto cursor-pointer" src={PotoIcon} alt="" onMouseDown={() => { setanswer3ImgHover(true) }} onMouseLeave={() => setanswer3ImgHover(false)} />
                                            )
                                        : (<></>)
                                    }
                                </div>
                                <div className="flex w-full gap-2">
                                    <input
                                        className="w-full border my-1 p-1 ps-5"
                                        defaultValue={question.answer4}
                                        placeholder="Text"
                                        type="text"
                                        onChange={(e) => question.answer4 = e.target.value}
                                    />
                                    {question.answer4Image !== "" ?
                                        answer4ImgHover ? (
                                            <Dialog open={answer4ImgHover} onClose={handleClose} >
                                                <DialogTitle>4th answer of question {questionIndex + 1} image</DialogTitle>
                                                {loading ? (
                                                    <CircularProgress className="text-blue-800 cursor-progress m-auto my-10" />
                                                ) :
                                                    (<img src={answer4ImgSrc} onError={(e) => onImgError(`answer4Image`)} style={{ margin: 'auto', maxWidth: "auto", height: 'auto' }} alt="Answer4"></img>)
                                                }
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Close</Button>
                                                    <Button onClick={handleClick}>Replace</Button>
                                                    <Button onClick={() => { RemoveImage("answer4Image") }}>Remove</Button>
                                                    <input
                                                        type="file"
                                                        id="fileInput"
                                                        ref={fileInputRef}
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        accept="image/*"
                                                        onChange={(event) => { handleImageReplace(event, `answer4Image`) }}
                                                    />
                                                </DialogActions>
                                            </Dialog>
                                        )
                                            :
                                            (
                                                <img className="m-auto cursor-pointer" src={PotoIcon} alt="" onMouseDown={() => { setanswer4ImgHover(true) }} onMouseLeave={() => setanswer4ImgHover(false)} />
                                            )
                                        : (<></>)
                                    }
                                </div>

                            </div>
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <img className="flex-none mt-4  cursor-pointer w-6 h-6" onClick={() => copyQuestion()} src={DuplicateQuestoin} alt="duplicate" />
                <img className="flex-none mt-4  cursor-pointer w-6 h-6" onClick={() => onDelete()} src={DeletQuestion} alt="delete" />
                <span>
                </span>
            </div>
        </div>
    );
};
export default QuestionListEdit;    