import React, { useEffect, useState } from "react";
import './style.css'
// Icons 
import NewCourseHeadIcon from '../../../../../assets/icons/ActivityContentIcons/New Course head icon.png'
import NewCourseIcon from '../../../../../assets/icons/ActivityContentIcons/New Course icon.png'
import CurrentCourseIcon from '../../../../../assets/icons/ActivityContentIcons/Current Courses icon.png'
import CurrentCourseHeadIcon from '../../../../../assets/icons/ActivityContentIcons/Current Courses head icon.png'
import { Link } from "react-router-dom";
import LoginHeading from "../../../../LoginHeading/LoginHeading";


const Activity = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [courses, setCourses] = useState([]);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

 


    useEffect(() => {
        const getCourses = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            var instructor_id = parseInt(localStorage.getItem("instructor_id"));
            await fetch(`https://gamizign.co:8000/instructors/${instructor_id}/courses`, requestOptions).then(response => response.json()).then(data => {
                setCourses(data);
            });
        }
        getCourses();

        const handleOutsideClick = (e) => {
            if (!e.target.closest('.dropdown')) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    return (
        <div className="w-full">
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Create New Activity</h3>
                <LoginHeading/>
                </div>
            <div className="flex flex-wrap justify-center gap-5 bg-white m-7 contentSides">
                <div className="flex-none">
                    {/* New courses Dropdown */}
                    <div className="relative inline-block text-left mt-20">
                    <img className="ml-10" src={NewCourseHeadIcon} alt="" />
                        {/* Dropdown button */}
                        <div className="group">
                        <button className="p-3 flex NewCourseBg"><img className="pr-3 " src={NewCourseIcon} alt="NewIcon" /><p className="mt-2   "><b>New</b> Course</p></button>
                            {/* Dropdown panel */}
                            <div className="origin-top-right absolute right-0 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 invisible group-hover:visible">
                                <div className="py-1">
                                    {/* Option 1 */}
                                    <Link
                                        to="/Dashboard/newcourse"
                                        className="flex justify-center block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    >
                                        Single Course
                                    </Link>

                                    {/* Option 2 */}
                                    <Link
                                        to="/Dashboard/journeyCourse"
                                        className="flex justify-center block px-4 py-2 text-base text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                    >
                                        Journey Course
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  Dropdown Menu of Current Course Button is Here */}
                <div className="flex-none relative inline-block text-left dropdown">
                    <div className="mt-20">
                        <img className="ml-10" src={CurrentCourseHeadIcon} alt="" />
                        <button
                            type="button"
                            className="inline-flex CurrentCourseBg justify-center w-full rounded-md border border-gray-300 shadow-sm  py-3 text-base  hover:bg-blue-200"
                            onClick={handleToggle}>
                            <img className="pr-3" src={CurrentCourseIcon} alt="CurrentIcon" /><p className="mt-2"><b>Current</b> Courses</p>
                        </button>
                    </div>

                    {isOpen && (
                        <div className="origin-top-right absolute right-0 overflow-y-auto max-h-64 w-full py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                {courses && Array.isArray(courses) ? (
                                    courses.map((course, index) => (
                                        <a
                                            key={index}
                                            href={`/Course?course_id=${course.course_id}`}
                                            // onClick={() => handleSelect(course)}
                                            className={`block px-4 py-2 text-base ${selectedOption === course ? 'text-blue-200' : 'text-gray-700'} hover:bg-blue-200 hover:text-gray-900`}
                                            role="menuitem"
                                        >
                                            {course.name}
                                        </a>
                                    ))
                                ) : (
                                    <p>No courses available</p>
                                )}

                            </div>

                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};

export default Activity;