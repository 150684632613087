import React, { useEffect, useState } from "react";
import { addStyles, EditableMathField } from 'react-mathquill'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from 'react-modal'
// bloom level
import remembering from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 1st level 36-36.png'
import understanding from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 2nd level 36-36.png'
import applying from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 3rd level 36-36.png'
import analyzing from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 4th level 36-36.png'
import evaluating from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 5th level 36-36.png'
import creating from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 6th level 36-36.png'
// Images
import MultiActivity from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch3/Activity-Type/multiactivity  icon hover off.png'
import Image from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/image icon _ multi activity.png'
import Video from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/video icon _ multi activity.png'
import Audio from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/audio icon _ multi activity.png'
import Text from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/assay icon _ multi activity.png'
import RankOrProccess from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/rank or process icon _ multi activity.png'
// Question Card images 
import MicIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Mic icon.png'
import PotoIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/photo icon 28-28.png'
import MathIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/math symbole icon 28-28.png'
import CloseIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/close icon fixed.png'
import MCQsRight from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/MCQ right answer.png'
import MCQsWrong from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/MCQ wrong answer.png'
import QuestiontypeBtnIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/question level bar.png'
import QuestiontypeTriangL3leIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/bloom 3rd level 36-36.png'
import AddNewActivity from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Add New Manual Question icon.png'
import BackIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/back iconB.png'
import SaveIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/save icon.png'
import DragDropIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Drag & Dorp fixed color icon.png'
import UploadIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Upload a File fixed color icon.png'
import MicUploadIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Audio icon.png'
import ImageUploadIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/image icon hover off color.png'
import VideoUploadIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/video icon hover off color.png'
import VoiceRecorderIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Voice Recorder icon.png'
import ArrowUpIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/double arrow - up.png'
// 
import EditQuestoin from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/edit icon.png'
import DeletQuestoin from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/delete icon.png'
import DuplicateQuestoin from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/Duplicate question icon.png'
import RadioOnIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/radio button on.png'
import RadioOffIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/Patch4-MultiActivity/radio button off.png'
import AlertIcon from '../../../../../../../assets/icons/Alert-icon.png'

import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";
import moment from "moment";
import DatePicker from "react-datepicker";



const MultiActivy = ({ resetActivityTypemain }) => {
    const { course, courseFormatDate, activity, stageIndex } = useLocation().state;
    const [latex, setLatex] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedFileVideo, setSelectedFileVideo] = useState(null);
    const [selectedFileAudio, setSelectedFileAudio] = useState(null);
    const [MultipleTypeQuestions, setMultipleTypeQuestions] = useState([]);
    const [SelecteQuestionType, SetSelecteQuestionType] = useState(null);
    const [ErrorMessage, SetErrorMessage] = useState('Kindly fill all the fields of the last question before adding new one question!');
    // Activity formation
    const [dateCreated, setDateCreated] = useState(moment().format("DD/MM/YYYY HH:mm:ss"));
    const [startingDate, setStartingDate] = useState(courseFormatDate);
    const [activityData, setActivityData] = useState({
        name: `Multi Activity ${course.stages[stageIndex].activities.length + 1}`,
        activityType: 'multiActivity',
        instructor_id: localStorage.getItem("instructor_id"),
        activity_file_name: "",
        description: "",
        scoreVolume: 50,
        url: "",
        gameType: null,
        dateCreated: dateCreated,
        createdBy: localStorage.getItem("name"),
        answer_requirement: "",
        answer_exact: "",
        activity_case: "",
        actionType: 1,
        questionList_id: null,
        questions: MultipleTypeQuestions,
    });
    useEffect(() => {
        setActivityData(prevState => ({
            ...prevState,
            questions: MultipleTypeQuestions,
        }));
    }, [MultipleTypeQuestions]);
    const HandelNameActivity = (event) => {
        setActivityData(prevState => ({
            ...prevState,
            name: event.target.value
        }));
    };
    // date formation
    const [date, setDate] = useState(courseFormatDate);
    const [stageFormatDate, setstageFormatDate] = useState(course.stages[stageIndex].startingDate == null ? courseFormatDate : date);
    const handleDateChange = (selectedDate) => {
        setDate(selectedDate);
        setstageFormatDate(selectedDate);
    };
    const navigate = useNavigate();
    const HanldeBackActivityType = () => {
        navigate('/Dashboard/activityType', { state: { course: course, courseFormatDate: courseFormatDate } });
    };
    // Save course and validation
    const HandelSaveMultiActivity = () => {
        const lastQuestion = MultipleTypeQuestions[MultipleTypeQuestions.length - 1];

        let isLastQuestionIncomplete;
        if (lastQuestion?.questionType === 'easy') {
            isLastQuestionIncomplete = !lastQuestion?.question || !lastQuestion?.answer1;
        } else {
            isLastQuestionIncomplete = !lastQuestion?.question || !lastQuestion?.answer1 || !lastQuestion?.answer2 || !lastQuestion?.answer3 || !lastQuestion?.answer4;
        }

        if (MultipleTypeQuestions.length === 0) {
            setCoursebotmodalIsOpen(true);
            console.error('Please add a question befor saving.');
            SetErrorMessage('Please add a question befor saving.');
        } else if (isLastQuestionIncomplete) {
            setCoursebotmodalIsOpen(true);
            console.error('Kindly fill all the fields of the last question befor saving');
            SetErrorMessage('Please fill all the fields of the last question befor saving.');
        } else {
            navigate(`/Dashboard/gameDoneJourney`, { state: { course: course, activity: activityData, stageIndex: stageIndex, courseFormatDate: courseFormatDate } });
        }
    };
    // essay Question Is Exactily and Contains Options
    const [selectedOptionEssay, setSelectedOptionEssay] = useState('contain');
    const handleOptionChangeEssay = (value, question) => {
        setSelectedOptionEssay(value);
        question.answer_exact = value;
    };
    // Voice recorder
    const [isRecording, setIsRecording] = useState(false);
    const [audioChunks, setAudioChunks] = useState([]);
    const [audioUrl, setAudioUrl] = useState('');

    const [activityType, setActivityType] = useState('');

    const get_activity_type = (type) => {
        setActivityType(type);
    };

    const resetActivityType = () => {
        setActivityType('');
    };
    // Add new question 
    const [IsquestionType, setIsquestionType] = useState('');
    const addQuestionMultiple = (questionType) => {
        // If there are questions already in the array, validate the last one
        if (MultipleTypeQuestions.length > 0) {
            // Validate the last question only if the array already has questions
            const lastQuestion = MultipleTypeQuestions[MultipleTypeQuestions.length - 1];

            let isLastQuestionIncomplete;
            if (lastQuestion.questionType === 'easy') {
                isLastQuestionIncomplete = !lastQuestion.question || !lastQuestion.answer1;
            } else {
                isLastQuestionIncomplete = !lastQuestion.question || !lastQuestion.answer1 || !lastQuestion.answer2 || !lastQuestion.answer3 || !lastQuestion.answer4;
            }

            if (isLastQuestionIncomplete) {
                setCoursebotmodalIsOpen(true);
                SetErrorMessage('Kindly fill all the fields of the last question before adding new one question!');
                console.error('Kindly fill all the fields of the last question before adding new one question!');
                return;
            }
        }

        // Create a new question object based on questionType
        const newQuestionObject = questionType === 'easy' ? {
            question: "",
            answer1: "",
            answer_exact: selectedOptionEssay,
            answer1Image: null,
            questionImage: null,
            poll: false,
            bloom_level: "Remembering",
            slide_no: "manual",
            difficulty: "easy",
            questionList_id: null,
            questionType: questionType.toString()
        } : {
            question: "",
            answer1: "",
            answer2: "",
            answer3: "",
            answer4: "",
            answer1Image: null,
            answer2Image: null,
            answer3Image: null,
            answer4Image: null,
            questionImage: null,
            poll: false,
            bloom_level: "Remembering",
            slide_no: "manual",
            difficulty: "easy",
            questionList_id: null,
            questionType: questionType.toString()
        };

        // Add the new question object to the MultipleTypeQuestions array
        setMultipleTypeQuestions([...MultipleTypeQuestions, newQuestionObject]);
        resetActivityType();
        setIsquestionType('');
        SetSelecteQuestionType(null);
    };

    // Delete Question Multiple
    const handleDeleteMultiple = (questionIndex) => {
        const updatedQuestionsCopy = [...MultipleTypeQuestions];
        updatedQuestionsCopy.splice(questionIndex, 1);
        setMultipleTypeQuestions(updatedQuestionsCopy);
    };
    // copy Question multiple
    const copyQuestionMultiple = (questionIndex) => {
        const updatedQuestionsCopy = [...MultipleTypeQuestions];
        const selectedQuestionCopy = { ...updatedQuestionsCopy[questionIndex] };
        updatedQuestionsCopy.push(selectedQuestionCopy);
        setMultipleTypeQuestions(updatedQuestionsCopy);
    }
    // Model error to add new question
    const [CoursebotmodalIsOpen, setCoursebotmodalIsOpen] = useState(false);
    const EmptyQuestionBotcloseModal = () => {
        setCoursebotmodalIsOpen(false);
        resetActivityType();
    };
    // Difficulty level and blom-level of questions 
    // Question Difficulty Level
    const QuestionLevel = (difficulty) => {
        switch (difficulty.toLowerCase()) {
            case 'easy':
                return 1;
            case 'medium':
                return 2;
            case 'hard':
                return 3;
            default:
                return 1;
        }
    };

    const handleDifficulty = (value, question) => {
        let level;
        // eslint-disable-next-line default-case
        switch (parseInt(value)) {
            case 1:
                level = 'easy';
                break;
            case 2:
                level = 'medium';
                break;
            case 3:
                level = 'hard';
                break;
        }
        question.difficulty = level;
    }
    // Question Bloom leve
    const getBloomLevel = (bloom_level) => {

        switch (bloom_level.toLowerCase()) {
            case 'remembering':
                return remembering;
            case 'understanding':
                return understanding;
            case 'applying':
                return applying;
            case 'analyzing':
                return analyzing;
            case 'evaluating':
                return evaluating;
            case 'creating':
                return creating;
            default:
                return remembering;
        }
    };

    const renderOptions = () => {
        // Image upload
        const handleFileChangeimage = (e) => {
            const file = e.target.files[0];
            setSelectedFile(file);
        };
        const handleDropimage = (e) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            const isValidFileType = Array.from(files).some(
                (file) => file.type === 'image/jpeg' || file.type === 'image/png'
            );
            if (isValidFileType) {
                const file = files[0];
                setSelectedFile(file);
                // Handle the dropped file as needed
            } else {
                console.log('Invalid file type. Please drop a .jpg or .png file.');
            }
        };
        const handleDragOverimage = (e) => {
            e.preventDefault();
        };
        // Video Upload
        const handleFileChangeVideo = (e) => {
            const file = e.target.files[0];
            setSelectedFileVideo(file);
        };

        const handleDropVideo = (e) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            const isValidFileType = Array.from(files).some(
                (file) => file.type.startsWith('video/')
            );

            if (isValidFileType) {
                // Handle the dropped video file as needed
                const videoFile = files[0];
                setSelectedFileVideo(videoFile);
            } else {
                console.log('Invalid file type. Please drop a video file.');
            }
        };
        const handleDragOverVideo = (e) => {
            e.preventDefault();
        };
        // Audio upload
        const handleFileChangeAudio = (e) => {
            const file = e.target.files[0];
            setSelectedFileAudio(file);
        };

        const handleDropAudio = (e) => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            const isValidFileType = Array.from(files).some(
                (file) => file.type.startsWith('audio/')
            );

            if (isValidFileType) {
                // Handle the dropped video file as needed
                const audioFile = files[0];
                setSelectedFileAudio(audioFile);
            } else {
                console.log('Invalid file type. Please drop a video file.');
            }
        };
        const handleDragOverAudio = (e) => {
            e.preventDefault();
        };
        // voice recorder

        let mediaRecorder;

        const startRecording = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

                mediaRecorder = new MediaRecorder(stream);

                mediaRecorder.ondataavailable = (event) => {
                    if (event.data.size > 0) {
                        setAudioChunks((prevChunks) => [...prevChunks, event.data]);
                    }
                };

                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
                    const url = URL.createObjectURL(audioBlob);
                    setAudioUrl(url);
                };

                mediaRecorder.start();
                setIsRecording(true);
            } catch (error) {
                console.error('Error accessing microphone:', error);
            }
        };

        const stopRecording = () => {
            if (mediaRecorder.state !== 'inactive') {
                mediaRecorder.stop();
                setIsRecording(false);
            }
        };
        switch (activityType) {
            case 'image':
                return (
                    <div>
                        <div className='w-full'>
                            <div className='px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3></div>
                            <div className="m-7 bg-white  ">
                                {/* PopUp Fill all field Of question */}
                                <Modal
                                    isOpen={CoursebotmodalIsOpen}
                                    onRequestClose={EmptyQuestionBotcloseModal}
                                    className="Modal flex justify-center mt-40"
                                // overlayClassName="Overlay"
                                >
                                    {/* Modal Content */}
                                    <div className="flex sm:ms-20" >
                                        <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                                            <div className="mt-5">
                                                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                                                <h1 className={`sm:text-2xl `}>Kindly fill all the fields of the last question before adding new one question!</h1>
                                                <div className="flex justify-center gap-8 my-4">
                                                    <button onClick={EmptyQuestionBotcloseModal}><b>OK</b></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                <div className="px-5 py-14 " >
                                    <div className=" w-full  static" style={{ borderRadius: '20px', height: '500px', backgroundColor: 'rgb(240, 245, 244)' }}>
                                        <div className='flex justify-center '><img className='PdfImage' src={ImageUploadIcon} alt="" /></div>
                                        <div className="flex justify-center">
                                            <div className="bg-white py-10 px-16 mt-20" style={{ borderRadius: '20px' }}>
                                                <label
                                                    htmlFor="fileInput"
                                                    style={{ borderRadius: '20px' }}
                                                    className="flex InputBg w-100 relative cursor-pointer border-2 border-dashed border-gray-300 p-4 rounded-xl transition duration-300 hover:border-blue-500"
                                                    onDrop={handleDropimage}
                                                    onDragOver={handleDragOverimage}>
                                                    <input
                                                        type="file"
                                                        accept='image/*'
                                                        id="fileInput"
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        onChange={handleFileChangeimage}
                                                    />
                                                    <div className="text-center md:flex gap-3">
                                                        <div className='text-center mt-3 py-5 px-10'>
                                                            <div className='flex justify-center'><img src={DragDropIcon} alt="drag" /></div>
                                                            <p>Drag & Drop</p>
                                                        </div>
                                                        <span className='mt-14 mr-5'>OR</span>
                                                        <div className='bg-white flex gap-2 p-3 px-5 rounded-xl'>
                                                            <div className='px-20 py-5'>
                                                                <div className='flex justify-center'><img src={UploadIcon} alt="uploadFile" /></div>
                                                                <p>Upload a File</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                                <div className="flex justify-center mt-4">
                                                    <img src={ArrowUpIcon} alt="arrow" />
                                                </div>
                                                <p className="flex justify-center text-blue-800">Image size less than  1mb with ratio of 2:3 JPG or PNG extention</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5 py-5">
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType}><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={() => { addQuestionMultiple('image') }} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'video':
                return (
                    <div>
                        <div className='w-full'>
                            <div className='px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3></div>
                            <div className="m-7 bg-white  ">
                                {/* PopUp Fill all field Of question */}
                                <Modal
                                    isOpen={CoursebotmodalIsOpen}
                                    onRequestClose={EmptyQuestionBotcloseModal}
                                    className="Modal flex justify-center mt-40"
                                // overlayClassName="Overlay"
                                >
                                    {/* Modal Content */}
                                    <div className="flex sm:ms-20" >
                                        <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                                            <div className="mt-5">
                                                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                                                <h1 className={`sm:text-2xl `}>Kindly fill all the fields of the last question before adding new one question!</h1>
                                                <div className="flex justify-center gap-8 my-4">
                                                    <button onClick={EmptyQuestionBotcloseModal}><b>OK</b></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                <div className="px-5 py-14 " >
                                    <div className=" w-full  static" style={{ borderRadius: '20px', height: '500px', backgroundColor: 'rgb(240, 245, 244)' }}>
                                        <div className='flex justify-center '><img className='PdfImage' src={VideoUploadIcon} alt="" /></div>
                                        <div className="flex justify-center">
                                            <div className="bg-white py-10 px-16 mt-20" style={{ borderRadius: '20px' }}>
                                                <label
                                                    htmlFor="fileInput"
                                                    style={{ borderRadius: '20px' }}
                                                    className="flex InputBg w-100 relative cursor-pointer border-2 border-dashed border-gray-300 p-4 rounded-xl transition duration-300 hover:border-blue-500"
                                                    onDrop={handleDropVideo}
                                                    onDragOver={handleDragOverVideo}>
                                                    <input
                                                        type="file"
                                                        accept='video/*'
                                                        id="fileInput"
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        onChange={handleFileChangeVideo}
                                                    />
                                                    <div className="text-center md:flex gap-3">
                                                        <div className='text-center mt-3 py-5 px-10'>
                                                            <div className='flex justify-center'><img src={DragDropIcon} alt="drag" /></div>
                                                            <p>Drag & Drop</p>
                                                        </div>
                                                        <span className='mt-14 mr-5'>OR</span>
                                                        <div className='bg-white flex gap-2 p-3 px-5 rounded-xl'>
                                                            <div className='px-20 py-5'>
                                                                <div className='flex justify-center'><img src={UploadIcon} alt="uploadFile" /></div>
                                                                <p>Upload a File</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                                <div className="flex justify-center mt-4">
                                                    <img src={ArrowUpIcon} alt="arrow" />
                                                </div>
                                                <p className="flex justify-center text-blue-800">Video size less than 2mb with ratio 2:3 or 3:2 by MP4 extention</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5 py-5">
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType}><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={() => { addQuestionMultiple('video') }} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'audio':
                return (
                    <div>
                        <div className='w-full'>
                            <div className='px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3></div>
                            <div className="m-7 bg-white  ">
                                {/* PopUp Fill all field Of question */}
                                <Modal
                                    isOpen={CoursebotmodalIsOpen}
                                    onRequestClose={EmptyQuestionBotcloseModal}
                                    className="Modal flex justify-center mt-40"
                                // overlayClassName="Overlay"
                                >
                                    {/* Modal Content */}
                                    <div className="flex sm:ms-20" >
                                        <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                                            <div className="mt-5">
                                                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                                                <h1 className={`sm:text-2xl `}>Kindly fill all the fields of the last question before adding new one question!</h1>
                                                <div className="flex justify-center gap-8 my-4">
                                                    <button onClick={EmptyQuestionBotcloseModal}><b>OK</b></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Modal>
                                <div className="px-5 py-14 " >
                                    <div className=" w-full  static" style={{ borderRadius: '20px', height: '500px', backgroundColor: 'rgb(240, 245, 244)' }}>
                                        <div className='flex justify-center '><img className='PdfImage' src={MicUploadIcon} alt="" /></div>
                                        <div>
                                            <button onClick={startRecording} disabled={isRecording}>
                                                Start Recording
                                            </button>
                                            <button onClick={stopRecording} disabled={!isRecording}>
                                                Stop Recording
                                            </button>
                                            {audioUrl && (
                                                <div>
                                                    <audio controls src={audioUrl}></audio>
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex justify-center">
                                            <div className="bg-white py-10 px-16 mt-20" style={{ borderRadius: '20px' }}>
                                                <label
                                                    htmlFor="fileInput"
                                                    style={{ borderRadius: '20px' }}
                                                    className="flex InputBg w-100 relative cursor-pointer border-2 border-dashed border-gray-300 p-4 rounded-xl transition duration-300 hover:border-blue-500"
                                                    onDrop={handleDropAudio}
                                                    onDragOver={handleDragOverAudio}>
                                                    <input
                                                        type="file"
                                                        accept='audio/*'
                                                        id="fileInput"
                                                        className="absolute h-0 w-0 overflow-hidden"
                                                        onChange={handleFileChangeAudio}
                                                    />
                                                    <div className="text-center md:flex gap-3">
                                                        <div className='text-center mt-3 py-5 px-10'>
                                                            <div className='flex justify-center'><img src={DragDropIcon} alt="drag" /></div>
                                                            <p>Drag & Drop</p>
                                                        </div>
                                                        <span className='mt-14 mr-5'>OR</span>
                                                        <div className='bg-white flex gap-2 p-3  rounded-xl'>
                                                            <div className='px-10 py-5'>
                                                                <div className='flex justify-center'><img src={UploadIcon} alt="uploadFile" /></div>
                                                                <p>Upload Audio</p>
                                                            </div>
                                                            <div className='px-20 py-5'>
                                                                <div className='flex justify-center'><img src={VoiceRecorderIcon} alt="uploadFile" /></div>
                                                                <p>Voice Recorder</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                                <div className="flex justify-center mt-4">
                                                    <img src={ArrowUpIcon} alt="arrow" />
                                                </div>
                                                <p className="flex justify-center text-blue-800">Audio size less than 1 mb by MP3 extension</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5 py-5">
                                    <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" onClick={resetActivityType} ><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={() => { addQuestionMultiple('audio') }} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 'text':
                return <p onClick={addQuestionMultiple}>Text Attachment</p>;
            case 'rank':
                return (
                    <div>
                        <h1 onClick={addQuestionMultiple}>Rank page is here</h1>
                    </div>
                );
            case 'eqution':
                return (
                    <div>
                        <div className='w-full'>
                            <div className='px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl">Create New Activity</h3></div>
                            <div className="m-7 bg-white contentSide static">
                                <div className='flex justify-center '><img className='PdfImage' src={MultiActivity} alt="" /></div>
                                {/* Eqution editer here */}
                                <div className="px-5 my-10" >
                                    <div className=" w-full flex justify-center py-5" style={{ borderRadius: '20px', height: '500px', backgroundColor: 'rgb(240, 245, 244)' }}>
                                        <div className="bg-white p-5" style={{ borderRadius: '20px' }}>
                                            <div className="border">
                                                <EditableMathField
                                                    latex={latex}
                                                    onChange={(mathField) => {
                                                        setLatex(mathField.latex())
                                                    }}
                                                />
                                                <p>{latex}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex  justify-center gap-5">
                                    <Link to='/Dashboard/activityType'>
                                        <button className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200" ><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                    </Link>
                                    {/* <Link to='/Dashboard/ImportContent'> */}
                                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={resetActivityType} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                    {/* </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div>
            {!activityType && (
                <div>
                    {/* Your main content goes here */}

                </div>
            )}

            {activityType ? (
                <div>
                    {renderOptions()}
                    <button onClick={resetActivityType}>Reset</button>
                </div>
            ) : (
                <div>
                    <div className='w-full'>
                        <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                            <h3 className="text-xl">Create New Activity</h3>
                            <LoginHeading />
                        </div>
                        <div className="m-7 bg-white  static">
                            <div className='flex justify-center '><img className='PdfImage' src={MultiActivity} alt="" /></div>
                            {/* PopUp Fill all field Of question */}
                            <Modal
                                isOpen={CoursebotmodalIsOpen}
                                onRequestClose={EmptyQuestionBotcloseModal}
                                className="Modal flex justify-center mt-40"
                            // overlayClassName="Overlay"
                            >
                                {/* Modal Content */}
                                <div className="flex sm:ms-20" >
                                    <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                                        <div className="mt-5">
                                            <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                                            <h1 className={`sm:text-2xl `}>{ErrorMessage}</h1>
                                            <div className="flex justify-center gap-8 my-4">
                                                <button onClick={EmptyQuestionBotcloseModal}><b>OK</b></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <div className="mx-40 my-5">
                                <div className="flex relative">
                                    <label htmlFor=""><b>Title</b>
                                        <input className="border p-2 mx-5 rounded bg-gray-100" type="text" defaultValue={`Multi Activity`} id="Title" onChange={(e) => HandelNameActivity(e)} />                                    </label>
                                    <div className="">
                                        <div className='my-2 absolute  right-0'>
                                            <label htmlFor="date">Starting Date </label>
                                            <DatePicker className='text-sky-600 border p-2 mx-5 rounded bg-gray-100' minDate={courseFormatDate} selected={stageFormatDate} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />
                                            {/* <span className='text-sky-600'>{date}</span> */}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {MultipleTypeQuestions?.map((question, questionIndex) => (<div>
                                        <div className="mt-7 flex justify-end pe-10 gap-2">
                                            <div>
                                                <div className="flex justify-end gap-2">
                                                    <div className={`border rounded px-6 `} style={{ backgroundColor: "#FFF3C6", }}><b>E</b></div>
                                                    <div className={`border rounded px-6 `} style={{ backgroundColor: "#BCE2BB", }}><b>M</b></div>
                                                    <div className={`border rounded px-6 `} style={{ backgroundColor: "#EDAEC3", }}><b>H</b></div>
                                                </div>
                                                <div className="flex justify-end">
                                                    <div>
                                                        <input
                                                            id="steps-range"
                                                            type="range"
                                                            min="1"
                                                            max="3"
                                                            defaultValue={question.difficulty ? QuestionLevel(question.difficulty) : 1}
                                                            step="1"
                                                            onChange={(e) => handleDifficulty(e.target.value, question)}
                                                            className="h-2 px-5 bg-gray-200 rounded-lg appearance-none cursor-pointer bg-transparent"
                                                            style={{
                                                                '--thumb-color': 'gray', // Change thumb color as desired
                                                                '--thumb-width': '15px', // Change thumb width as desired
                                                                '--thumb-height': '15px', // Change thumb height as desired
                                                                '--thumb-radius': '0', // Set thumb radius to 0 to make it square
                                                                'WebkitAppearance': 'none', // Remove default appearance
                                                                'appearance': 'none', // Remove default appearance
                                                                'cursor': 'pointer', // Add cursor pointer
                                                                width: '200px',
                                                            }}
                                                        />
                                                        <style >{`
                                #steps-range::-webkit-slider-thumb {
                                WebkitAppearance: none;
                                appearance: none;
                                width: var(--thumb-width);
                                height: var(--thumb-height);
                                border: none;
                                background-color: var(--thumb-color);
                                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
                                border-radius: var(--thumb-radius);
                                cursor: pointer;
                                }

                                #steps-range::-moz-range-thumb {
                                width: var(--thumb-width);
                                height: var(--thumb-height);
                                border: none;
                                background-color: var(#b6dff7);
                                clip-path: polygon(#DBE6F5;
                                border-radius: var(--thumb-radius);
                                cursor: pointer;
                                }
                            `}</style>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <img src={getBloomLevel(question.bloom_level)} alt="" />
                                            </div>
                                        </div>
                                        {/* Easy type question */}
                                        {question.questionType === 'easy' &&
                                            <div key={question.id} className="ms-7 flex gap-3">
                                                <div className="flex">
                                                    <b className="mt-5">{questionIndex + 1}. </b>
                                                    <span><img className="mx-2 border p-1" style={{ borderRadius: '5px' }} src={Text} alt="" /></span>
                                                </div>
                                                <Accordion className="grow">
                                                    <div style={{ backgroundColor: '#F6C1C1' }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2-content"
                                                            id="panel2-header"
                                                        >
                                                            <Typography>

                                                            </Typography>
                                                            <div className="QUESTION flex w-full gap-2 rounded p-2 mx-0">
                                                                <input onChange={(e) => question.question = e.target.value} defaultValue={question.question} className="flex-1 mx-5 ps-5" placeholder="Your question" type="text" name="" id="" style={{ width: '620px' }} />
                                                                <span><img className="flex-none mt-3" src={EditQuestoin} alt="edit" /></span>
                                                                <span><img className="flex-none mt-3" src={DeletQuestoin} alt="delete" onClick={() => handleDeleteMultiple(questionIndex)} /></span>
                                                            </div>
                                                        </AccordionSummary>
                                                    </div>

                                                    <AccordionDetails>
                                                        <Typography>
                                                            <div className="pt-1">
                                                                <div className="flex w-full gap-2 px-16">
                                                                    <p className="mt-3">Type answer</p>
                                                                    <input onChange={(e) => question.answer1 = e.target.value} defaultValue={question.answer1} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" style={{ height: '100px', }} />
                                                                    <div className="flex mt-3 gap-1">
                                                                        <span> <img src={PotoIcon} alt="" /></span>
                                                                        <span><img src={MathIcon} alt="" /></span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-center">
                                                                    <div className="QUESTIONS-COUNT flex-initial w-100 py-2 md:flex">
                                                                        <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChangeEssay('is exactly', question)}>
                                                                            <span>
                                                                                <img
                                                                                    src={selectedOptionEssay === 'is exactly' ? RadioOnIcon : RadioOffIcon}
                                                                                    alt="Task"
                                                                                    className="radio-image mt-1"
                                                                                />
                                                                            </span>
                                                                            <span className={`ps-2 ${selectedOptionEssay === 'is exactly' ? 'font-bold' : 'font-light'}`}>is exactly</span>
                                                                        </div>
                                                                        <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChangeEssay('contain', question)}>
                                                                            <span>
                                                                                <img
                                                                                    src={selectedOptionEssay === 'contain' ? RadioOnIcon : RadioOffIcon}
                                                                                    alt="Project"
                                                                                    className="radio-image mt-1"
                                                                                />
                                                                            </span>
                                                                            <span className={`ps-2 ${selectedOptionEssay === 'contain' ? 'font-bold' : 'font-light'}`}>contain</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <span><img onClick={() => copyQuestionMultiple(questionIndex)} className="flex-none mt-5 cursor-pointer" src={DuplicateQuestoin} alt="duplicate" /></span>
                                            </div>
                                        }
                                        {/* Reorder type question */}
                                        {question.questionType === 'reorder' &&
                                            <div key={question.id} className="ms-7 flex gap-3">
                                                <div className="flex">
                                                    <b className="mt-5">{questionIndex + 1}. </b>
                                                    <span><img className="mx-2 border p-1" style={{ borderRadius: '5px' }} src={RankOrProccess} alt="" /></span>
                                                </div>
                                                <Accordion className="grow">
                                                    <div style={{ backgroundColor: '#DFBFDD' }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2-content"
                                                            id="panel2-header"
                                                        >
                                                            <Typography>

                                                            </Typography>
                                                            <div className="QUESTION flex w-full gap-2 rounded p-2 mx-0">
                                                                <input onChange={(e) => question.question = e.target.value} defaultValue={question.question} className="flex-1 mx-5 ps-5" placeholder="Your question" type="text" name="" id="" style={{ width: '620px' }} />
                                                                <span><img className="flex-none mt-3" src={EditQuestoin} alt="edit" /></span>
                                                                <span><img className="flex-none mt-3" src={DeletQuestoin} alt="delete" onClick={() => handleDeleteMultiple(questionIndex)} /></span>
                                                            </div>
                                                        </AccordionSummary>
                                                    </div>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <div className="w-full">
                                                                <div className="flex w-full gap-2">
                                                                    <span className=" pt-1.5 px-3 bg-green-50 border my-1">1</span>
                                                                    <input onChange={(e) => question.answer1 = e.target.value} defaultValue={question.answer1} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                                                    <img className="m-auto" src={PotoIcon} alt="" />
                                                                    <img className="m-auto" src={MathIcon} alt="" />
                                                                </div>
                                                                <div className="flex w-full gap-2">
                                                                    <span className=" pt-1.5 px-3 bg-orange-50 border my-1">2</span>
                                                                    <input onChange={(e) => question.answer2 = e.target.value} defaultValue={question.answer2} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                                                    <img className="m-auto" src={PotoIcon} alt="" />
                                                                    <img className="m-auto" src={MathIcon} alt="" />
                                                                </div>
                                                                <div className="flex w-full gap-2">
                                                                    <span className=" pt-1.5 px-3 bg-orange-100 border my-1">3</span>
                                                                    <input onChange={(e) => question.answer3 = e.target.value} defaultValue={question.answer3} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                                                    <img className="m-auto" src={PotoIcon} alt="" />
                                                                    <img className="m-auto" src={MathIcon} alt="" />
                                                                </div>
                                                                <div className="flex w-full gap-2">
                                                                    <span className=" pt-1.5 px-3 bg-orange-200 border my-1">4</span>
                                                                    <input onChange={(e) => question.answer4 = e.target.value} defaultValue={question.answer4} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                                                    <img className="m-auto" src={PotoIcon} alt="" />
                                                                    <img className="m-auto" src={MathIcon} alt="" />
                                                                </div>
                                                            </div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <span><img onClick={() => copyQuestionMultiple(questionIndex)} className="flex-none mt-5 cursor-pointer" src={DuplicateQuestoin} alt="duplicate" /></span>
                                            </div>
                                        }
                                        {/* Image , video , audio and MCQs type question */}
                                        {(question.questionType === 'audio' || question.questionType === 'image' || question.questionType === 'video' || question.questionType === 'mcqs') &&
                                            <div key={question} className="ms-7 flex gap-3">
                                                <div className="flex">
                                                    <b className="mt-5">{questionIndex + 1}. </b>
                                                    {question.questionType === 'image' && <span><img className="mx-2 border p-1" style={{ borderRadius: '5px' }} src={Image} alt="" /></span>}
                                                    {question.questionType === 'video' && <span><img className="mx-2 border p-1" style={{ borderRadius: '5px' }} src={Video} alt="" /></span>}
                                                    {question.questionType === 'audio' && <span><img className="mx-2 border p-1" style={{ borderRadius: '5px' }} src={Audio} alt="" /></span>}
                                                    {question.questionType === 'mcqs' && <span className="mt-6"><span className="mx-2 border px-3 py-7" style={{ borderRadius: '5px' }}>MCQs</span></span>}

                                                </div>
                                                <Accordion className="grow">
                                                    <div style={{
                                                        backgroundColor:
                                                            question.questionType === 'image' ? '#ACCCE5' :
                                                                question.questionType === 'video' ? '#FFF3C6' :
                                                                    question.questionType === 'audio' ? '#DDE6C0' :
                                                                        question.questionType === 'mcqs' ? '#FEE7C3' :
                                                                            'initial'
                                                    }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel2-content"
                                                            id="panel2-header"
                                                        >
                                                            <Typography>

                                                            </Typography>
                                                            <div className="QUESTION flex w-full gap-2 rounded p-2 mx-0">
                                                                <input onChange={(e) => question.question = e.target.value} defaultValue={question.question} className="flex-1 mx-5 ps-5" placeholder="Your question" type="text" name="" id="" style={{ width: '620px' }} />
                                                                <span><img className="flex-none mt-3" src={EditQuestoin} alt="edit" /></span>
                                                                <span><img className="flex-none mt-3" src={DeletQuestoin} alt="delete" onClick={() => handleDeleteMultiple(questionIndex)} /></span>
                                                            </div>
                                                        </AccordionSummary>
                                                    </div>
                                                    <AccordionDetails>
                                                        <Typography>
                                                            <div className="pt-1">
                                                                <div className="flex w-full gap-2">
                                                                    <img className="m-auto" src={MCQsRight} alt="" />
                                                                    <input onChange={(e) => question.answer1 = e.target.value} defaultValue={question.answer1} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                                                    <img className="m-auto" src={PotoIcon} alt="" />
                                                                </div>
                                                                <div className="flex w-full gap-2">
                                                                    <input onChange={(e) => question.answer2 = e.target.value} defaultValue={question.answer2} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                                                    <img className="m-auto" src={PotoIcon} alt="" />
                                                                </div>
                                                                <div className="flex w-full gap-2">
                                                                    <input onChange={(e) => question.answer3 = e.target.value} defaultValue={question.answer3} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                                                    <img className="m-auto" src={PotoIcon} alt="" />
                                                                </div>
                                                                <div className="flex w-full gap-2">
                                                                    <input onChange={(e) => question.answer4 = e.target.value} defaultValue={question.answer4} className="w-full border my-1 p-1 ps-5" placeholder="Text" type="text" />
                                                                    <img className="m-auto" src={PotoIcon} alt="" />
                                                                </div>
                                                            </div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <span><img onClick={() => copyQuestionMultiple(questionIndex)} className="flex-none mt-5 cursor-pointer" src={DuplicateQuestoin} alt="duplicate" /></span>
                                            </div>
                                        }

                                    </div>
                                    ))}
                                </div>
                                {/* Multi Activity Type*/}
                                {SelecteQuestionType &&
                                    <div className="mt-5 justify-center flex gap-3">
                                        <img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { get_activity_type('image'); setIsquestionType('image') }} src={Image} alt="" />
                                        <img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { get_activity_type('video'); setIsquestionType('video') }} src={Video} alt="" />
                                        <img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { get_activity_type('audio'); setIsquestionType('audio') }} src={Audio} alt="" />
                                        <img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('easy'); addQuestionMultiple('easy') }} src={Text} alt="" />
                                        <img className="transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('reorder'); addQuestionMultiple('reorder') }} src={RankOrProccess} alt="" />
                                        <span className="mt-4 transition-transform transform hover:scale-110 cursor-pointer" onClick={() => { setIsquestionType('mcqs'); addQuestionMultiple('mcqs') }}>
                                            <span className="border p-3" style={{ borderRadius: '5px' }}>MCQs</span>
                                        </span>
                                    </div>}
                            </div>
                            <div className="mt-10">
                                <div onClick={() => { SetSelecteQuestionType(true) }} className="flex justify-center cursor-pointer"><img src={AddNewActivity} alt="" /></div>
                                <p className="flex justify-center mt-2">Add New Activity</p>
                            </div>
                            <div className="flex  justify-center gap-5 py-10">
                                {/* <Link to='/Dashboard/activityType'> */}
                                <button onClick={HanldeBackActivityType} className="border flex px-7 py-2 rounded BackBtn hover:bg-blue-200"><b>Back</b><img className="mt-1 ms-2" src={BackIcon} alt="back" /></button>
                                {/* </Link> */}
                                {/* <Link to='/Dashboard/ImportContent'> */}
                                <button type='submit' onClick={HandelSaveMultiActivity} className='BackBtn flex p-2 px-7 hover:bg-blue-200' ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>


    )
}

export default MultiActivy;