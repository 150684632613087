import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
// import { Dialog, DialogContent, Paper } from '@mui/material';
import ViewAttachedImage from "./attachedImage";

// import QuestiontypeBtnIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/question level bar.png'
import remembering from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 1st level 36-36.png'
import understanding from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 2nd level 36-36.png'
import applying from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 3rd level 36-36.png'
import analyzing from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 4th level 36-36.png'
import evaluating from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 5th level 36-36.png'
import creating from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bloom 6th level 36-36.png'
// import QuestionDifficultyLevelArrow from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard//question level bar indicator.png'
// import MathIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/math symbole icon 28-28.png'
import CloseIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/close icon fixed.png'
import PotoIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/photo icon 28-28.png'
import MCQsRight from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/MCQ right answer.png'
import MCQsWrong from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/MCQ wrong answer.png'
import CreateManualQuestionIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/Add New Manual Question icon.png'
import BackIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/back icon.png'
import NextIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/next icon.png'
// import RobotOpenEyeChat from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/QuestionListCard/bot with open eyes _ chat bubble.png'
// pop-up one
import QuestiontypeTriangL3lePic from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/page 18/bloom level 3rd level 140-148.png'
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from 'react-modal'
// model 2 bot 
import SubmiBtntIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/Submit icon.png';
import LoginHeading from "../../../../../LoginHeading/LoginHeading";


const Customizing = () => {

    const { questions: initialQuestions } = useLocation().state;
    const {course} = useLocation().state;
    const navigate = useNavigate();
    const fileInputRefs = {
        question: useRef(null),
        answer1: useRef(null),
        answer2: useRef(null),
        answer3: useRef(null),
        answer4: useRef(null)
    };
    
    // Define the questions state and setQuestions function, initialized with the obtained array
    const [questions, setQuestions] = useState(initialQuestions || []);

    const { filename } = useLocation().state;

    // modal one question card
    // const [modalIsOpen, setModalIsOpen] = useState(false);

    const openModal = () => {
        // setModalIsOpen(true);
    };

    const closeModal = () => {
        // setModalIsOpen(false);
    };
    // bot model 
    const [botmodalIsOpen, setBotmodalIsOpen] = useState(false);

    // const openBotModal = () => {
    //     setBotmodalIsOpen(true);
    // };

    const BotcloseModal = () => {
        setBotmodalIsOpen(false);
    };

    const removeQuestion = (index) => {
        // Create a new copy of the questions array
        const updatedQuestions = [...questions];
        // Remove the question at the specified index from the copied array
        updatedQuestions.splice(index, 1);
        // Update the state with the modified array
        setQuestions(updatedQuestions);
    };

    const getBloomLevel = (bloom_level) => {

        switch (bloom_level.toLowerCase()) {
            case 'remembering':
                return remembering;
            case 'understanding':
                return understanding;
            case 'applying':
                return applying;
            case 'analyzing':
                return analyzing;
            case 'evaluating':
                return evaluating;
            case 'creating':
                return creating;
            default:
                return remembering;
        }
    };
    const handleChange = (index, field, value) => {
        const updatedQuestions = [...questions]; // Create a copy of the questions array
        updatedQuestions[index][field] = value; // Update the field of the question at the specified index
        setQuestions(updatedQuestions); // Update state with the modified array
    };
    // const addNewQuestion = () => {
    //     const hasEmpty = questions.some(questionObj => hasEmptyParameters(questionObj));


    //     // Add new question only if no existing question has empty parameters
    //     if (!hasEmpty) {
    //         setQuestions(prevQuestions => [
    //             ...prevQuestions,
    //             { question: "", answer1: "", answer2: "", answer3: "", poll: false, slide_no:"manual", difficulty:"easy", bloom_level:"remembering"}
    //         ]);
    //     }   
    // };
    const addNewQuestion = () => {
        // Check if any question object in the questions array has empty question and answers
        const hasEmpty = questions.some(questionObj => {
            return !questionObj.question && (!questionObj.question || !questionObj.answer1 || !questionObj.answer2 || !questionObj.answer3 || !questionObj.answer4);
        });

        // Add new question only if no existing question has empty question and answers
        if (!hasEmpty) {
            setQuestions(prevQuestions => [
                ...prevQuestions,
                { question: "", answer1: "", answer2: "", answer3: "", answer4:"", poll: false, slide_no:"manual", difficulty:"easy", bloom_level:"remembering"}
            ]);
        }
    };
    
    const handleImageAttach = async (index, type, event) =>{
        
        const file = event.target.files[0];
        if (file) {
           
            // You can do further processing with the file here
            const formData = new FormData();
            formData.append("file", file);
            try{
                const uploadImage = await axios.post('https://gamizign.co:8000/attachment?type=question', formData,{});
                if (uploadImage.data) {
                    questions[index][type + 'Image'] = uploadImage.data.message;
                    } else {
                        console.log("An error occurred during uploading. Please try again.");
                }
            }
            catch (error) {
                console.log("An error occurred during uploading. Please try again.");
            }
        }
    };
    const [isHovering, setIsHovering] = useState(false);

 
    const handleMouseOver = () => {
        setIsHovering(true);
      };
    
      const handleMouseOut = () => {
        setIsHovering(false);
      };

    // var hasEmptyParameters = (obj) => {
    //     for (let key in obj) {
    //         if (obj.hasOwnProperty(key) && obj[key] === "") {
    //             return true; // Return true if any value is empty
    //         }
    //     }
    //     return false;
    // }
    const saveList = () => {
        navigate('/Dashboard/saveQuestions', { state: { course: course, questions: questions, filename: filename } });
    };
    useEffect(() => {
        // This effect will run every time 'questions' state changes
    }, [questions]); // Dependency array containing 'questions'


    // Function to handle the change event of the range input and update the difficulty level of the selected question
    const difficultyLevel = (e, index) => {
        const value = parseInt(e.target.value);
        let level;
        switch (value) {
            case 1:
                level = 'easy';
                break;
            case 2:
                level = 'medium';
                break;
            case 3:
                level = 'hard';
                break;
            default:
                level = 'easy';
        }
        // Update the difficulty level of the selected question in the questions array
        const updatedQuestions = [...questions];
        updatedQuestions[index].difficulty = level;
    };


    return (
        <div className='w-full'>
            <div className='flex relative TITLE px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Create New Activity</h3>
                <LoginHeading />
            </div>
            <div className="m-7 bg-white relative">
                {/* <img className="AI-BOT absolute bottom-0 right-1 cursor-pointer" onClick={openBotModal} src={RobotOpenEyeChat} alt="robot" /> */}
                {/* progress line */}
                <div>
                    <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className="flex w-full items-center text-green-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Adding</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-green-600 after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Creating</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-pink-300 after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <b>Customizing</b>
                            </span>
                        </li>
                        <li className="flex items-center">
                            <b>Saving</b>
                        </li>
                    </ol>
                </div>
                <div className="grid justify-items-center mt-10 mx-5">
                    <h1 className="text-3xl">Gamizign AI Generated</h1>
                    <div className="flex mt-5">
                        <button className="border bg-gray-100 p-1 px-2 rounded border-gray-400"><b>{questions.length}</b> Questions</button>
                        <p className="text-sky-600 p-1">{filename} </p>
                    </div>
                    {questions.map((question, index) => (
                        <div className="QUESTION1 w-full max-w-xl mt-5" key={Math.random()} id={Math.random()}>
                            <div className="flex gap-5 justify-end items-center">
                                <div className="columns-1">
                                    {/* <img className="mt-5" src={QuestiontypeBtnIcon} style={{ height: '20px' }} alt="" /> */}
                                    <div className="flex justify-end gap-2">
                                        <div className={`border bg-yellow-200 rounded px-6 `}><b>E</b></div>
                                        <div className={`border bg-green-200 rounded px-6 `}><b>M</b></div>
                                        <div className={`border bg-red-200 rounded px-6 `}><b>H</b></div>
                                    </div>
                                    {/* <img className="mt-5" src={QuestionDifficultyLevelArrow} style={{}} alt="Difficulty level" /> */}
                                    <input id="steps-range" type="range" min='1' max="3" defaultValue={question.difficulty} step="1"
                                        onChange={(e) => difficultyLevel(e, index)}
                                        className="w-full h-2 px-5 bg-gray-200 rounded-lg appearance-none cursor-pointer bg-transparent dark:bg-gray-700"
                                        style={{
                                            '--thumb-color': 'gray', // Change thumb color as desired
                                            '--thumb-width': '15px', // Change thumb width as desired
                                            '--thumb-height': '15px', // Change thumb height as desired
                                            '--thumb-radius': '0', // Set thumb radius to 0 to make it square
                                            'WebkitAppearance': 'none', // Remove default appearance
                                            'appearance': 'none', // Remove default appearance
                                            'cursor': 'pointer', // Add cursor pointer
                                            width: '200px',
                                        }}
                                    />

                                </div>
                                <img className="cursor-pointer" onClick={openModal} style={{ height: '100%' }} src={getBloomLevel(question.bloom_level)} alt="Bloom level" />

                            </div>

                                <div className="CARD border shadow-xl QlistCard mt-2">
                                    <div className="QUESTION flex gap-2 bg-green-50 rounded p-2">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={(event) => handleImageAttach(index, "question", event)}
                                            ref={(el) => (fileInputRefs['question'][index] = el)} 
                                            />
                                        <img
                                            className="flex-none m-auto hover:scale-125 cursor-pointer"
                                            src={PotoIcon}
                                            alt=""
                                            onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
                                            onClick={() => fileInputRefs['question'][index].click()} // Click the hidden file input when the image is clicked
                                        />
                                        { <ViewAttachedImage />}
                                        <p className="flex-none m-auto font-bold">{index +1} .MCQ question</p>
                                        <input
                                            className="flex-1 mx-5 py-2 sm:ps-5"
                                            defaultValue={question.question}
                                            placeholder="Your question"
                                            type="text"
                                            // onChange={handleInputChange}
                                            onChange={(e) => question.question = e.target.value}
                                        />                                        {/* <img className="flex-none m-auto hover:scale-125 cursor-pointer" src={MathIcon} alt="" /> */}
                                        <img className="flex-none m-auto hover:scale-125 cursor-pointer" style={{'width' : '30px'}} src={CloseIcon} alt="delete" onClick={() => removeQuestion(index)} />
                                    </div>
                                    <div className="ANSWER1 flex p-2 gap-2">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={(event) => handleImageAttach(index, "answer1", event)}
                                            ref={(el) => (fileInputRefs['answer1'][index] = el)}                                         />
                                        <img
                                            className="flex-none m-auto hover:scale-125 cursor-pointer"
                                            src={PotoIcon}
                                            alt=""
                                            onClick={() => fileInputRefs['answer1'][index].click()} 
                                        />                                        
                                        <img className="flex-none m-auto" src={MCQsRight} alt="" />
                                        <input
                                            className="w-full border my-1 p-1 ps-5"
                                            defaultValue={question.answer1}
                                            placeholder="Text"
                                            type="text"
                                            onChange={(e) => question.answer1 = e.target.value}
                                        />                                        {/* <img className="flex-none m-auto hover:scale-125 cursor-pointer" src={MathIcon} alt="" /> */}
                                    </div>
                                    <div className="ANSWER2 flex p-2 gap-2">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={(event) => handleImageAttach(index, "answer2", event)}
                                            ref={(el) => (fileInputRefs['answer2'][index] = el)}                                         />
                                        <img
                                            className="flex-none m-auto hover:scale-125 cursor-pointer"
                                            src={PotoIcon}
                                            alt=""
                                            onClick={() => fileInputRefs['answer2'][index].click()}
                                        />                                         
                                        <img className="flex-none m-auto" src={MCQsWrong} alt="" />
                                        <input
                                            className="w-full border my-1 p-1 ps-5"
                                            defaultValue={question.answer2}
                                            placeholder="Text"
                                            type="text"
                                            onChange={(e) => question.answer2 = e.target.value}
                                        />
                                        {/* <img className="flex-none m-auto hover:scale-125 cursor-pointer" src={MathIcon} alt="" /> */}
                                    </div>
                                    <div className="ANSWER3 flex p-2 gap-2">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={(event) => handleImageAttach(index, "answer3", event)}
                                            ref={(el) => (fileInputRefs['answer3'][index] = el)}                                         />
                                        <img
                                            className="flex-none m-auto hover:scale-125 cursor-pointer"
                                            src={PotoIcon}
                                            alt=""
                                            onClick={() => fileInputRefs['answer3'][index].click()}
                                        />                                         
                                        <img className="flex-none m-auto" src={MCQsWrong} alt="" />
                                        <input
                                            className="w-full border my-1 p-1 ps-5"
                                            defaultValue={question.answer3}
                                            placeholder="Text"
                                            type="text"
                                            onChange={(e) => question.answer3 = e.target.value}
                                        />                                        {/* <img className="flex-none m-auto hover:scale-125 cursor-pointer" src={MathIcon} alt="" /> */}
                                    </div>
                                    <div className="ANSWER4 flex p-2 gap-2">
                                        <input
                                            type="file"
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            onChange={(event) => handleImageAttach(index, "answer4", event)}
                                            ref={(el) => (fileInputRefs['answer4'][index] = el)}                                         />
                                        <img
                                            className="flex-none m-auto hover:scale-125 cursor-pointer"
                                            src={PotoIcon}
                                            alt=""
                                            onClick={() => fileInputRefs['answer4'][index].click()} 
                                        />                                         
                                        <img className="flex-none m-auto m-auto" src={MCQsWrong} alt="" />
                                        <input
                                            className="w-full border my-1 p-1 ps-5"
                                            defaultValue={question.answer4}
                                            placeholder="Text"
                                            type="text"
                                            onChange={(e) => question.answer4 = e.target.value}
                                        />                                        {/* <img className="flex-none m-auto hover:scale-125 cursor-pointer" src={MathIcon} alt="" /> */}
                                    </div>
                                </div>
                            </div>
                    ))}
                    <div className="flex justify-center">
                        <button className=" border py-2 px-6 my-10 font-bold flex BackBtn hover:bg-blue-200" onClick={addNewQuestion} style={{ borderRadius: '7px' }}>
                            <img src={CreateManualQuestionIcon} alt="AddNewQ border" style={{ width: '30px', paddingRight: '5px' }} />
                            Create</button>
                    </div>
                    <div className='MANUAL&SUBMIT flex text-center my-10 items-center justify-center gap-3 px-20'>
                        <Link to='/'><button className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button></Link>
                        <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={saveList} ><img className='mr-5 mt-1' src={NextIcon} alt="Next" /><b> Next</b></button>
                    </div>
                </div>
                <div>
                    <Modal
                        // isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        className="Modal"
                    // overlayClassName="Overlay"
                    >
                        {/* Modal Content */}
                        <div className="flex mt-20 justify-center ps-12">
                            <div className="flex mt-14  drop-shadow-xl bg-white" style={{ width: "600px", height: "500px", borderRadius: "20px 20px" }}>
                                <div className="border-end  justify-center bg-gray-300 px-5 pt-9" style={{ borderRadius: "20px 0px 0px 20px" }} >
                                    <img style={{ width: '150px', height: '100px', }} src={QuestiontypeTriangL3lePic} alt="" />
                                </div>
                                <div className="p-5 pt-7">
                                    <div>
                                        <h1 className="text-red-200 font-bold"> Applying-Application Level</h1>
                                        <p className="text-blue-900 mt-2">Abstract idea concrete situation to solve problem</p>
                                    </div>
                                    <div className="mt-5">
                                        <h1 className="text-red-200 font-bold">Keywords</h1>
                                        <li className="text-blue-900">select,solve,utilize,model,identify</li>
                                    </div>
                                    <div className="mt-5">
                                        <h1 className="text-red-200 font-bold">New activity suggestion</h1>
                                        <li className="text-blue-900 mt-2">
                                            Identify general characteristics (started and/or implied) of the main characters.
                                        </li>
                                        <li className="text-blue-900 mt-2">
                                            Distinguish what could happen from what couldn't happen in the story in rael life.
                                        </li>
                                        <li className="text-blue-900 mt-2">
                                            Select parts of the story that were the funniest,saddest,happiest, and most unbelievable.
                                        </li>
                                        <li className="text-blue-900 mt-2">
                                            Differentiate fact from opinion.
                                        </li>
                                        <li className="text-blue-900 mt-2">
                                            Compare and/or contract two of the main characters.
                                        </li>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </Modal>

                    {/*bot modal  */}


                    <Modal
                        isOpen={botmodalIsOpen}
                        onRequestClose={BotcloseModal}
                        className="Modal"
                    // overlayClassName="Overlay"
                    >
                        {/* Modal Content */}
                        <div className="flex mt-20 justify-end pe-40">
                            <div className="flex mt-14  drop-shadow-xl bg-white" style={{ width: "600px", height: "250px", borderRadius: "20px 20px" }}>
                                <div className="border-end  justify-center bg-gray-300 px-5 pt-14" style={{ borderRadius: "20px 0px 0px 20px" }} >
                                    <img style={{ width: '100px', height: '100px', }} src={QuestiontypeTriangL3lePic} alt="" />
                                </div>
                                <div className="p-5 pt-7">
                                    <div>
                                        <h1 className="text-red-200 font-bold">Pick Level</h1>
                                        <p className="text-blue-900 mt-2"><li>Applying-Application Level</li></p>
                                    </div>
                                    <div className="mt-5">
                                        <h1 className="text-red-200 font-bold">Choose Keywords</h1>
                                        <li className="text-blue-900">apply. <b>build,</b>choose, consrtuct, develop, interview, <br />
                                            make usee of, orgnize, experiment with, plan,<br />
                                            <b>select,solve,</b> utilize,model, <b>identify</b>
                                        </li>
                                    </div>
                                    <div className="flex justify-end">
                                        <button className="flex border p-2 px-3 bg-gray-100 rounded" onClick={BotcloseModal}><img className="pe-4 mt-1" src={SubmiBtntIcon} alt="post" /><b>Submit</b></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
            <style >{`
        #steps-range::-webkit-slider-thumb {
          WebkitAppearance: none;
          appearance: none;
          width: var(--thumb-width);
          height: var(--thumb-height);
          border: none;
          background-color: var(--thumb-color);
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
          border-radius: var(--thumb-radius);
          cursor: pointer;
        }

        #steps-range::-moz-range-thumb {
          width: var(--thumb-width);
          height: var(--thumb-height);
          border: none;
          background-color: var(#b6dff7);
          clip-path: polygon(#DBE6F5;
          border-radius: var(--thumb-radius);
          cursor: pointer;
        }
      `}</style>
        </div>
    )
};

export default Customizing;