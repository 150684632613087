import React, { useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';

const ViewAttachedImage = ({ index, type, questions }) => {
  const [open, setOpen] = useState(false);


  // const handleMouseEnter = () => {
  //   setOpen(true);
  // };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const imageUrl = `https://gamizign.co:8000/uploads/questions/2024-04-16-21-53-42-072383.png}`;
  return (
      <Dialog open={open} onClose={handleMouseLeave}>
        <DialogContent>
          <img src={imageUrl} alt="Attached Image" />
        </DialogContent>
      </Dialog>
  );
};

export default ViewAttachedImage;
