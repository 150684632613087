import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ViewHIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/view course head icon.png'
import EditIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/edit icon.png'
import DeleteIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/delete icon.png'
import TestCourse from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/Test the Course icon.png'
// Activity type
import PdfImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/PDF icon hover off color.png'
import GameImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/game icon hover off color.png'
import VideoImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/video icon hover off color.png'
import ImageImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/image icon hover off color.png'
import AudioImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/Audio icon hover off color.png'
import urlImage from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/URL icon hover off color.png'
import SaveIcon from '../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/ViewCourse/save icon.png'
import AlertIcon from '../../../../../../assets/icons/Alert-icon.png'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Button } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoginHeading from "../../../../../LoginHeading/LoginHeading";
import Modal from 'react-modal'


const ViewCourse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const course_id = queryParams.get('course_id');


  const [SelectedCourse, setSelectedCourse] = useState(null)
  const [questionsLists, setQuestionsLists] = useState(null)
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getCourse = async () => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };

      try {
        setLoading(true);

        const response = await fetch(`https://gamizign.co:8000/courses/${course_id}/`, requestOptions);
        const data = await response.json();
        var instructor_id = parseInt(localStorage.getItem("instructor_id"));
        const responseQlist = await fetch(`https://gamizign.co:8000/instructors/${instructor_id}/question-lists`, requestOptions);
        const dataQlist = await responseQlist.json();

        setLoading(false);
        // setCourses(data);
        setSelectedCourse(data);
        setQuestionsLists(dataQlist);
      } catch (error) {
        console.error('Error fetching courses:', error);
        // Handle the error if needed
      } finally {
        setLoading(false);
      }
    };
    getCourse();
  }, []);
  // Delete satge from course and enpoint
  const [courseName, setCourseName] = useState()
  const [stageId, setStageId] = useState()
  const [stageName, setStageName] = useState()
  const [stageIndex, setStageIndex] = useState()
  const [activtyId, setActivtyId] = useState()
  const [activityName, setActivityName] = useState()
  const [stageDelete, setStageDelete] = useState(false)
  const [activityDelete, setActivityDelete] = useState(false)
  const DeleteStage = () => {
    const selectedCourseCopy = { ...SelectedCourse };
    selectedCourseCopy.stages = selectedCourseCopy.stages.filter(stage => stage.stage_id !== stageId);
    setSelectedCourse(selectedCourseCopy);
    fetch(`https://gamizign.co:8000/stages/${stageId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
        }
        else {
          throw new Error('Failed to delete course $questionslist_id');
        }
      })
      .catch(error => {
        console.error('Error deleting course:', error);
      });

  };
  // Delete Activity from satge/course and enpoint
  const DeleteActivity = () => {
    const selectedCourseCopy = { ...SelectedCourse };
    selectedCourseCopy.stages[stageIndex].activities = selectedCourseCopy.stages[stageIndex].activities.filter(activity => activity.activity_id !== activtyId);
    setSelectedCourse(selectedCourseCopy);
    fetch(`https://gamizign.co:8000/activities/${activtyId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.ok) {
        }
        else {
          throw new Error('Failed to delete course $questionslist_id');
        }
      })
      .catch(error => {
        console.error('Error deleting course:', error);
      });
  };

  // Stages and Activities Header Color
  const StageColors = ['#ACCCE5', '#BCE2BB', '#DDE6C0', '#FFF3C6', '#FEE7C3', '#FCDCBD', '#F6C1C1', '#DFBFDD'];
  const ActivityColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];

  // Culculation of stages duation 
  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/");
    // JavaScript Date object uses months starting from 0, so subtract 1 from the month
    return new Date(year, month - 1, day);
  };
  const calculateRemainingDays = (startingDate, finishingDate) => {
    const startDate = parseDate(startingDate);
    const endDate = parseDate(finishingDate);
    const duration = Math.abs(endDate - startDate);
    const daysRemaining = Math.ceil(duration / (1000 * 60 * 60 * 24));

    return (daysRemaining / 7).toFixed(0);
  };

  // Formation of date
  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  let hh = today.getHours();
  let min = today.getMinutes();
  let ss = today.getSeconds();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  const formattedToday = dd + '/' + mm + '/' + yyyy +'  ' + hh + ':' + min + ':' + ss  
  // Change Activit Type 

  const ChangActivityType = (stageId, activityId, newType , activity) => {
    setSelectedCourse((prevCourse) => {
      const updatedStages = prevCourse.stages.map((stage) => {
        if (stage.stage_id === stageId) {
          return {
            ...stage,
            activities: stage.activities.map((activity) =>
              activity.activity_id === activityId
                ? { ...activity, activityType: newType }
                : activity
            ),
          };
        }
        return stage;
      });
      return { ...prevCourse, stages: updatedStages };
    });
    
  };
  // Change game type array 
  const ChangeGame = {
    0: 'Squirrlo', 1: 'Jupitario', 2: 'Deepmarine', 3: 'SpaceX', 4: 'Freesign',
    5: 'KanwaKango', 6: 'Lexileague', 7: 'Trainaid', 8: 'Loopylizer', 9: 'DinerDish',
  }
  const changeGameType = (stageId, activityId, newGameType) => {
    setSelectedCourse((prevCourse) => {
      const updatedStages = prevCourse.stages.map((stage) => {
        if (stage.stage_id === stageId) {
          return {
            ...stage,
            activities: stage.activities.map((activity) =>
              activity.activity_id === activityId
                ? { ...activity, gameType: Number(newGameType) }
                : activity
            ),
          };
        }
        return stage;
      });
      return { ...prevCourse, stages: updatedStages };
    });
  };
  // Change Question list in activity
  const changeQuestionList = (stageId, activityId, newGameType) => {
    setSelectedCourse((prevCourse) => {
      const updatedStages = prevCourse.stages.map((stage) => {
        if (stage.stage_id === stageId) {
          return {
            ...stage,
            activities: stage.activities.map((activity) =>
              activity.activity_id === activityId
                ? { ...activity, questionList_id: Number(newGameType) }
                : activity
            ),
          };
        }
        return stage;
      });
      return { ...prevCourse, stages: updatedStages };
    });
  };
  // upload file for activity
  const [selectedFile , setSelectedFile] = useState (null)
  const handleFileChange = (files , activity) => {
    const file = files;
    setSelectedFile(file);
    activity.activity_file_name  = file.name
    activity.lastModified  = formattedToday
};

  const UpdateActivityhandle = async (activity) => {
    // e.preventDefault();

    try {
      const response = await fetch(`https://gamizign.co:8000/activity/${activity.activity_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Include other headers if necessary
        },
        body: JSON.stringify(activity),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      window.location.reload();
      // Handle the response data as needed
    } catch (error) {
      console.error('Error:', error);
      // Handle the error as needed
    }
  };

  return (
    <div className='w-full'>
      <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
        <h3 className="text-xl">Course Structure</h3>
        <LoginHeading />
      </div>
      <div className="m-7 bg-white border border-2">
        <div className='flex justify-center static'><img className='absolute top-6' src={ViewHIcon} alt="" /></div>
        {/* loading */}
        {loading ? (
          <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
            <svg
              className="text-gray-300 animate-spin"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100">
              <path
                d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
              </path>
              <path
                d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
              </path>
            </svg>
          </div>
        ) : (
          <div>
            <div className="bg-gray-100 grid grid-cols-3 px-5 py-2">
              <div className="bg-white  px-5 py-2 border border-2">{SelectedCourse.name}</div>
              <div></div>
              <div className="flex">
                <p className="bg-white  px-5 py-2 border border-2">{`Date Created ${SelectedCourse.dateCreated}`}</p>
                <div className="flex gap-2 mt-2.5 ms-5">
                  <span><img src={EditIcon} alt="" /></span>
                  <span><img src={DeleteIcon} alt="" /></span>
                </div>
              </div>
            </div>
            <div>
              {/* Stages of Course */}
              <p className={`${SelectedCourse.stages.length === 0 ? "text-xl flex justify-center text-red-500 font-bold mt-5" : "hidden"}`}>No any Stage in this Course</p>
              {SelectedCourse.stages.map((stage, StageIndex) => (
                <div key={StageIndex}>
                  <div className="sm:mx-20 mx-5 my-5">
                    <Accordion defaultExpanded>
                      <div style={{ backgroundColor: StageColors[StageIndex % StageColors.length] }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3-content"
                          id="panel3-header"
                        >
                          <div className={` w-full flex px-10 relative `}>
                            <p className="">{stage.name}</p>
                            <p className="bg-white py-2 px-5 absolute  left-80 ">
                              {`Date Created ${stage.startingDate}`}
                              <span className="ms-5">{`Duration ${calculateRemainingDays(stage.startingDate, stage.finishingDate)} ${calculateRemainingDays < 2 ? "week" : "weeks"}`}</span>
                            </p>
                            <div className="flex absolute  right-3 gap-2">
                              <span><img src={EditIcon} alt="" /></span>
                              <span><img src={DeleteIcon} alt="DeleteStage" onClick={() => { setStageId(stage.stage_id); setStageName(stage.name); setCourseName(SelectedCourse.name); setStageDelete(true) }} /></span>
                            </div>
                          </div>
                        </AccordionSummary>
                      </div>
                      {/* Activities Of stage */}
                      <div>
                        <AccordionDetails>
                          <p className={`${stage.activities.length === 0 ? "text-xl flex justify-center text-red-500 font-bold" : "hidden"}`}>No any activity in this Stage</p>
                          {stage.activities && Array.isArray(stage.activities) ? (<div>
                            {stage.activities.map((activity, ActivitiesIndex) => (
                              <div className=" -mt-4 py-2 px-5 w-full ms-9" key={ActivitiesIndex}>
                                <Accordion>
                                  <div className="mb-0.5" style={{ backgroundColor: ActivityColors[StageIndex % ActivityColors.length] }}>

                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                    >
                                      <div className="flex relative w-full gap-5">
                                        <p>{activity.name}</p>
                                        {/* <span className=""><img src={EditIcon} alt="" /></span> */}
                                        <span className={` ${activity.activityType === "pdf" ? "" : "hidden"}`}><img width={30} src={PdfImage} alt="" /></span>
                                        <span className={` ${activity.activityType === "game" ? "" : "hidden"}`}><img width={30} src={GameImage} alt="" /></span>
                                        <span className={` ${activity.activityType === "video" ? "" : "hidden"}`}><img width={30} src={VideoImage} alt="" /></span>
                                        <span className={` ${activity.activityType === "image" ? "" : "hidden"}`}><img width={30} src={ImageImage} alt="" /></span>
                                        <span className={` ${activity.activityType === "audio" ? "" : "hidden"}`}><img width={30} src={AudioImage} alt="" /></span>
                                        <span className={` ${activity.activityType === "url" ? "" : "hidden"}`}><img width={30} src={urlImage} alt="" /></span>

                                        <div className="flex absolute right-3 gap-2">
                                          <span><img src={EditIcon} alt="" /></span>
                                          <span><img src={DeleteIcon} alt="DeleteActivity" onClick={() => { setActivtyId(activity.activity_id); setActivityName(activity.name); setStageIndex(StageIndex); setCourseName(SelectedCourse.name); setActivityDelete(true) }} /></span>
                                        </div>
                                      </div>
                                    </AccordionSummary>
                                  </div>
                                  <AccordionDetails>
                                    <div>
                                      <div>
                                        <label htmlFor="Activity Type">Activity Type:
                                          <select className="border outline-none" onChange={(e) => ChangActivityType(stage.stage_id, activity.activity_id, e.target.value , activity)} value={activity.activityType} name="" id="">
                                            <option value={activity.activityType} selected>{activity.activityType}</option>
                                            {activity.activityType !== 'game' && (<option value="game">Game</option>)}
                                            {activity.activityType !== 'url' && (<option value="url">Url</option>)}
                                            {activity.activityType !== 'image' && (<option value="image">Image</option>)}
                                            {activity.activityType !== 'pdf' && (<option value="pdf">Pdf</option>)}
                                            {activity.activityType !== 'video' && (<option value="video">Video</option>)}
                                            {activity.activityType !== 'audio' && (<option value="audio">Audio</option>)}
                                          </select>
                                        </label>
                                      </div>
                                      {activity.activityType != 'game' && <div className="flex">
                                        <p>Activity File name: <b>{activity.activity_file_name}</b></p>
                                        <p><input onChange={(e) => handleFileChange(e.target.files[0], activity)} type="file" name="" id="" /></p>
                                      </div>}
                                      {activity.activityType === "game" && (
                                        <div>
                                          <label htmlFor="Change game">Game Type:
                                            <select
                                              className="border outline-none"
                                              onChange={(e) =>
                                                changeGameType(stage.stage_id, activity.activity_id, e.target.value)
                                              }
                                              value={activity.gameType}
                                            >
                                              {Object.entries(ChangeGame).map(([key, value]) => (
                                                <option key={key} value={key}>
                                                  {value}
                                                </option>
                                              ))}
                                            </select>
                                          </label>
                                        </div>
                                      )}
                                      {activity.activityType === "game" &&
                                        <div>
                                          <label htmlFor="Question list">Questions list:
                                            <select className="border outline-none" onChange={(e) => changeQuestionList(stage.stage_id, activity.activity_id, e.target.value)} value={activity.questionList_id} name="" id="">
                                              {questionsLists.map((questionsList, questionsListsIndex) => (
                                                <option key={questionsList.questionList_id} value={questionsList.questionList_id}>{questionsList.name}</option>
                                              ))}
                                            </select>
                                          </label>
                                        </div>
                                      }
                                      <div className="flex justify-center mt-10">
                                      <button onClick={()=>UpdateActivityhandle(activity)} className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Save Activity </b><img className='ml-5 mt-1' src={SaveIcon} alt="Back" /></button>

                                      </div>


                                    </div>
                                  </AccordionDetails>
                                </Accordion>

                              </div>
                            ))}
                          </div>) : (<div>NO any Activity in This Stage  </div>)}


                        </AccordionDetails>
                      </div>
                      <AccordionActions>

                      </AccordionActions>
                    </Accordion>
                  </div>

                </div>
              ))}

              {/* <div className="flex justify-center my-10 gap-5">
                <img src={TestCourse} alt="" />
                <p >Test the Course</p>
              </div> */}
            </div>
          </div>
        )}
        {/* Delete Stage pop up */}
        <Modal
          isOpen={stageDelete}
          onRequestClose={() => setStageDelete(false)}
          className="Modal flex justify-center mt-40"
        // overlayClassName="Overlay"
        >
          {/* Modal Content */}
          <div className="flex sm:ms-20" >
            <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
              <div className="mt-5">
                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                <h1 className={`sm:text-2xl `}>Are you sure you want to <b> Delete </b> the <b>{stageName}</b> from <b>{courseName}</b>?</h1>
                <div className="flex justify-center gap-8 my-4">
                  <button className="hover:bg-red-200 px-2" onClick={() => { setStageDelete(false); DeleteStage() }}>Yes</button>
                  <button onClick={() => setStageDelete(false)}>No</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Delete Activity pop up */}
        <Modal
          isOpen={activityDelete}
          onRequestClose={() => setActivityDelete(false)}
          className="Modal flex justify-center mt-40"
        // overlayClassName="Overlay"
        >
          {/* Modal Content */}
          <div className="flex sm:ms-20" >
            <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
              <div className="mt-5">
                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                <h1 className={`sm:text-2xl `}>Are you sure you want to <b> Delete </b> the <b>{activityName}</b> from <b>{stageName} of {courseName}</b>?</h1>
                <div className="flex justify-center gap-8 my-4">
                  <button className="hover:bg-red-200 px-2" onClick={() => { DeleteActivity(); setActivityDelete(false) }}>Yes</button>
                  <button onClick={() => setActivityDelete(false)}>No</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

      </div>
    </div>
  )
};

export default ViewCourse;
