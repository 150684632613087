import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TreeView from "../Newcourse/treeView";
import FileNametIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/File icon.png';
import SubmiBtntIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/Submit icon.png';
import RadioOnIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/radio button on.png';
import RadioOffIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/radio button off.png';
import '../style.css'
import axios from "axios";
import LoginHeading from "../../../../../LoginHeading/LoginHeading";

const CreatingPdfJourney = ({ onSelectStart, onSelectEnd }) => {
  const [selectedButton, setSelectedButton] = useState('Easy Questions');
  const [selectedOption, setSelectedOption] = useState('5 to 10');
  var [outline_data, setOutline] = useState('DFG');
  const [isConverting, setIsConverting] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [totalPages, settotalPages] = useState(1);
  const { course, stageIndex, courseFormatDate } = useLocation().state;

  const navigate = useNavigate();


  var selectedDifficulty = "Medium & Hard";

  const handleButtonClick = (value) => {
    if (value === "Easy")
      selectedDifficulty = "Medium & Hard";
    if (value === "Medium")
      selectedDifficulty = "Easy & Hard";
    if (value === "Hard")
      selectedDifficulty = "Easy & Medium";
    setSelectedButton(value);
  };
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };
  const location = useLocation();
  const [file, setFile] = useState('');
  const [slides, setSlides] = useState([]);
  const [start_page, setStartPage] = useState('');
  const [end_page, setEndPage] = useState('');



  const handleGetOutline = async () => {
    const formData = new FormData();
    formData.append("file", file);
    setIsConverting(true);
    const req = await axios.post('https://gamizign.co:8000/get_outline', formData);
    if (req.data) {
      setIsConverting(false);
      settotalPages(req.data.total_pages);
      setOutline(req.data.outline);
      setStartPage(1);
    }
    else {
      console.log("An error had occured while getting outline");
    }
  }

  const PageSelector = ({ total_pages, onSelectStart, onSelectEnd }) => {
  
    const handleStartChange = (e) => {
      const selectedStart = parseInt(e.target.value, 10);
      setStartPage(selectedStart);
      setEndPage(''); // Reset end_page when start_page changes
      if (typeof onSelectStart === 'function') {
        onSelectStart(selectedStart);
      }
    };
  
    const handleEndChange = (e) => {
      const selectedEnd = parseInt(e.target.value, 10);
      setEndPage(selectedEnd);
      if (typeof onSelectEnd === 'function') {
        onSelectEnd(selectedEnd);
      }
    };
  
    return (
      !isConverting ? (
        <div className="flex flex-col space-y-4">
          {start_page && (
            <>
              <label htmlFor="start_page" className="text-lg font-semibold">
                Starting page:
              </label>
              <select
                id="start_page"
                disabled={isDisabled}
                value={start_page}
                onChange={handleStartChange}
                className="p-2 rounded border border-gray-300 focus:outline-none focus:border-blue-500"
              >
                <option value="" disabled>Select start page</option>
                {[...Array(total_pages)].map((_, index) => (
                  <option key={index + 1} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </select>
    
              <label htmlFor="end_page" className="text-lg font-semibold">
                Ending page:
              </label>
              <select
                id="end_page"
                disabled={isDisabled}
                value={end_page}
                onChange={handleEndChange}
                className="p-2 rounded border border-gray-300 focus:outline-none focus:border-blue-500"
              >
                <option value="" disabled>Select end page</option>
                {Array.from({ length: 5 }, (_, index) => start_page + index).map((page) => (
                  <option key={page} value={page}>
                    {page}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      ) : (
        <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
          <svg
            className="text-gray-300 animate-spin"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
          >
            <path
              d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
              stroke="#b6dff7"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
              stroke="#FFFFFF"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-gray-900"
            />
          </svg>
        </div>
      )
    );
    
  };



const submitPages = async()=>{
    setIsGenerating(true);
    setIsDisabled(true);
    if (slides != null && file != null) {
        try {

          const formData = new FormData();
          formData.append("file", file);
          formData.append("start_page", JSON.stringify(start_page));
          formData.append("end_page", JSON.stringify(end_page));
          formData.append("difficulty_level", JSON.stringify(selectedDifficulty));
          formData.append("range", JSON.stringify(selectedOption));
          const response = await axios.post("https://gamizign.co:8000/question_pdf_chat_gpt/", formData, {
            
          });
          if (response.data) {
            setIsGenerating(false);
            var questions = response.data;
            navigate('/Dashboard/customizingJourney', { state: { course: course, questions: questions, filename: file.name, courseFormatDate: courseFormatDate, stageIndex:stageIndex } });
          }
  
          else {
            setIsGenerating(false);
            console.log('Error in fetching questions');
          }
        }
        catch (error) {
          setIsGenerating(false);
          console.error('Fetch error:', error);
        }
      }
}


  useEffect(() => {
    // Access the state from the previous page
    const { selectedFile } = location.state || {};
    if (selectedFile) {
      setFile(selectedFile);
    }
  }, [location.state]);

  // useEffect to log the value of file whenever it changes
  useEffect(() => {
    if (file) {
      handleGetOutline();
    }
  }, [file]);

  return (
    <div className='w-full'>
      <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
        <h3 className="text-xl">Create New Activity</h3>
        <LoginHeading />
      </div>
      <div className="m-7 bg-white contentSideCreating">
        {/* progress line */}
        <div>
          <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
            <li className="flex w-full items-center text-green-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
              <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <b>Adding</b>
              </span>
            </li>
            <li className="flex w-full items-center text-pink-300 after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
              <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                <b>Creating</b>
              </span>
            </li>
            <li className="flex w-full items-center after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
              <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                <b>Customizing</b>
              </span>
            </li>
            <li className="flex items-center">
              <b>Saving</b>
            </li>
          </ol>
        </div>
        <div className="App px-20">
          <div className="flex mt-5">
            <button className="border FixedWidth170 flex mx-2 px-3 py-2 rounded filName flex-none"><img className="mr-3 mt-1" src={FileNametIcon} alt="pic" />File Name</button>
            <p className="mt-2 ms-3 flex-1"><span className="text-sky-600">{file.name}</span></p>
          </div>
          <div className="flex space-x-4 mt-5">
            <div className="DIFFICULTIES flex-1">
              <button
                className={`border FixedWidth170 m-2 py-2 px-5 ${selectedButton === 'Hard Questions' ? 'white' : 'bg-green-50'} text-black rounded`}
                onClick={() => handleButtonClick('Hard Questions')}
              >
                <b>Hard </b>Questions
              </button>
              <button
                className={`border FixedWidth170 m-2  py-2 px-5 ${selectedButton === 'Normal Questions' ? 'white' : 'bg-green-50'} text-black rounded`}
                onClick={() => handleButtonClick('Normal Questions')}
              >
                <b>Normal </b>Questions
              </button>
              <button
                className={`border FixedWidth170 m-2  py-2 px-5 ${selectedButton === 'Easy Questions' ? 'white' : 'bg-green-50'} rounded`}
                onClick={() => handleButtonClick('Easy Questions')}
              >
                <b>Easy </b>Questions
              </button>
            </div>
            <div className="QUESTIONS-COUNT flex-initial w-100 py-2 md:flex">
              <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChange('5 to 10')}>
                <span>
                  <img
                    src={selectedOption === '5 to 10' ? RadioOnIcon : RadioOffIcon}
                    alt="5 to 10"
                    className="radio-image mt-1"
                  />
                </span>
                <span className={`ps-2 ${selectedOption === '5 to 10' ? 'font-bold' : 'font-light'}`}>5 to 10</span>
              </div>
              <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChange('10 to 20')}>
                <span>
                  <img
                    src={selectedOption === '10 to 20' ? RadioOnIcon : RadioOffIcon}
                    alt="10 to 20"
                    className="radio-image mt-1"
                  />
                </span>
                <span className={`ps-2 ${selectedOption === '10 to 20' ? 'font-bold' : 'font-light'}`}>10 to 20</span>
              </div>
              <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChange('20 to 30')}>
                <span>
                  <img
                    src={selectedOption === '20 to 30' ? RadioOnIcon : RadioOffIcon}
                    alt="20 to 30"
                    className="radio-image mt-1"
                  />
                </span>
                <span className={`ps-2 ${selectedOption === '20 to 30' ? 'font-bold' : 'font-light'}`}>20 to 30</span>
              </div>
            </div>
          </div>
          {/* {isConverting ? (
            <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
            <svg
              className="text-gray-300 animate-spin"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100">
              <path
                d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
              </path>
              <path
                d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
              </path>
            </svg>
            </div>
            ) : 
            (
            outline_data.sub_items?.length > 0 ? (
                <TreeView outline={outline_data} start_page={setStartPage} />
            ) :
             ( */}
                <div className="flex flex-col space-y-4 content-center">
                <PageSelector
                    total_pages={totalPages}
                />
                </div>
            {/* )
            )} */}


        {isGenerating ?
          (<div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
            <svg
              className="text-gray-300 animate-spin"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100">
              <path
                d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
              </path>
              <path
                d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
              </path>
            </svg>
          </div>) :
          !isConverting ?
            (<div className="flex justify-center mt-10">
              <button className="border flex px-3 py-2 mb-5 rounded hover:bg-blue-200 filSubmit" onClick={submitPages}>
                <img className="mr-3 mt-1" src={SubmiBtntIcon} alt="pic" />
                <b>Submit</b>
              </button>
            </div>) : (<></>)}
        </div>
      </div>
    </div>
  )
};

export default CreatingPdfJourney;