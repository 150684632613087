import React, { useState } from "react";
import userIcon from '../../assets/icons/userIcon.png'


const LoginHeading = () => {
    var inst_name = localStorage.getItem("name");
    var inst_Email = localStorage.getItem("email");

    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <div>
            <div className="text-xl absolute  right-12 lg:right-5 flex gap-3">
               <div className="hidden sm:block "> {inst_Email}
                ({inst_name})</div>
                <div className="relative">
                    {/* Button with dropdown toggle */}
                    <button
                        className="flex items-center justify-center w-10 h-10 -mt-1 hover:bg-gray-300 rounded-full focus:outline-none"
                        onMouseEnter={() => setShowDropdown(true)}
                        onMouseLeave={() => setShowDropdown(false)}
                    >
                        <img width={30} src={userIcon} alt="" />
                    </button>

                    {/* Dropdown menu */}
                    {showDropdown && (
                        <div className="absolute right-0 z-10 w-40 py-2 bg-white border border-gray-200 rounded shadow-lg top-full" onMouseEnter={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                            {/* Option 1 */}
                            <button className="flex items-center w-full px-4 py-2 hover:bg-gray-100 focus:outline-none">
                                Profile
                            </button>

                            {/* Option 2 */}
                            <button className="flex items-center w-full px-4 py-2 hover:bg-gray-100 focus:outline-none">

                                Settings
                            </button>

                            {/* Option 3 */}
                            <button className="flex items-center w-full px-4 py-2 hover:bg-gray-100 focus:outline-none"
                                onClick={() => { localStorage.clear(); window.location.href = "/"; }}>
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
};
export default LoginHeading;
