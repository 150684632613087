import React, { useEffect, useState } from "react";

import QListDashboardIcon from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Question Lists dashboard icon.png'
import Addfolder from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/create group icon.png'
import GridIconOn from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/grid icon on.png'
import GridIconOff from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/grid icon off.png'
import ListIconOn from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/list icon on.png'
import ListIconOff from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/list icon off.png'
import QuestionVolume from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/questions volume icon.png'
import GradVolume from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/grade volume icon.png'
import PdfICon from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/pdf icon.png'
import EditQuestoin from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/edit icon.png'
import DeletQuestoin from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/delete icon.png'
import NextIcon from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Next icon.png'
import BackIcon from '../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/back iconB.png'
import CreateNewQuestion from '../../../../../assets/icons/ActivityContentIcons/New Course icon.png'
import AlertIcon from '../../../../../assets/icons/Alert-icon.png'
import { Link, Navigate, unstable_HistoryRouter } from "react-router-dom";
import Modal from 'react-modal'
import LoginHeading from "../../../../LoginHeading/LoginHeading";



const QuestionList = () => {
    const [currentImageGrid, setCurrentImageGrid] = useState(GridIconOff);
    const toggleImage = () => {
        setCurrentImageGrid((prevImage) => (prevImage === GridIconOff ? GridIconOn : GridIconOff));
    };
    const [questionsLists, setQuestionsLists] = useState([]);
    const [loading, setLoading] = useState(true);
    var instructor_id = parseInt(localStorage.getItem("instructor_id"));

    useEffect(() => {
        const getQuestionsLists = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            try {
                setLoading(true);
                await fetch(`https://gamizign.co:8000/instructors/${instructor_id}/question-lists`, requestOptions).then(response => response.json()).then(data => {
                    setLoading(false);
                    setQuestionsLists(data);
                    setFilteredQuestionLists(data);
                });
            } catch (error) {
                console.error('Error fetching courses:', error);
                // Handle the error if needed
            } finally {
                setLoading(false);
            }

        }
        getQuestionsLists();

    }, []);

    const [filteredQuestionLists, setFilteredQuestionLists] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);

        const lowercasedSearchTerm = e.target.value.toLowerCase();

        const filteredLists = questionsLists.filter((questionsList) => {
            return questionsList.name.toLowerCase().includes(lowercasedSearchTerm);
        });

        setFilteredQuestionLists(filteredLists);
    };
    // color Palette
    // const QuestionsListsColors = ['#ACCCE5', '#BCE2BB', '#DDE6C0', '#FFF3C6', '#FEE7C3', '#FCDCBD', '#F6C1C1', '#DFBFDD'];
    const QuestionsListsColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];

    // Delete Question list
    const [QuestionListId, setQuestionListId] = useState();
    const handleDeleteQuestionList = () => {
        const updatedQuestionLists = filteredQuestionLists.filter(questionsList => questionsList.questionList_id !== QuestionListId);
        setFilteredQuestionLists(updatedQuestionLists);
        setCoursebotmodalIsOpen(false);
        fetch(`https://gamizign.co:8000/questionLists/${QuestionListId}?instructor_id=${instructor_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                }
                else {
                    throw new Error(`Failed to delete questionlist ${QuestionListId}`);
                }
            })
            .catch(error => {
                console.error('Error deleting questionlist:', error);
            });
    };
    // Delete Course PopUp
    const [QuestionListName, setQuestionListName] = useState('');
    const [CoursebotmodalIsOpen, setCoursebotmodalIsOpen] = useState(false);
    const openCurseBotModal = () => {
        setCoursebotmodalIsOpen(true);
    };
    const CourseBotcloseModal = () => {
        setCoursebotmodalIsOpen(false);
    };
    // Make array for Pigination 
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;

    const indexOfLastquestionsLits = currentPage * rowsPerPage;
    const indexOfFirstquestionsLits = indexOfLastquestionsLits - rowsPerPage;
    const questionsLitsPigination = filteredQuestionLists.slice(indexOfFirstquestionsLits, indexOfLastquestionsLits);

    const totalPages = Math.ceil(filteredQuestionLists.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className='w-full'>
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Question Lists</h3>
                <LoginHeading />
            </div>
            <div className="m-7 bg-white">
                {/* PopUp Delete QuestionsList */}
                <Modal
                    isOpen={CoursebotmodalIsOpen}
                    onRequestClose={CourseBotcloseModal}
                    className="Modal flex justify-center mt-40"
                // overlayClassName="Overlay"
                >
                    {/* Modal Content */}
                    <div className="flex sm:ms-20" >
                        <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-gray-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                            <div className="mt-5">
                                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                                <h1 className={`sm:text-2xl `}>Are you sure you want to <b> Delete </b> the questions list <b>{QuestionListName}</b>?</h1>
                                <div className="flex justify-center gap-8 my-4">
                                    <button onClick={handleDeleteQuestionList}>Yes</button>
                                    <button onClick={CourseBotcloseModal}>No</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
                <div className="px-7 pt-10">
                    <div className="flex py-2 relative px-3" style={{ borderBottom: "2px gray solid" }}>
                        <p><b>Name</b></p>
                        <div className="flex gap-3 absolute right-3">
                            {/* <img src={Addfolder} alt="1" /> */}
                            <img
                                src={currentImageGrid}
                                alt='Grid View'
                                onClick={toggleImage}
                            />
                            <img src={currentImageGrid === GridIconOff ? ListIconOn : ListIconOff} alt="3" onClick={toggleImage} />
                        </div>
                    </div>
                    <div>
                        <input id="searchInput" value={searchTerm} onChange={handleSearch} className="sm:px-5 ps-2 py-2 border mt-3 rounded sm:w-80" type="text" placeholder="Search by any name,tag,grade,...etc." />
                    </div>
                    {/* <div className={`mt-5 ${currentImageGrid === GridIconOn ? 'hidden' : ''}`}><input type="checkbox" /> Show Groups</div> */}
                </div>

                <div className={`flex flex-wrap gap-4 px-5 justify-center grid lg:grid-cols-3 sm:grid-cols-2  ${currentImageGrid === GridIconOn ? 'hidden' : ''}`}>
                    {loading ? (
                        <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                            <svg
                                className="text-gray-300 animate-spin"
                                viewBox="0 0 64 64"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                width="100"
                                height="100">
                                <path
                                    d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                                    stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                                </path>
                                <path
                                    d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                                    stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                                </path>
                            </svg>
                        </div>
                    ) :
                        (filteredQuestionLists && Array.isArray(filteredQuestionLists)) ? (
                            filteredQuestionLists.map((questionsList, QuestionsListsIndex) => (
                                <div className="bg-gray-100 flex justify-center my-3 hover:scale-95 cursor-pointer" style={{ backgroundColor: QuestionsListsColors[QuestionsListsIndex % QuestionsListsColors.length], borderRadius: '10px', }}>
                                    <div className="py-3 relative">
                                        <h1 className="font-bold  sm:px-6 py-1">{questionsList.name}</h1>
                                        <div className="flex justify-center my-2 gap-8">
                                            <p className="flex"><span><img className="mt-1.5 mr-2" src={QuestionVolume} alt="q" /></span>{questionsList.questions.length}</p>
                                            <p className="flex"><span><img className="mt-1.5 mr-2" src={GradVolume} alt="q" /></span> </p>
                                        </div>
                                        <div className="flex justify-center">
                                            <div className="flex justify-center gap-2 bg-white p-2 border absolute -bottom-6" style={{ width: '120px', borderRadius: '8px' }}>
                                                <Link to={`/Dashboard/DownloadQuestionsList?questionList_id=${questionsList.questionList_id}`}><img src={PdfICon} alt="pdf" /></Link>
                                                <Link to={`/Dashboard/EditQuestionsList?questionList_id=${questionsList.questionList_id}`}>
                                                    <img src={EditQuestoin} alt="edit" />
                                                </Link>
                                                <img className="cursor-pointer" src={DeletQuestoin} alt="delete" onClick={() => { openCurseBotModal(); setQuestionListName(questionsList.name); setQuestionListId(questionsList.questionList_id) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="text-gray-500">No questions lists available.</div>
                        )
                    }
                </div>

                {/*Question Lists List View  */}
                <div className={`px-2 sm:px-5 ${currentImageGrid === GridIconOff ? 'hidden' : ''}`}>


                    <div class="relative overflow-x-auto max-w-80 sm:max-w-full">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                                <tr>
                                    <th scope="col" class="">
                                    </th>
                                    <th scope="col" class="px-3 py-3">
                                        Created By
                                    </th>
                                    <th scope="col" class="px-3 py-3">
                                        Date Created
                                    </th>
                                    <th scope="col" class="px-3 py-3">
                                        Last Modified
                                    </th>
                                    <th scope="col" class="px-3 py-3">
                                        Last Modified By
                                    </th>
                                    <th scope="col" class="">
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                                        <svg
                                            className="text-gray-300 animate-spin"
                                            viewBox="0 0 64 64"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="100"
                                            height="100">
                                            <path
                                                d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                                                stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                                            </path>
                                            <path
                                                d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                                                stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                                            </path>
                                        </svg>
                                    </div>
                                ) :
                                    (questionsLitsPigination && Array.isArray(questionsLitsPigination)) ?
                                        questionsLitsPigination.map((questionsList, index) => (
                                            <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                                                <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                    {questionsList.name}
                                                </th>
                                                <td class="px-3 py-6">
                                                    {questionsList.createdBy}
                                                </td>
                                                <td class="px-3 py-6">
                                                    {questionsList.dateCreated}
                                                </td>
                                                <td class="px-3 py-6">
                                                    {questionsList.lastModified}
                                                </td>
                                                <td class="px-3 py-6">
                                                    {questionsList.createdBy}
                                                </td>
                                                <td class="px-3 py-6">
                                                    <div className="flex justify-center">
                                                        <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                                                            <Link to={`/Dashboard/DownloadQuestionsList?questionList_id=${questionsList.questionList_id}`}><img src={PdfICon} alt="pdf" /></Link>
                                                            <Link to={`/Dashboard/EditQuestionsList?questionList_id=${questionsList.questionList_id}`}>
                                                                <img src={EditQuestoin} alt="edit" />
                                                            </Link>
                                                            <span className="cursor-pointer" onClick={() => { openCurseBotModal(); setQuestionListName(questionsList.name); setQuestionListId(questionsList.questionList_id) }}><img src={DeletQuestoin} alt="delete" /></span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )

                                        ) : (<div className="text-gray-500">No questions lists available.</div>)}

                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-center gap-5 mt-4">
                        <button onClick={handlePrevPage} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPage === 1}>
                            <img className="mt-1 mr-2" src={BackIcon} alt="" /> Prev
                        </button>
                        <span className="px-4 py-2">{currentPage} of {totalPages}</span>
                        <button onClick={handleNextPage} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPage === totalPages}>
                            Next <img className="mt-1 ms-2" src={NextIcon} alt="" />
                        </button>
                    </div>
                </div>
                <div className="flex justify-center">
                    <Link to='/Dashboard/CreateCourse'>  <button className=" border py-2 px-6 my-10 font-bold flex BackBtn hover:bg-blue-200" style={{ borderRadius: '7px' }}>
                        <img src={CreateNewQuestion} alt="AddNewQ" style={{ width: '30px', paddingRight: '5px' }} />
                        Create</button></Link>
                </div>
            </div>
        </div>
    )
};

export default QuestionList;