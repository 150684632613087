import React, { useEffect, useState } from "react";
import QListDashboardIcon from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/Question Lists dashboard icon.png'
import Addfolder from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/create group icon.png'
import GridIconOn from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/grid icon off.png'
import ListIconOff from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/list icon on.png'
import PdfICon from '../../../../../../assets/icons/ActivityContentIcons/QuestionListsPatch5/pdf icon.png'

import { Link, useLocation } from "react-router-dom";
import LoginHeading from "../../../../../LoginHeading/LoginHeading";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
// const generatePDF = (questionList, selectedQuestionlist) => {
//     const docDefinition = {
//       content: [
//         { text: 'Question List', style: 'header' },
//         { text: '\n' }, // Add some space
//         {
//           // Iterate over the question list and create a table
//           table: {
//             body: [
//               ['Question', 'Correct Answer'],
//               ...questionList.map(question => [
//                 question.question,
//                 question.correctAnswer
//               ])
//             ]
//           }
//         },
//         { text: '\n\n' }, // Add some space between tables
//         { text: 'Selected Question List', style: 'header' },
//         { text: '\n' }, // Add some space
//         {
//           // Iterate over the selected question list and create a table
//           table: {
//             body: [
//               ['Question', 'Correct Answer'],
//               ...selectedQuestionlist.map(question => [
//                 question.question,
//                 question.correctAnswer
//               ])
//             ]
//           }
//         }
//       ],
//       styles: {
//         header: {
//           fontSize: 18,
//           bold: true
//         }
//       }
//     };
  
//     // Generate PDF
//     pdfMake.createPdf(docDefinition).download('question_list.pdf');
//   };



const PdfDownloadQuestionList =()=>{
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const questionList_id = queryParams.get('questionList_id');
    const [selectedQuestions, setSelectedQuestions] = useState(null);
    const [pdffile, setPdffile] = useState(null);
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await fetch(`https://gamizign.co:8000/question-lists/${questionList_id}`);
                const data = await response.json();

                setSelectedQuestions(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [questionList_id]);
    if (!selectedQuestions) {
        return <div>
            <div>
            <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                <svg
                    className="text-gray-300 animate-spin"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100">
                    <path
                        d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                        stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                    </path>
                    <path
                        d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                        stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                    </path>
                </svg>
            </div>
        </div>
        </div>;
    }
    // Generate and download Quiz form
    const generatePDFQuiz = () => {
      // Check if selectedQuestions is defined and contains the questions array
      if (!selectedQuestions || !selectedQuestions.questions) {
        console.error('Selected questions data is not available.');
        return;
      }
    
      const formatQuestionOrAnswer = (text, level) => ({
        margin: [level * 10, 0, 0, 2], // Adjust margin based on level (0 for question, 1 for answer)
        text: text
      });
    
      const docDefinition = {
        content: [
          { text: selectedQuestions.name, style: 'header' },
          { text: `Created By: ${selectedQuestions.createdBy}`, style: 'createdDate' },
          { text: `Date Created: ${selectedQuestions.dateCreated}`, style: 'createdDate' },
          { text: '\n' }, // Add some space
          { text: `Multiple Choice`, style: 'MultipleChoice' },
          ...selectedQuestions.questions.flatMap((question, index) => [
            formatQuestionOrAnswer(`${index + 1}. ${question.question}`, 1),
            formatQuestionOrAnswer(`a) ${question.answer1 || ''}`, 2),
            formatQuestionOrAnswer(`b) ${question.answer2 || ''}`, 2),
            formatQuestionOrAnswer(`c) ${question.answer3 || ''}`, 2),
            formatQuestionOrAnswer(`d) ${question.answer4 || ''}`, 2),
            { text: '\n' } // Add some space after each question
          ])
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true
          },
          createdDate: {
            fontSize: 10,
            bold: true
          },
          MultipleChoice: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          question: {
            fontSize: 14,
            margin: [10, 10, 0, 5]
          },
          answer: {
            fontSize: 15,
            margin: [20, 0, 0, 2]
          }
        }
      };
    
      // Generate PDF
      pdfMake.createPdf(docDefinition).download(`${selectedQuestions.name}Quiz.pdf`);
    };
    
    // Generate and download Right Answere form
    const generatePDFRightAnswers = () => {
        // Check if selectedQuestions is defined and contains the questions array
        if (!selectedQuestions || !selectedQuestions.questions) {
          console.error('Selected questions data is not available.');
          return;
        }
      
        const docDefinition = {
          content: [
            { text: selectedQuestions.name, style: 'header' },
            { text: `Created By: ${selectedQuestions.createdBy}`, style: 'CreatedDate' },
            { text: `Date Created: ${selectedQuestions.dateCreated}`, style: 'CreatedDate' },
            { text: '\n' }, // Add some space
            {
              // Iterate over the question list and create a table
              table: {
                body: [
                  ['Sr#', 'Question', 'Answer'],
                  ...selectedQuestions.questions.map((question, index) => [
                    index+1,
                    question.question,
                    question.answer1 || '', // Make sure to handle undefined answers
                  ])
                ]
              }
            }
          ],
          styles: {
            header: {
              fontSize: 18,
              bold: true
            },
            CreatedDate: {
              fontSize: 10,
              bold: true
            }
          }
        };
      
        // Generate PDF
        pdfMake.createPdf(docDefinition).download(`${selectedQuestions.name}RightAnswers.pdf`);

      };
      

    return(
        <div className='w-full'>
        <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
            <h3 className="text-xl">Question Lists</h3>
            <LoginHeading/>
            </div>
        <div className="sm:m-7 my-7 bg-white">
        <div className='flex justify-center static'><img className='absolute top-6' src={QListDashboardIcon} alt="" /></div>
            <div className="px-5 py-10">
                <div className="flex py-2 relative px-3" style={{ borderBottom: "2px gray solid" }}>
                    <p><b>Name</b></p>
                    <div className="flex gap-3 absolute right-3">
                        <p><b>{selectedQuestions.name}</b></p>
                        {/* <img src={Addfolder} alt="1" />
                        <img src={GridIconOn} alt="2" />
                        <img src={ListIconOff} alt="3" /> */}
                    </div>
                </div>
                {/* Course Chapters Here */}
                <div className="my-2 bg-gray-100 w-full pt-10" style={{borderRadius:'10px',border:'1px gray solid', height:'500px'}}>
                        <div className="  sm:flex justify-center sm:mt-40 gap-5" >
                            {/* Right answers here */}
                            {/* {selectedQuestions.questions.map((items)=>(
                                <p>{items.answer1}</p>
                            ))} */}
                            
                            <div className="flex flex justify-center">
                            <button onClick={generatePDFQuiz} className="flex border p-5 bg-white font-bold" style={{borderRadius:'11px',width:'250px'}}>
                                <img className="mr-3" src={PdfICon} alt="quiz" />Quiz form</button>
                            </div>
                            <div className="flex justify-center mt-10 px-2 sm:mt-0">
                            <button onClick={generatePDFRightAnswers} className="flex border p-5 bg-white font-bold" style={{borderRadius:'11px',width:'250px'}}>
                                <img className="mr-3" src={PdfICon} alt="rightAnswers" />Right Answers form</button>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
    )
};

export default PdfDownloadQuestionList;
