import React, { useState } from "react";
import './saving.css'
import { useNavigate, useLocation } from 'react-router-dom';
// Games Posters
import Squirrlo from '../../../../../../../assets/images/chooseGame/Squirrlo poster.png'
import DinerDish from '../../../../../../../assets/images/chooseGame/DinerDash poster.png'
import Deepmarine from '../../../../../../../assets/images/chooseGame//Deepmarine poster.png'
import SpaceX from '../../../../../../../assets/images/chooseGame/SpaceoX poster.png'
import Loopylizer from '../../../../../../../assets/images/chooseGame/Loopylizer poster.png'
import Freesign from '../../../../../../../assets/images/chooseGame/Freesign poster.png'
import Jupitario from '../../../../../../../assets/images/chooseGame/Jupitario poster.png'
import SugarSmash from '../../../../../../../assets/images/chooseGame/SugarSmash poster.png'
import Trainaid from '../../../../../../../assets/images/chooseGame/Trainaid poster.png'
import KanwaKango from '../../../../../../../assets/images/chooseGame/Kanwa _ Kango poster.png'
import Lexileague from '../../../../../../../assets/images/chooseGame/Laxileague poster.png'
import BackIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/back iconB.png'
import SaveIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/save icon.png'
import axios from "axios";
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";


const ChooseGameJourney = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedgame, setSelectedgame] = useState(null);

    const navigate = useNavigate();
    const [gameError, setGameError] = useState(null);
    var instructor_id = localStorage.getItem("instructor_id");

    const { course, activity, activity_index, stageIndex, courseFormatDate } = useLocation().state;
    const handleImageClick = (imageName , selectedgame) => {
        setSelectedImage(imageName);
        setSelectedgame(selectedgame);
        activity.gameType = imageName;
    };
    const handleContinue = async () => {
        if(selectedImage !== null){
            setGameError('');
            navigate(`/Dashboard/gameDoneJourney`, { state: { course: course, activity: activity, selectedgame: selectedgame, stageIndex:stageIndex, courseFormatDate:courseFormatDate} });
        }
        else{
            setGameError('Select one of the games before saving');
        }
    }

    return (

        <div className='w-full'>
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Create New Activity</h3>
                <LoginHeading />
            </div>
            <div className="m-7 bg-white contentAreaChooseGame">
                {/* progress line */}
                <div>
                    <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className="flex w-full items-center text-green-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Adding</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-green-600 after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Creating</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-green-600 after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Customizing</b>
                            </span>
                        </li>
                        <li className="flex items-center text-pink-300">
                            <span>
                                <b>Saving</b>
                            </span>
                        </li>
                    </ol>
                </div>
                <div>
                    <div className="mt-10">
                        <h1 className="text-2xl font-bold flex justify-center">Choose game</h1>
                        <p className="text-blue-800 flex px-10 justify-center">Your question list will be embedded in the chosen game without coding</p>
                        <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-5 justify-center px-10 mt-3 gap-5">
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame === Squirrlo ? "scale-110" : ""}`} src={Squirrlo} alt="" onClick={() => {handleImageClick(0,Squirrlo)}} />
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame === DinerDish ? "scale-110" : ""}`} src={DinerDish} alt="" onClick={() => handleImageClick(9,DinerDish)} />
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame === Deepmarine ? "scale-110" : ""}`} src={Deepmarine} alt="" onClick={() => handleImageClick(2,Deepmarine)} />
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame === SpaceX ? "scale-110" : ""}`} src={SpaceX} alt="" onClick={() => handleImageClick(3,SpaceX)} />
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame === Loopylizer ? "scale-110" : ""}`} src={Loopylizer} alt="" onClick={() => handleImageClick(8,Loopylizer)} />
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame === Freesign ? "scale-110" : "" }`} src={Freesign} alt="" onClick={() => handleImageClick(4,Freesign)} />
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame===Jupitario?"scale-110":""}`} src={Jupitario} alt="" onClick={() => handleImageClick(1,Jupitario)} />
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame===Trainaid?"scale-110":""}`} src={Trainaid} alt="" onClick={() => handleImageClick(7,Trainaid)} />
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame===KanwaKango?"scale-110":""}`} src={KanwaKango} alt="" onClick={() => handleImageClick(5,KanwaKango)} />
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame===Lexileague?"scale-110":""}`} src={Lexileague} alt="" onClick={() => handleImageClick(6,Lexileague)} />
                            <img className={`hover:cursor-pointer  transition-transform transform hover:scale-110 ${selectedgame===SugarSmash?"scale-110":""}`} src={SugarSmash} alt="" onClick={() => handleImageClick(10,SugarSmash)} />
                        </div>
                        {gameError && (
                            <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                            <span className="font-medium">{gameError}</span> 
                          </div>                          
                            
                        )}
                    </div>
                </div>
                <div className='flex text-center mt-14 mb-5 items-center justify-center gap-3'>
                    {/* <Link to='/Dashboard/customizing'><button className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button></Link> */}
                    <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={handleContinue} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                </div>

            </div>
        </div>
    )
}

export default ChooseGameJourney;