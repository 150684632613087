import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import GamizignHIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Gamizign head icon.png'
import PdfImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/PDF icon hover off color.png'
import GameImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/game icon hover off color.png'
import VideoImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/video icon hover off color.png'
import AudioImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/Audio icon hover off color.png'
import ImageImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/image icon hover off color.png'
import UrlImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/URL icon hover off color.png'
import CourseLock from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/course locked icon.png'
import DeletQuestoin from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/delete icon.png'
import LearnerDone from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/learners done number icon.png'
import LearnerTotal from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/total learners number icon.png'
import BackIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/back iconB.png'
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";

const HomeWork = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const course_id = queryParams.get('course_id');

    const [SelectedCourse, setSelectedCourse] = useState(null)
    const [questionsLists, setQuestionsLists] = useState(null)
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getCourse = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            try {
                setLoading(true);

                const response = await fetch(`https://gamizign.co:8000/courses/${course_id}/`, requestOptions);
                const data = await response.json();
                var instructor_id = parseInt(localStorage.getItem("instructor_id"));
                const responseQlist = await fetch(`https://gamizign.co:8000/instructors/${instructor_id}/question-lists`, requestOptions);
                const dataQlist = await responseQlist.json();

                setLoading(false);
                // setCourses(data);
                setSelectedCourse(data);
                setQuestionsLists(dataQlist);
            } catch (error) {
                console.error('Error fetching courses:', error);
                // Handle the error if needed
            } finally {
                setLoading(false);
            }
        };
        getCourse();
    }, []);
    const navigate = useNavigate();
    const ActivityEvaluation = (activity, Course_name, Students,) =>{
        navigate(`/Course/ActivityEvaluation?course_id=${course_id}`, {state:{activity: activity, Course_name: Course_name, Students: Students, course_id: course_id}})
    }
    const ActivityCourseColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];
    return (
        <div className='w-full'>
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Students Homework</h3>
                <LoginHeading />
            </div>
            <div className="m-7 bg-white border-4 pb-5">
                <div className='flex justify-center static'><img className='absolute top-6' src={GamizignHIcon} alt="" /></div>
                <div className="p-5  bg-gray-50 border-b font-bold text-xl">Homework List</div>
                {loading ? (
                    <div className="flex justify-center grid min-h-[140px] w-full place-question-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                        <svg
                            className="text-gray-300 animate-spin"
                            viewBox="0 0 64 64"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            width="100"
                            height="100">
                            <path
                                d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                                stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                            </path>
                            <path
                                d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                                stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                            </path>
                        </svg>
                    </div>
                ) : (
                    <div>
                        <div className=" grid grid-cols-1 px-5 mt-10 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40">
                            {(SelectedCourse)?(SelectedCourse.stages.map((stage, stageIndex)=>(stage.activities.map((activity, activityIndex)=>(
                                 <div className={`mt-5  ${activity.activityType === 'game' ? 'hidden' : ''}`}>
                                 <div className="border relative" style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[activityIndex % ActivityCourseColors.length] }}>
                                   <div className={`flex justify-center mt-3 ${activity.activityType === "pdf" ? "" : "hidden"}`}><img src={PdfImage} alt="" /></div>
                                   <div className={`flex justify-center mt-3 ${activity.activityType === "game" ? "" : "hidden"}`}><img src={GameImage} alt="" /></div>
                                   <div className={`flex justify-center mt-3 ${activity.activityType === "video" ? "" : "hidden"}`}><img src={VideoImage} alt="" /></div>
                                   <div className={`flex justify-center mt-3 ${activity.activityType === "image" ? "" : "hidden"}`}><img src={ImageImage} alt="" /></div>
                                   <div className={`flex justify-center mt-3 ${activity.activityType === "audio" ? "" : "hidden"}`}><img src={AudioImage} alt="" /></div>
                                   <div className={`flex justify-center mt-3 ${activity.activityType === "url" ? "" : "hidden"}`}><img src={UrlImage} alt="" /></div>
                                   <div className="bg-gray-900 text-white  absolute inset-x-0 bottom-0" style={{ borderRadius: '20px', height: "100px" }}>
                                     <div className="flex justify-center"><img src={CourseLock} alt="" style={{ marginTop: "-25px" }} /></div>
                                     <p onClick={()=>{ActivityEvaluation(activity , SelectedCourse.name, SelectedCourse.students)}} className="flex justify-center pt-2 text-sm cursor-pointer"><b>{activity.name}</b></p>
                                     <p className="flex justify-center mt-2 text-sm"><b>{SelectedCourse.name}</b></p>
                                     {/* <p className="flex justify-center mt-2 text-sm"><b>{activity.stageName}</b></p> */}
                                   </div>
                                 </div>
                                 <div className="flex gap-7 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                                   {/* <img src={CourseNotActive} alt="" /> */}
                                   {/* <img src={EditQuestoin} alt="" /> */}
                                   <img className="cursor-pointer" src={DeletQuestoin} alt="" 
                                //    onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); setcourseIndex(CourseIndex); setStageIndex(stageIndex); setActivityIndex(activityIndex); openActivityBotModal() }}
                                    />
                                 </div>
                                 <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                                   <span className="relative"><img src={LearnerTotal} alt="" /><span className="absolute inset-0 left-2.5 top-0.5">{activity.analyticsList.length}</span> </span>
                                   <p>Learners</p>
                                   <span className="relative"><img src={LearnerDone} alt="" /><span className="absolute inset-0 left-2.5 top-0.5">{SelectedCourse.students.length}</span> </span>
                                 </div>
                               </div>
                            ))
                                
                            ))):(<div className="font-bold text-xl">No activity in <b>{SelectedCourse.name}</b> course</div>)}
                        </div>
                    </div>
                )}
                 <div className="flex justify-center mt-5">
                    <Link to={`/Course?course_id=${SelectedCourse?.course_id}`}><button className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button></Link>
                </div>
               
            </div>
        </div>
    )
};

export default HomeWork;