import React, { useState, useRef, useEffect } from "react";
import axios from "axios";

import FileNametIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/File icon.png';
import SubmiBtntIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/Submit icon.png';
import RadioOnIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/radio button on.png';
import RadioOffIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/radio button off.png';
import MagniferIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/magnifer icon.png';
import CheckOffIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/PPTX check box off.png';
import CheckOnIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Creating/PPTX check box on.png';
import {useLocation, useNavigate } from "react-router-dom";
import '../style.css'
import LoginHeading from "../../../../../LoginHeading/LoginHeading";

function CreatingPptxJourney() {
  const [file, setFile] = useState();
  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [images, setImages] = useState([]);
  const [isConverting, setIsConverting] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const imageBaseUrl = "https://gamizign.co:8000/tmp?file=";
  const location = useLocation();
  const { selectedFile } = useLocation().state;
  const [slides, setSlides] = useState([]);

  const navigate = useNavigate();
  const { course, stageIndex, courseFormatDate } = useLocation().state;




  useEffect(() => {
    // Access the state from the previous page
    if (location.state && location.state.selectedFile) {
      setFile(location.state.selectedFile);
      // handleConversion(); // Auto-convert after selecting the file
    }
  }, [location.state]);

    // useEffect to log the value of file whenever it changes
    useEffect(() => {
      if (file) {
        // Call handleConversion here if needed
        handleConversion();
      }
    }, [file]);

  const fileInputRef = useRef();
  
  const submitSlides = async () => {
    setIsGenerating(true);
    setIsDisabled(true);
    if (slides != null && file != null) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("slides", JSON.stringify(slides));
        formData.append("difficulty_level", JSON.stringify(selectedDifficulty));
        formData.append("range", JSON.stringify(selectedOption));
        const response = await axios.post("https://gamizign.co:8000/question_ppt_chat_gpt/", formData, {
          
        });
        if (response.data) {
          setIsGenerating(false);
          setIsDisabled(false);
          var questions = response.data;
          navigate('/Dashboard/customizingJourney', { state: { course: course, questions: questions, filename: file.name, courseFormatDate: courseFormatDate, stageIndex:stageIndex } });
        }

        else {
          setIsGenerating(false);
          setIsDisabled(false);
          console.log('Error in fetching questions');
        }
      }
      catch (error) {
        setIsGenerating(false);
        setIsDisabled(false);
        console.error('Fetch error:', error);
      }
    }
  };

  // const handleFileChange = (event) => {
  //   setFile(event.target.files[0]);
  // };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    if (!isConverting) {
      handleConversion();
    }
  };

  const handleConversion = async () => {
    setIsConverting(true);
    setMessage("Conversion and image extraction are in progress...");
    setProgress(0);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post("https://gamizign.co:8000/convert/", formData, {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          setProgress(progress);
        },
      });

      if (response.data.images) {
        setMessage("Conversion complete. Images available for download.");
        setImages(response.data.images.map((image) => ({ path: image, zoomed: false })));
        setIsConverting(false);
      } else {
        setMessage("An error occurred during conversion. Please try again.");
      }
    } catch (error) {
      setMessage("An error occurred during conversion. Please try again.");
    } finally {
      setIsConverting(false);
    }
  };
  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setImages(images.map((image) => ({ ...image, checked: newSelectAll })));
    if (newSelectAll) {
      const indices = images.map((_, index) => index);
      setSlides(indices);
    } else {
      setSlides([]);
    }
  };

  const toggleCheckbox = (index) => {
    const newImages = [...images];
    newImages[index].checked = !newImages[index].checked;
    setImages(newImages);
  };

  const toggleZoom = (index) => {
    const newImages = [...images];
    newImages[index].zoomed = !newImages[index].zoomed;
    // newImages[index].checked = false; // Ensure checkbox is not checked
    setImages(newImages);
  };

  const toggleImages = (index) => {
    const newImages = [...images];
    newImages[index].checked = !newImages[index].checked;
    setImages(newImages);
    slides.push(index);
  };


  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setFile(droppedFile);
    handleConversion();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
  };

  <button
    onClick={handleConversion}
    disabled={!file || isConverting}
    onDragOver={handleDragOver}
    onDragEnter={handleDragEnter}
    onDrop={handleDrop}
  >
    Convert
  </button>

  // Here is Question Type and Range Handles
  const [selectedButton, setSelectedButton] = useState('Easy Questions');
  const [selectedOption, setSelectedOption] = useState('5 to 10');

  var selectedDifficulty = "Medium & Hard";

  const handleButtonClick = (value) => {
    if (value === "Easy")
      selectedDifficulty = "Medium & Hard";
    if (value === "Medium")
      selectedDifficulty = "Easy & Hard";
    if (value === "Hard")
      selectedDifficulty = "Easy & Medium";
    setSelectedButton(value);
  };
  const handleOptionChange = (value) => {
    setSelectedOption(value);
  };
  //  check box toggle 
  const [showImage1, setShowImage1] = useState(true);

  // const toggleImages = () => {
  //   setShowImage1(!showImage1);
  // };

  return (
    <div className="w-full">
      <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
        <h3 className="text-xl">Create New Activity</h3>
        <LoginHeading/>
        </div>
      <div className="m-7 bg-white contentSideCewating">
        {/* progress line */}
        <div>
          <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
            <li className="flex w-full items-center text-green-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
              <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <b>Adding</b>
              </span>
            </li>
            <li className="flex w-full items-center text-pink-300 after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
              <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                <b>Creating</b>
              </span>
            </li>
            <li className="flex w-full items-center after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
              <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                <b>Customizing</b>
              </span>
            </li>
            <li className="flex items-center">
              <b>Saving</b>
            </li>
          </ol>
        </div>
        {/* Pptx code */}
        <div className="App lg:px-20">
          <div className="sm:flex mt-5">
            <button className="border FixedWidth170 flex px-3 py-2 rounded filName flex-none"><img className="mr-3 mt-1" src={FileNametIcon} alt="pic" />File Name</button>
            <p className="mt-2 ms-3 text-sky-600 flex-1">{selectedFile.name}</p>
          </div>
          <div className="flex space-x-4 mt-5">
          <div className="DIFFICULTIES flex-1">
              <button
                className={`border FixedWidth170 m-2 py-2 px-5 ${selectedButton === 'Hard Questions' ? 'bg-white' : 'bg-green-50'} text-black rounded`}
                onClick={() => handleButtonClick('Hard Questions')}
              >
                <b>Hard </b>Questions
              </button>
              <button
                className={`border FixedWidth170 m-2 py-2 px-5 ${selectedButton === 'Normal Questions' ? 'bg-white' : 'bg-green-50'} text-black rounded`}
                onClick={() => handleButtonClick('Normal Questions')}
              >
                <b>Normal </b>Questions
              </button>
              <button
                className={`border FixedWidth170 m-2 py-2 px-5 ${selectedButton === 'Easy Questions' ? 'bg-white' : 'bg-green-50'} text-black rounded`}
                onClick={() => handleButtonClick('Easy Questions')}
              >
                <b>Easy </b>Questions
              </button>
            </div>
            <div className="QUESTIONS-COUNT flex-initial w-100 py-2 md:flex">
              <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChange('5 to 10')}>
                  <span>
                  <img 
                      src={selectedOption === '5 to 10' ? RadioOnIcon : RadioOffIcon}
                      alt="5 to 10"
                      className="radio-image mt-1"
                  />
                  </span>
                  <span className={`ps-2 ${selectedOption ==='5 to 10' ? 'font-bold' : 'font-light'}`}>5 to 10</span>
              </div>
              <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChange('10 to 20')}>
                  <span>
                  <img
                      src={selectedOption === '10 to 20' ? RadioOnIcon : RadioOffIcon}
                      alt="10 to 20"
                      className="radio-image mt-1"
                  />
                  </span>
                  <span className={`ps-2 ${selectedOption ==='10 to 20' ? 'font-bold' : 'font-light'}`}>10 to 20</span>
              </div>
              <div className="image-radio flex m-2 hover:cursor-pointer" onClick={() => handleOptionChange('20 to 30')}>
                  <span>
                  <img
                      src={selectedOption === '20 to 30' ? RadioOnIcon : RadioOffIcon}
                      alt="20 to 30"
                      className="radio-image mt-1"
                  />
                  </span>
                  <span className={`ps-2 ${selectedOption ==='20 to 30' ? 'font-bold' : 'font-light'}`}>20 to 30</span>
              </div>
            </div>
            {/* <div className="space-x-5 py-2">
              <label htmlFor="" className="p-2">
                <input type="radio"
                  value="5 to 10"
                  checked={selectedOption === '5 to 10'}
                  onChange={handleOptionChange} />
                <span className="ms-2">5 to 10</span>
              </label>
              <label htmlFor="" className="p-2">
                <input type="radio"
                  value="10 to 20"
                  checked={selectedOption === '10 to 20'}
                  onChange={handleOptionChange} />
                <span className="ps-2">10 to 20</span>
              </label>
              <label htmlFor="" className="p-2">
                <input type="radio"
                  value="20 to 30"
                  checked={selectedOption === '20 to 30'}
                  onChange={handleOptionChange} />
                <span className="ps-2">20 to 30</span>
              </label>
            </div> */}
          </div>
          <div className="file-input">
          </div>
          {isConverting ? (<div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
            <svg
              className="text-gray-300 animate-spin"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100">
              <path
                d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
              </path>
              <path
                d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
              </path>
            </svg>
          </div>) : (<></>)}
          {/* {isConverting ? (
            <div className="progress-dialog">
              <p>Converting...</p>
              <div className="circular-progress"></div>
            </div>
          ) : images.length > 0 ? (
            <div className="image-container">
            </div>
          ) : null} */}
          {/*Pptx code start */}
          {/* {progress > 0 && <progress value={progress} max="100" />}
          <p>{message}</p> */}
      {images.length > 0 && (
        <div className="image-container ">
          <div className=" my-5 justify-items-start">
            <button className="text-xl" style={{  display: "block" }} >
              <input
                className="mt-1 mr-2"
                onClick={toggleSelectAll}
                type="checkbox"
                style={{ width: "20px", height: "20px" }}
                name=""
                id=""
              /> 
              Select All Slides
            </button>
          </div>
          <div className="grid px-2 2xl:grid-cols-3 xl:grid-cols-2 gap-5 flex justify-center">
            {images.map((image, index) => (
              <div key={index} className="image-wrapper ">
                {/* <input
                  type="checkbox"
                  id={`checkbox-${index}`}
                  style={{ width: "20px", height: "20px" }}
                  className="checkbox"
                  checked={image.checked}
                  onChange={() => toggleCheckbox(index)}
                /> */}
                <label className="image-label relative flex justify-center" >
                  <span className={`overlay ${image.zoomed ? "visible" : ""}`}></span>
                  <span className="magnifier" onClick={() => toggleZoom(index)}>
                    <span role="img" aria-label="Zoom In">
                      <img className="absolute left-40 -bottom-2" src={MagniferIcon} alt="" />
                    </span>
                  </span>
                  <img
                    className=""
                    src={imageBaseUrl + `${image.path.substring(image.path.lastIndexOf("/") + 1)}`}
                    alt={`${index}`}
                    style={{
                      width: image.zoomed ? "100%" : "75%",
                      marginRight: index % 3 === 2 ? "0" : "10px",
                      cursor: "pointer",
                    }}
                  />
                  <img
                    className="absolute left-40 top-5"
                    src={image.checked ? CheckOnIcon : CheckOffIcon}
                    alt=""
                    style={{ width: '50px', height: '50px', cursor: 'pointer', transition: 'transform 0.3s ease-in-out' }}
                    onClick={() => toggleImages(index)}
                  />
                </label>
              </div>
            ))}
          </div>
          {isGenerating ?
          (<div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
            <svg
              className="text-gray-300 animate-spin"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="100">
              <path
                d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
              </path>
              <path
                d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
              </path>
            </svg>
          </div>) :
          !isConverting ?
            (<div className="flex justify-center mt-10">
              <button className="border flex px-3 py-2 mb-5 rounded hover:bg-blue-200 filSubmit" onClick={submitSlides}>
                <img className="mr-3 mt-1" src={SubmiBtntIcon} alt="pic" />
                <b>Submit</b>
              </button>
            </div>) : (<></>)}
        </div>
      )}
          {/*Pptx code End */}
        </div>
      </div>
    </div>

  );
}

export default CreatingPptxJourney;
