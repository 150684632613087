import React, { useEffect, useRef } from "react";
import LoginHeading from "../../../../LoginHeading/LoginHeading";
import CheckBoxOn from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/PPTX check box on.png'
import CheckBoxOff from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/PPTX check box off.png'
import Learneres from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Users icon off.png'
import SearchIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/magnifer icon.png'
import NextIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Next icon.png'
import BackIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/back iconB.png'
import LearnersIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Learners insight icon.png'
import SkillsIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Skills insight icon.png'
import KnowlwdgeIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Knowledge insight icon.png'
import CompetenceIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Competence insight icon.png'
import AccuracyIcon from '../../../../../assets/icons/ActivityContentIcons/The-Gamizign-World/Accuracy insight icon.png'
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import mermaid from 'mermaid';
import PlotyChart from "./Components/BoxPlotChart";
import AnalyticsTable from "./Components/PlayersAnalytics";
import * as d3 from 'd3';





const GamizignWorld = () => {
    const [skills, setSkills] = useState(CheckBoxOn)
    const skillsHandle = () => {
        setSkills((prevImage) => (prevImage === CheckBoxOn ? CheckBoxOff : CheckBoxOn))
    };
    const [knowledge, setKnowledge] = useState(CheckBoxOn)
    const knowledgeHandle = () => {
        setKnowledge((prevImage) => (prevImage === CheckBoxOn ? CheckBoxOff : CheckBoxOn))
    };
    const [competence, setCompetence] = useState(CheckBoxOn)
    const CompetenceHandle = () => {
        setCompetence((prevImage) => (prevImage === CheckBoxOn ? CheckBoxOff : CheckBoxOn))
    };
    // getting data fron endpoint
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getCourses = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            var instructor_id = parseInt(localStorage.getItem("instructor_id"));

            try {
                setLoading(true);

                const response = await fetch(`https://gamizign.co:8000/instructors/${instructor_id}/courses`, requestOptions);
                const data = await response.json();

                setCourses(data);
                // setFilteredCourses(data);
                setSelectedCourse(data);
                // setLoading(false);
            } catch (error) {
                console.error('Error fetching courses:', error);
                // Handle the error if needed
            } finally {
                setLoading(false);
            }
        };

        getCourses();
    }, []);
    // handler course change 
    const [Highlight, setHighlight] = useState(null);
    const [selectedCourse, setSelectedCourse] = useState([]);
    const handleCourseChange = (e) => {
        setStudentId(null)
        const selectedIndex = parseInt(e.target.value);
        if (!isNaN(selectedIndex)) {
            setSelectedCourse(courses[selectedIndex]);
            setHighlight(e.target.value)
        } else {
            setSelectedCourse(courses);
            setHighlight(null)
        }
    };

    // Function to extract all activities from filteredCourses and set them in state
    const [students, setStudents] = useState([]);
    const [filtestudents, setfiltestudents] = useState([]);

    const extractStudents = () => {
        let allStudents = [];
        if (Array.isArray(selectedCourse)) {
            // If selectedCourse is an array
            allStudents = selectedCourse.flatMap(course => course.students);
        }
        else if (typeof selectedCourse === 'object') {
            // If selectedCourse is an object
            allStudents = Object.values(selectedCourse.students).flatMap(students => students);
        }
        // Convert array to Set to filter out duplicate students by student_id
        const uniqueStudents = Array.from(new Set(allStudents.map(student => student.student_id)))
            .map(student_id => allStudents.find(student => student.student_id === student_id));
        // Sort the unique students by firstName in ascending order
        // uniqueStudents.sort((a, b) => a.firstName.localeCompare(b.firstName));
        // uniqueStudents.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setStudents(uniqueStudents);
        setfiltestudents(uniqueStudents);
    };

    useEffect(() => {
        extractStudents();
    }, [selectedCourse]);

    // order name wise learneres
    const orderLearners = () => {
        const ordernames = [...filtestudents]
        ordernames.sort((a, b) => a.firstName.localeCompare(b.firstName));
        setStudents(ordernames);

    };

    //   for searche students
    const [searchTerm, setSearchTerm] = useState('');


    const handleSearch = (e) => {
        setSearchTerm(e.target.value);

        const lowercasedSearchTerm = e.target.value.toLowerCase();

        const filteredLists = filtestudents.filter((students) => {
            return students.firstName.toLowerCase().includes(lowercasedSearchTerm);
        });

        setStudents(filteredLists);
        setCurrentPage(1)
    };

    //   Pigination for Learners Table
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 5;

    const indexOfLastStudent = currentPage * rowsPerPage;
    const indexOfFirstStudent = indexOfLastStudent - rowsPerPage;
    const currentStudents = students.slice(indexOfFirstStudent, indexOfLastStudent);

    const totalPages = Math.ceil(students.length / rowsPerPage);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const highlightText = (text, highlight) => {
        if (!highlight) return text;
        const regex = new RegExp(`(${highlight})`, 'gi');
        return text.split(regex).map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
                <span key={index} className="bg-yellow-300 text-xl font-bold">{part}</span>
            ) : (
                part
            )
        );
    };



    // Calculate cards' values
    let totalStudents = 0;
    let totalScore = 0;
    let totalSessions = 0;
    let CompleteSessions = 0;
    let correctAnswers = 0;
    let incorrectAnswers = 0;
    let allPlayers = 0;
    let totalTime = 0;

    const uniqueStudentIds = new Set();

    if (selectedCourse) {
        if (Array.isArray(selectedCourse)) {
            selectedCourse.forEach(course => {
                totalStudents += course.students.length;
                if (course.stages) {
                    course.stages.forEach(stage => {
                        stage.activities.forEach(activity => {
                            activity.analyticsList.forEach(analytics => {
                                if (!uniqueStudentIds.has(analytics.student_id)) {
                                    uniqueStudentIds.add(analytics.student_id);
                                    allPlayers++;
                                }
                                totalScore += analytics.score;
                                totalSessions += analytics.sessions;
                                CompleteSessions += analytics.gamesWon;
                                correctAnswers += analytics.correctAnswers;
                                incorrectAnswers += analytics.incorrectAnswers;
                                totalTime += analytics.totalTime;
                            });
                        });
                    });
                }
            });
        } else {
            totalStudents += selectedCourse.students.length;
            selectedCourse.stages.forEach(stage => {
                stage.activities.forEach(activity => {
                    activity.analyticsList.forEach(analytics => {
                        if (!uniqueStudentIds.has(analytics.student_id)) {
                            uniqueStudentIds.add(analytics.student_id);
                            allPlayers++;
                        }
                        totalScore += analytics.score;
                        totalSessions += analytics.sessions;
                        CompleteSessions += analytics.gamesWon;
                        correctAnswers += analytics.correctAnswers;
                        incorrectAnswers += analytics.incorrectAnswers;
                        totalTime += analytics.totalTime;
                    });
                });
            });
        }
    }


    // Learners chart
    class ApexChart extends React.Component {
        constructor(props) {
            super(props);

            this.state = {

                series: [76],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#DBE6F5",
                                strokeWidth: '80%',
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91]
                        },
                        colors: ['#ACCCE5'],
                    },
                    labels: ['Average Results'],
                },


            };
        }



        render() {
            return (
                <div>
                    <div id="chart">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }
    // Skills chart
    class SkillsChart extends React.Component {
        constructor(props) {
            super(props);

            this.state = {

                series: [46],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#E5F2E3",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91]
                        },
                        colors: ['#BCE2BB'],
                    },
                    labels: ['Average Results'],
                },


            };
        }



        render() {
            return (
                <div>
                    <div id="chart">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }
    // Knowledge chart
    class KnowledgeChart extends React.Component {
        constructor(props) {
            super(props);

            this.state = {

                series: [-15],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#FEFAE2",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91]
                        },
                        colors: ['#FFF3C6'],
                    },
                    labels: ['Average Results'],
                },


            };
        }



        render() {
            return (
                <div>
                    <div id="chart">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }
    // Competence chart
    class CompetenceChart extends React.Component {
        constructor(props) {
            super(props);

            this.state = {

                series: [93],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#FDE4DC",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91]
                        },
                        colors: ['#F6C1C1'],
                    },
                    labels: ['Average Results'],
                },


            };
        }



        render() {
            return (
                <div>
                    <div id="chart">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }
    // Accuracy chart
    class AccuracyChart extends React.Component {
        constructor(props) {
            super(props);

            this.state = {

                series: [25],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -20,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#EFE1EE",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '22px'
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'light',
                            shadeIntensity: 0.4,
                            inverseColors: false,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 50, 53, 91]
                        },
                        colors: ['#DFBFDD'],
                    },
                    labels: ['Average Results'],
                },


            };
        }



        render() {
            return (
                <div>
                    <div id="chart">
                        <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" />
                    </div>
                    <div id="html-dist"></div>
                </div>
            );
        }
    }
    // All courses Accuracy chart
    // const colors = ['#ACCCE5', '#BCE2BB', '#DDE6C0', '#FFF3C6', '#FEE7C3', '#FCDCBD', '#F6C1C1', '#DFBFDD'];
    const colors = ['#1F77B4', '#2CA02C', '#D62728', '#FF7F0E', '#9467BD', '#8C564B', '#E377C2', '#7F7F7F'];


    // seprated analytics lists
    const [analyticsfilterd, setAnalyticsfilterd] = useState([]);

    // Function to extract all analytics from selectedCourse and set them in state
    const extractAllanalyticsList = () => {
        let allanalyticsList = [];

        // If selectedCourse is an array
        if (Array.isArray(selectedCourse)) {
            selectedCourse.forEach(course => {
                course.stages.forEach(stage => {
                    stage.activities.forEach(activity => {
                        activity.analyticsList.forEach(analytics => {
                            allanalyticsList.push(analytics.analytics_id);
                        });
                    });
                });
            });
        } else if (selectedCourse && typeof selectedCourse === 'object') {
            // If selectedCourse is a single object
            selectedCourse.stages.forEach(stage => {
                stage.activities.forEach(activity => {
                    activity.analyticsList.forEach(analytics => {
                        allanalyticsList.push(analytics.analytics_id);
                    });
                });
            });
        }

        setAnalyticsfilterd(allanalyticsList);
    };
        // Call extractAllanalyticsList when selectedCourse changes or when needed
        useEffect(() => {
            extractAllanalyticsList();
        }, [selectedCourse]);
        
    // New quadrant chart 
    const [studentId, setStudentId] = useState(null); // Global variable for student_id

    const QuadrantChart = ({ students }) => {
        const chartRef = useRef();
    
        useEffect(() => {
            const svg = d3.select(chartRef.current);
            const width = 600;
            const height = 600;
            const margin = 50;
            const innerWidth = width - 2 * margin;
            const innerHeight = height - 2 * margin;
    
            svg.selectAll('*').remove(); // Clear the SVG
    
            const chartGroup = svg.append('g')
                .attr('transform', `translate(${margin}, ${margin})`);
    
            const xScale = d3.scaleLinear().domain([0, 1]).range([0, innerWidth]);
            const yScale = d3.scaleLinear().domain([0, 1]).range([innerHeight, 0]);
    
            // Draw the quadrants with labels
            const quadrants = [
                { x: xScale(0.25), y: yScale(1.05), label: 'under-Engagement Risk', align: 'middle' },
                { x: xScale(0.75), y: yScale(1.05), label: 'On-Track / Not At Risk', align: 'middle' },
                { x: xScale(0.25), y: yScale(-0.05), label: 'Withdraw Risk', align: 'middle' },
                { x: xScale(0.75), y: yScale(-0.05), label: 'Academic Performance Risk', align: 'middle' },
            ];
    
            quadrants.forEach(({ x, y, label, align }) => {
                chartGroup.append('text')
                    .attr('x', x)
                    .attr('y', y)
                    .attr('text-anchor', align)
                    .text(label);
            });
    
            // Draw quadrant borders
            svg.append('g')
                .attr('transform', `translate(${margin}, ${margin})`)
                .selectAll('line')
                .data([0.5])
                .enter()
                .append('line')
                .attr('x1', d => xScale(d))
                .attr('x2', d => xScale(d))
                .attr('y1', 0)
                .attr('y2', innerHeight)
                .attr('stroke', 'black')
                .style('stroke-dasharray', '5,5'); // Dotted border
    
            svg.append('g')
                .attr('transform', `translate(${margin}, ${margin})`)
                .selectAll('line')
                .data([0.5])
                .enter()
                .append('line')
                .attr('x1', 0)
                .attr('x2', innerWidth)
                .attr('y1', d => yScale(d))
                .attr('y2', d => yScale(d))
                .attr('stroke', 'black')
                .style('stroke-dasharray', '5,5'); // Dotted border
    
            // Create a tooltip
            const tooltip = chartGroup.append('text')
            .style('opacity', 0)
            .style('font-size', '12px')
            .style('font-weight', 'bold')
            .style('border', '1px solid black')
            .style('border-radius', '5px')
            .style('background-color', 'white')
            .style('z-index', '10')
            .attr('fill', 'black');
    
            // Draw the students
            students.forEach(student => {
                const correctAnswers = student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).reduce((acc, analytics) => acc + analytics.correctAnswers, 0);
                const incorrectAnswers = student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).reduce((acc, analytics) => acc + analytics.incorrectAnswers, 0);
                const gamesWon = student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).reduce((acc, analytics) => acc + analytics.gamesWon, 0);
                const sessions = student.analyticsList.filter(analytics => analyticsfilterd.includes(analytics.analytics_id)).reduce((acc, analytics) => acc + analytics.sessions, 0);
    
                const accuracy = correctAnswers > 0 ? ((correctAnswers / (correctAnswers + incorrectAnswers)) * 100).toFixed(2) : 0;
                const engagement = gamesWon > 0 ? ((gamesWon / sessions) * 100).toFixed(2) : 0;
                const engagementFix = gamesWon > 0 ? ((gamesWon / sessions) * 100).toFixed(0) : 0;
    
                const accuracyValue = (accuracy/100);
                const engagementValue = (engagement/100);
    
                const sumValues = Number(accuracy) + Number(engagement);
                let color = '#f9cccc';
                if (accuracy >= 50 && engagement >= 50) color = '#E5F2E3';
                else if (sumValues >= 50) color = '#DBE6F5';
    
                chartGroup.append('circle')
                    .attr('cx', xScale(accuracyValue))
                    .attr('cy', yScale(engagementValue))
                    .attr('r', 15) // Increase the radius of circles
                    .attr('fill', color)
                    .attr('stroke', 'black')
                    .attr('cursor', 'pointer')
                    .on('click', () => setStudentId(student.student_id));
                    
    
                const randomOffsetX = (Math.random() - 0.5) * 30;
                const randomOffsetY = (Math.random() - 0.5) * 30;
    
                chartGroup.append('text')
                    .attr('x', xScale(accuracyValue)+18)
                    .attr('y', yScale(engagementValue)+4 + randomOffsetY)
                    .text(student.firstName)
                    .attr('font-size', '12px')
                    .attr('fill', 'black')
                    .attr('stroke', 'none')
                    .attr('cursor', 'pointer')
                    .on('click', () => setStudentId(student.student_id))
                    .on('mouseenter', function () {
                        tooltip.transition().duration(200)
                            .style('opacity', 1);
                        tooltip.text(`Accuracy: ${accuracy}%  Games Won: ${gamesWon}`)
                            .attr('x', xScale( accuracyValue) + (accuracy >= 30 ? -150 : 0) ) // Adjust tooltip position
                            .attr('y', yScale(engagementValue) - 28);
                    })
                    .on('mouseleave', function () {
                        tooltip.transition().duration(500)
                            .style('opacity', 0);
                    });
            });
    
        }, [students]);
    
        return (
            <div>
                <svg ref={chartRef} width="700" height="600"></svg>
                <p>{studentId}</p>
            </div>
        );
    };
    
    



    return (
        <div className="w-full">
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Dashboard</h3>
                <LoginHeading />
            </div>
            {loading ? (
                <div class="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                    <svg
                        class="text-gray-300 animate-spin"
                        viewBox="0 0 64 64"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100"
                        height="100">
                        <path
                            d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                            stroke="#b6dff7" stroke-width="5" stroke-linecap="round" stroke-linejoin="round">
                        </path>
                        <path
                            d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                            stroke="#FFFFFF" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" class="text-gray-900">
                        </path>
                    </svg>
                </div>
            ) : (
                <div class=" mx-7 ">
                    <div className="grid md:grid-cols-2">
                        <div className="grid md:grid-cols-2">
                            <div className="flex gap-3">
                                <span className="font-bold mt-2.5">Courses</span>
                                <select className="border outline-none p-3 w-56"
                                    onChange={handleCourseChange}
                                    value={courses.indexOf(selectedCourse)}
                                    name="" id="">
                                    <option selected value="">All</option>
                                    {courses.map((course, index) => (
                                        <option key={index} value={index}>
                                            {course.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex gap-3">
                                <span className="font-bold mt-2.5">Duration</span>
                                <select className="border outline-none p-3 w-36" name="" id="">
                                    <option selected value="">01 Week</option>
                                    <option value="">02 Weeks</option>
                                    <option value="">03 Weeks</option>
                                    <option value="">01 Month</option>
                                </select>
                            </div>
                        </div>
                        <div className="grid md:grid-cols-3 py-2">
                            <div className="flex gap-3">
                                <img className="cursor-pointer" onClick={skillsHandle} src={skills} width={30} alt="skill" />
                                <span className="font-bold mt-1">Skills</span>
                            </div>
                            <div className="flex gap-3">
                                <img src={knowledge} onClick={knowledgeHandle} width={30} alt="Knowledge" />
                                <span className="font-bold mt-1">Knowledge</span>
                            </div>
                            <div className="flex gap-3">
                                <img src={competence} onClick={CompetenceHandle} width={30} alt="Competence" />
                                <span className="font-bold mt-1">Competence</span>
                            </div>
                        </div>

                    </div>
                    {/* Cards */}
                    <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 my-5 gap-5">
                        {/* Learners card */}
                        <div className="border bg-blue-100 pt-5 shadow-lg" style={{ background: "#ACCCE5" }}>
                            <div className=" h-24">
                                <p className="flex justify-center font-bold text-xl">Learners</p>
                                <p className="flex justify-center font-bold text-2xl">{allPlayers}</p>
                            </div>
                            <div className="border border-bottom bg-white">
                                <div className="flex justify-center bg-blue-50 mx-5 p-5 border -mt-5 gap-5" style={{ background: "#DBE6F5" }}>
                                    <div>
                                        <p>Previous week</p>
                                        <p className="flex justify-center">360</p>
                                    </div>
                                    <img className={"m-auto"} width={40} src={LearnersIcon} alt="" />
                                </div>
                                <div id="ApexChart" className="ApexChart my-5 flex justify-center">
                                    <ApexChart />
                                </div>
                            </div>
                        </div>
                        {/* Skills card */}
                        <div className={`border bg-blue-100 pt-5 shadow-lg ${skills === CheckBoxOn ? "" : "hidden"}`} style={{ background: "#BCE2BB" }}>
                            <div className=" h-24">
                                <p className="flex justify-center font-bold text-xl">Skills</p>
                                <p className="flex justify-center font-bold text-2xl">{CompleteSessions > 0 ? ((CompleteSessions / totalSessions) * 100).toFixed(2) : 0}%</p>
                            </div>
                            <div className="border border-bottom bg-white">
                                <div className="flex justify-center bg-blue-50 mx-5 p-5 border -mt-5 gap-5" style={{ background: "#E5F2E3" }}>
                                    <div>
                                        <p>Previous week</p>
                                        <p className="flex justify-center">38%</p>
                                    </div>
                                    <img className={"m-auto"} width={40} src={SkillsIcon} alt="" />
                                </div>
                                <div id="ApexChart" className="ApexChart my-5 flex justify-center">
                                    <SkillsChart />
                                </div>
                            </div>
                        </div>
                        {/* Knowledge card */}
                        <div className={`border bg-blue-100 pt-5 shadow-lg ${knowledge === CheckBoxOn ? '' : 'hidden'}`} style={{ background: "#FFF3C6" }}>
                            <div className=" h-24">
                                <p className="flex justify-center font-bold text-xl">Knowledge</p>
                                <p className="flex justify-center font-bold text-2xl">{correctAnswers > 0 ? (((correctAnswers / (correctAnswers + incorrectAnswers)) * 100) / allPlayers).toFixed(2) : 0.0}%</p>
                            </div>
                            <div className="border border-bottom bg-white">
                                <div className="flex justify-center bg-blue-50 mx-5 p-5 border -mt-5 gap-5" style={{ background: "#FEFAE2" }}>
                                    <div>
                                        <p>Previous week</p>
                                        <p className="flex justify-center">65%</p>
                                    </div>
                                    <img className={"m-auto"} width={40} src={KnowlwdgeIcon} alt="" />
                                </div>
                                <div id="ApexChart" className="ApexChart my-5 flex justify-center">
                                    <KnowledgeChart />
                                </div>
                            </div>
                        </div>
                        {/* Competence card */}
                        <div className={`border bg-blue-100 pt-5 shadow-lg ${competence === CheckBoxOn ? '' : 'hidden'}`} style={{ background: "#F6C1C1" }}>
                            <div className=" h-24">
                                <p className="flex justify-center font-bold text-xl">Competence</p>
                                <p className="flex justify-center font-bold text-2xl">{correctAnswers > 0 ? (((correctAnswers / (correctAnswers + incorrectAnswers)) * 100) / totalSessions).toFixed(2) : 0.0}%</p>
                            </div>
                            <div className="border border-bottom bg-white">
                                <div className="flex justify-center bg-blue-50 mx-5 p-5 border -mt-5 gap-5" style={{ background: "#FDE4DC" }}>
                                    <div>
                                        <p>Previous week</p>
                                        <p className="flex justify-center">68%</p>
                                    </div>
                                    <img className={"m-auto"} width={40} src={CompetenceIcon} alt="" />
                                </div>
                                <div id="ApexChart" className="ApexChart my-5 flex justify-center">
                                    <CompetenceChart />
                                </div>
                            </div>
                        </div>
                        {/* Accuracy card */}
                        <div className="border bg-blue-100 pt-5 shadow-lg" style={{ background: "#DFBFDD" }}>
                            <div className=" h-24">
                                <p className="flex justify-center font-bold text-xl">Accuracy</p>
                                <p className="flex justify-center font-bold text-2xl">{correctAnswers > 0 ? ((correctAnswers / (correctAnswers + incorrectAnswers)) * 100).toFixed(2) : 0.0}%</p>
                            </div>
                            <div className="border border-bottom bg-white">
                                <div className="flex justify-center bg-blue-50 mx-5 p-5 border -mt-5 gap-5" style={{ background: "#EFE1EE" }}>
                                    <div>
                                        <p>Previous week</p>
                                        <p className="flex justify-center">71%</p>
                                    </div>
                                    <img className={"m-auto"} width={40} src={AccuracyIcon} alt="" />
                                </div>
                                <div id="ApexChart" className="ApexChart my-5 flex justify-center">
                                    <AccuracyChart />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*courses Accuracy and bubble chart */}
                    <div className="grid sm:grid-cols-2 gap-5">
                        <div className="bg-white border"><PlotyChart coursesData={courses} colors={colors} highlight={Highlight} /></div>
                        <div className="bg-white border"><QuadrantChart students={filtestudents} /></div>

                    </div>
                    {/* Students table */}
                    <p className="mt-5 text-xl font-bold">Learner Results</p>
                    <div className="my-3 border bg-white p-5 ">
                        <div className="flex justify-between">
                            <div className="flex gap-3">
                                <span><img src={SearchIcon} alt="Search Icon" className="transform scale-x-[-1] mt-3" /></span>
                                <input className="outline-none border p-3 w-56" placeholder="Search by Name" type="text" value={searchTerm} onChange={handleSearch} />
                            </div>
                            {/* <div> <span><img src={CheckBoxOn} alt="" /></span></div> */}
                        </div>
                        {/* Table of students Start */}
                        <div className="relative overflow-x-auto max-w-80 sm:max-w-full">
                            <AnalyticsTable selectedCourse={selectedCourse} currentStudents={currentStudents} studentId={studentId} />
                            <div className="flex justify-center gap-5 mt-4">
                                <button onClick={handlePrevPage} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPage === 1}>
                                    <img className="mt-1 mr-2" src={BackIcon} alt="" /> Prev
                                </button>
                                <span className="px-4 py-2">{currentPage} of {totalPages}</span>
                                <button onClick={handleNextPage} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPage === totalPages}>
                                    Next <img className="mt-1 ms-2" src={NextIcon} alt="" />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            )}

        </div>
    )
};

export default GamizignWorld;