import React, { useState, useEffect } from "react";
import '../style.css'
import DragDropIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Drag _ Drop icon.png'
import UploadIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Upload a File icon.png'
import BackIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/back iconB.png'
import CurrentQIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Adding/Current Questions Lists icon.png'
import ManuallIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Adding/Manually Create Questions icon.png'
import { Link, useNavigate, useLocation } from "react-router-dom";
import LoginHeading from "../../../../../LoginHeading/LoginHeading";
// import currentQuestion from  "../../../../../pages/sidebar/sidbarOptionsContent/Activity/Newcourse/adding"



const AddingContentImporter = () => {
    const { course } = useLocation().state || "";
    const {stageIndex, courseFormatDate} = useLocation().state;

    useEffect(() => {
        fetchQuestionsLists();
    }, []);

    const [selectedFile, setSelectedFile] = useState(null);
    const navigate = useNavigate();
    const [questionsLists, setQuestionsLists] = useState([]);
    const [isHovering, setIsHovering] = useState(false);
    var [selQuestionList, setQuestionsList] = useState('Current Questions Lists');
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    let hh = today.getHours();
    let min = today.getMinutes();
    let ss = today.getSeconds();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '/' + mm + '/' + yyyy
    var instructor_id = localStorage.getItem("instructor_id");
    var inst_name = localStorage.getItem("name");
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);

            // Check the file type
            const fileType = file.type;

            // Navigate based on file type
            if (fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
                // PPTX file type, navigate to one link
                navigate('/Dashboard/JourneyPptx', { state: { course:course, selectedFile: file, stageIndex:stageIndex, courseFormatDate:courseFormatDate } });
            } else if (fileType === 'application/pdf') {
                // PDF file type, navigate to another link
                navigate('/Dashboard/JourneyPdf', { state: { course:course, selectedFile: file, stageIndex:stageIndex, courseFormatDate:courseFormatDate } });
            } else {
                // Handle other file types or show an error message
                console.error('Unsupported file type');
            }
        }
    };
    const fetchQuestionsLists = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };
        var instructor_id = parseInt(localStorage.getItem("instructor_id"));
        await fetch(`https://gamizign.co:8000/instructors/${instructor_id}/question-lists`, requestOptions).then(response => response.json()).then(data => {
            setQuestionsLists(data);
        });

    }
    const handleDrop = (e) => {
        e.preventDefault();
      
        const files = e.dataTransfer.files;
      
        const isValidFileType = Array.from(files).some(
          (file) =>
            file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || // pptx
            file.type === 'application/pdf' // pdf
        );
      
        if (isValidFileType) {
          const file = files[0];
      
          // Navigate based on file type
          if (file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
            // PPTX file type, navigate to one link
            navigate('/Dashboard/JourneyPptx', { state: { selectedFile: file, stageIndex:stageIndex, courseFormatDate:courseFormatDate } });
          } else if (file.type === 'application/pdf') {
            // PDF file type, navigate to another link
            navigate('//Dashboard/JourneyPdf', { state: { selectedFile: file, stageIndex:stageIndex, courseFormatDate:courseFormatDate } });
          } else {
            // Handle other file types or show an error message
            console.error('Unsupported file type');
          }
        } else {
          console.log('Invalid file type. Please drop a .pptx or .pdf file.');
        }
      };
      
    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const getBackToActivityType= () => {
        navigate('/Dashboard/activityType', {state:{course: course, stageIndex: stageIndex, courseFormatDate:courseFormatDate}});
    }

    const handleQuestSelections = (questionList) => {
        selQuestionList = questionList.name;
        setQuestionsList(selQuestionList);
        setIsHovering(false);

        var activity = {
            "name": "activity",
            "activityType": "game",
            "scoreVolume": 50,
            "instructor_id": instructor_id,
            "actionType": 0,
            "questionList_id": questionList.questionList_id,
            "dateCreated": formattedToday,
            "createdBy": inst_name,
        }
        navigate('/Dashboard/chooseGameJourney', { state: { questionList: selQuestionList, course: course, activity: activity, stageIndex: stageIndex, courseFormatDate:courseFormatDate } });
    }

    return (
        <div className='w-full'>
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Create New Activity</h3>
                <LoginHeading/>
                </div>
            <div className="m-7 bg-white contentSide">
                {/* Progress Line */}
                <div>
                    <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className="flex w-full items-center text-pink-300 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <b>Adding</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <b>Creating</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center after:content-[''] after:w-full after:border-blue-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <b>Customizing</b>
                            </span>
                        </li>
                        <li className="flex items-center">
                            <b>Saving</b>
                        </li>
                    </ol>
                </div>
                <div className="flex flex-col w-full items-center justify-center text-center">
                    <div className='text-center mt-5 mb-2'>
                        <div><h1><b>Content Importer</b></h1>
                            <p className='text-sky-600'>Easily convert your content into interactive e-learing games</p></div>
                    </div>
                    <label
                        htmlFor="fileInput"
                        className="InputBg w-100 relative cursor-pointer border-2 border-dashed border-gray-300 p-4 rounded-xl transition duration-300 hover:border-blue-500"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}>
                        <input
                            type="file"
                            id="fileInput"
                            className="absolute h-0 w-0 overflow-hidden"
                            accept=".pptx, .pdf"
                            onChange={handleFileChange}
                        />
                        <div className="text-center md:flex gap-3">
                            <div className='text-center mt-3 px-5'>
                                <div className='flex justify-center'><img src={DragDropIcon} alt="drag" /></div>
                                <p>Drag & Drop</p>
                            </div>
                            <span className='mt-7 pr-5'>OR</span>
                            <div className='bg-white flex gap-2 p-3 px-20 rounded-xl'>
                                <div className='px-5'>
                                    <div className='flex justify-center'><img src={UploadIcon} alt="uploadFile" /></div>
                                    <p>Upload a File</p>
                                </div>
                            </div>
                        </div>
                    </label>
                    {/* Slected File which user select */}
                    {selectedFile && (
                        <div className="mt-4">
                            <p className="text-lg font-semibold">Selected File:</p>
                            <p className="text-gray-500">{selectedFile.name}</p>
                        </div>
                    )}
                    <p className="py-3">Or</p>
                    <button name="currentQuestion"
                        className="border flex px-4 md:w-80 h-10 py-2 h-auto CurentQListBtn"
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}>

                        <img src={CurrentQIcon} alt="Icon" />
                        <span className="mt-1 ms-3">{selQuestionList}</span>
                        {isHovering && (
                            <div className="origin-top-right mt-10 overflow-y-auto overflow-x-auto max-h-64 max-w-40 md:max-w-80 absolute py-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                <div className="list-container max-h-100 py-30 w-fit">
                                    <ul>
                                        {questionsLists.map((item, index) => (
                                            <li className="hover:bg-gray-100 py-2" id={index} onClick={() => handleQuestSelections(item)} key={item.questionsList_id}>{item.name} </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        )}
                    </button>

                    <p className="py-3">Or</p>
                    <button className="border flex px-4 w-80 py-2 ManualBtn" onClick= {()=> navigate("/Dashboard/EditQuestionsList", { state: { course: course, stageIndex:stageIndex, courseFormatDate:courseFormatDate, prevPath: useLocation.pathname}})}><img src={ManuallIcon} alt="Icon" /><span className="mt-1 ms-3"><b>Manually </b>Create Questions</span></button>
                    <button className="border flex px-7 py-2 rounded mt-10 BackBtn hover:bg-blue-200"onClick={getBackToActivityType} ><b>Back</b><img className="mt-1 ms-2" src={BackIcon}  alt="back" /></button>
                </div>
            </div>
        </div>
    )
};

export default AddingContentImporter;