import React from "react";
import './saving.css'
import { useState, useEffect  } from 'react';
import { Link,  useLocation, useNavigate} from 'react-router-dom';
import BackIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/back iconB.png';
import SaveIcon from '../../../../../../../assets/icons/ActivityContentIcons/NewCourse/save icon.png';
import axios from "axios";
import NewCourse from "../newCourse";
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";
import CircularProgress from '@mui/material/CircularProgress';


const SavingQuestions = () =>{
    const { course, questions, filename } = useLocation().state;
    const navigate = useNavigate();
    var tempFilename;
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState('');
    const [progress, setIsProgress] = useState(false);


    let hasError = false;
    let activities = [];
    let stages = [];
    
    const handleNameChange = (e) => {
        tempFilename = e.target.value;
        setName(e.target.value);
        setNameError('');
    };
    const saveActivity  = async () =>{
        setIsProgress(true);
        if (!name) {
            setNameError('Please fill in the questions name.');
            hasError = true;
        }else{
            hasError = false;
            try {
                const today = new Date();
                const yyyy = today.getFullYear();
                let mm = today.getMonth() + 1; // Months start at 0!
                let dd = today.getDate();
                let hh = today.getHours();
                let min = today.getMinutes();
                let ss = today.getSeconds();

                if (dd < 10) dd = '0' + dd;
                if (mm < 10) mm = '0' + mm;

                const formattedToday = dd + '/' + mm + '/' + yyyy 
                var instructor_id = localStorage.getItem("instructor_id");
                var inst_name = localStorage.getItem("name");

                var req = {
                    "name" : name, 
                    "dateCreated":  formattedToday,
                    "createdBy": inst_name,
                    "lastModified": formattedToday, 
                    "questions": questions,
                }
                var activity = {
                    "name": "activity",
                    "activityType": "game",
                    "scoreVolume": 50,
                    "instructor_id": instructor_id,
                    "actionType": 0,
                    "dateCreated": formattedToday,
                    "createdBy": inst_name,
                }
                const response = await axios.post("https://gamizign.co:8000/instructors/" + instructor_id + "/questions/", req);
          
                if (response.data) {
                    setIsProgress(false);
                    activity.questionList_id = response.data.questionList_id;
                    navigate('/Dashboard/chooseGame', {state:{ course: course , activity : activity, activity_index:course.stages[0].activities.length , questionList_id : response.data.questionList_id}});
                } else {
                    setIsProgress(false);
                }
              } catch (error) {
                setIsProgress(false);
                // setErr("An error occurred during conversion. Please try again.");
              } 

        }
    }
    // Back to cusromize page
    const BackToCustomize = () => {
        navigate('/Dashboard/customizing', { state: { course: course, questions: questions, filename: filename } });
    };


    return(
      
            <div className='w-full'>
                <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                    <h3 className="text-xl">Create New Activity</h3>
                    <LoginHeading/>
                    </div>
                <div className="m-7 bg-white contentArea relative" disabled={progress}>
                {progress && (
                    <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 z-50">
                        <CircularProgress className="text-blue-800" />
                    </div>
                )}
                  {/* progress line */}
                  <div>
                    <ol className="flex items-center pt-5 px-2 sm:px-10 w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                        <li className="flex w-full items-center text-green-600 dark:text-blue-500 sm:after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Adding</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-green-600 after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                                <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Creating</b>
                            </span>
                        </li>
                        <li className="flex w-full items-center text-green-600 after:content-[''] after:w-full after:border-green-200 after:border sm:after:inline-block   dark:after:border-gray-700">
                            <span className="flex items-center after:text-gray-200 dark:after:text-gray-500">
                            <svg className="w-3.5 h-3.5 sm:w-4 sm:h-4 me-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <b>Customizing</b>
                            </span>
                        </li>
                        <li className="flex items-center text-pink-300">
                            <span>
                            <b>Saving</b>
                            </span>
                        </li>
                    </ol>
                </div>
                <div className="flex justify-center">
                    <div className="border mt-20 pb-10" style={{width:'90%', borderRadius:"5px 5px 10px 10px"}}>
                        <div className="bg-green-50 flex px-5 py-3" style={{borderRadius:"5px 5px 0px 0px"}}>
                            Save your Questions
                        </div>
                        <div className="border mt-10 bg-orange-50 flex py-2 px-1 mx-10">
                            <span className="px-3 mt-1">Name:</span> <input className="w-full border px-5 py-1"onChange={handleNameChange} type="text" value={tempFilename} name="" id="" />
                        </div>
                        {nameError && <p className="text-red-500 px-10">{nameError}</p>}
                        <div className="flex justify-center mt-5">
                        <p className="text-blue-800 w-[90%]">Clear simple name with chapter number, edition number & date is better for recalling</p>
                        </div>

                    </div>
                </div>
                <div className='flex text-center mt-14 items-center justify-center gap-3'>
                                <button onClick={BackToCustomize} className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button>
                          <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' onClick={saveActivity} ><img className='mr-5 mt-1' src={SaveIcon} alt="Next" /><b> Save</b></button>
                </div>
                
               </div>
               </div>    
    )
}

export default SavingQuestions;