import './App.css';
import './index.css';

import Dashboard from './components/pages/sidebar/sidebarMenu';
import SignInPage from './components/pages/signin/signin';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import NewCourse from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/newCourse';
import Activity from './components/pages/sidebar/sidbarOptionsContent/Activity/activity';
import Adding from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/adding';
import QuestionList from './components/pages/sidebar/sidbarOptionsContent/QuestionList/questionList';
import Gamizign from './components/pages/sidebar/sidbarOptionsContent/Gamizign/gamizign';
import Insights from './components/pages/sidebar/sidbarOptionsContent/Insights/insights';
import Instructors from './components/pages/sidebar/sidbarOptionsContent/Instructors/instructors';
import LearnerList from './components/pages/sidebar/sidbarOptionsContent/LearnerList/learnerList';
import Users from './components/pages/sidebar/sidbarOptionsContent/Users/users';
import Rewards from './components/pages/sidebar/sidbarOptionsContent/Rewards/rewards';
import Settings from './components/pages/sidebar/sidbarOptionsContent/Settings/settings';
import Help from './components/pages/sidebar/sidbarOptionsContent/Help/help';
import CreatingPptx from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/CreatingPptx';
import CreatingPdf from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/CreatingPdf';
import Customizing from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/customizing';
import SavingQuestions from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/savingQuestion';
import ChooseGame from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/chooseGame';
import GameDone from './components/pages/sidebar/sidbarOptionsContent/Activity/Newcourse/Saving/gameDone';
// Patch 3
import CoverImage from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/coverImage';
import AddingActivityType from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/activityType';
import AddingContentImporter from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/contentImporter';
import CreatingPptxJourney from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/CreatingPptxJourney';
import CreatingPdfJourney from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/CreatingPdfJourney';
import CustomizingJourney from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/customizingJourney';
import SavingQuestionsJourney from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/Saving/savingQuestionJourney';
import ChooseGameJourney from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/Saving/chooseGameJourney';
import GameDoneJourney from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/Saving/gameDoneJourney';
import MultiActivy from './components/pages/sidebar/sidbarOptionsContent/Activity/JourneyActivity/MultiActivity/mutiActivity';
import QuestionListEdit from './components/pages/sidebar/sidbarOptionsContent/QuestionList/components/EditQuestionList';
import PdfDownloadQuestionList from './components/pages/sidebar/sidbarOptionsContent/QuestionList/components/PdfDownloadQuestionList';
import CourseBar from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/CourseBar';
import CourseAnalytics from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/Analytics';
import HomeWork from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/HomeWork';
import CourseSetting from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/CourseSetting';
import ViewCourse from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/ViewCourse';
import PublishCourse from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/PublishCourse';
import GamizignWorld from './components/pages/sidebar/sidbarOptionsContent/The Gamizign World/gamizignWorld';
import ActivityEvaluation from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/ActivityEva';
import LearnerEva from './components/pages/sidebar/sidbarOptionsContent/Gamizign/Components/HomeWork/LearnerEva';

function App() {
  const getInitialElement = () => {
    const id = localStorage.getItem("instructor_id");
    return id === null ? <SignInPage /> : <Dashboard />;
  };
  
  let router = createBrowserRouter([
    {
      path: "/",
      element: getInitialElement()
    },
    {
      path: "/Dashboard",
      element: <Dashboard />,
      children: [
        {
          path: "/Dashboard",
          element: <GamizignWorld />
        },
        {
          path: "/Dashboard/CreateCourse",
          element: <Activity />
        },
        // Tabs routes here
        {
          path: "/Dashboard/questionList",
          element: <QuestionList />
        },
        {
          path: "/Dashboard/Courses",
          element: <Gamizign />
        },
        {
          path: "/Dashboard/insights",
          element: <Insights />
        },
        {
          path: "/Dashboard/instructors",
          element: <Instructors />
        },
        {
          path: "/Dashboard/learnerList",
          element: <LearnerList />
        },
        {
          path: "/Dashboard/users",
          element: <Users />
        },
        {
          path: "/Dashboard/rewards",
          element: <Rewards />
        },
        {
          path: "/Dashboard/settings",
          element: <Settings />
        },
        {
          path: "/Dashboard/help",
          element: <Help />
        },
        // Create New Activity 
        {
          path: "/Dashboard/newcourse",
          element: <NewCourse />
        },
        {
          path: "/Dashboard/adding",
          element: <Adding />
        },
        {
          path: "/Dashboard/creatingPptx",
          element: <CreatingPptx />
        },
        {
          path: "/Dashboard/creatingPdf",
          element: <CreatingPdf />
        },
        {
          path: "/Dashboard/customizing",
          element: <Customizing />
        },
        {
          path: "/Dashboard/saveQuestions",
          element: <SavingQuestions />
        },
        {
          path: "/Dashboard/chooseGame",
          element: <ChooseGame />
        },
        {
          path: "/Dashboard/gameDone",
          element: <GameDone />
        },
        // Patch 3 Journey course
        {
          path: "/Dashboard/journeyCourse",
          element: <CoverImage />
        },
        {
          path: "/Dashboard/activityType",
          element: <AddingActivityType />
        },
        {
          path: "/Dashboard/ImportContent",
          element: <AddingContentImporter />
        },
        {
          path: "/Dashboard/JourneyPptx",
          element: <CreatingPptxJourney />
        },
        {
          path: "/Dashboard/JourneyPdf",
          element: <CreatingPdfJourney />
        },
        {
          path: "/Dashboard/customizingJourney",
          element: <CustomizingJourney />
        },
        {
          path: "/Dashboard/saveQuestionsJourney",
          element: <SavingQuestionsJourney />
        },
        {
          path: "/Dashboard/chooseGameJourney",
          element: <ChooseGameJourney />
        },
        {
          path: "/Dashboard/gameDoneJourney",
          element: <GameDoneJourney />
        },
        // Multi Activity Journey course
        {
          path: "/Dashboard/multiActivities",
          element: <MultiActivy />
        },
        // Question List 
        {
          path: "/Dashboard/DownloadQuestionsList",
          element: <PdfDownloadQuestionList />
        },
        {
          path: "/Dashboard/EditQuestionsList",
          element: <QuestionListEdit />
        },

      ]


    },
    // Gamizign you courses
    {
      path: "/Course",
      element: <CourseBar />,
      children: [
        {
          path: "/Course",
          element: <CourseAnalytics />,
          // children:[
          //   {
          //     path:"/Course/HomeWork",
          //     element:<GameDoneJourney/>,
          //   },
          // ]
        },
        {
          path: "/Course/HomeWork",
          element: <HomeWork />,
        },
        {
          path: "/Course/CourseSetting",
          element: <CourseSetting />,
        },
        {
          path: "/Course/ViewCourse",
          element: <ViewCourse />,
        },
        {
          path: "/Course/PublishCourse",
          element: <PublishCourse />,
        },
        {
          path: "/Course/ActivityEvaluation",
          element: <ActivityEvaluation />,
        },
        {
          path: "/Course/LearnerEvaluation",
          element: <LearnerEva />,
        },
      ]
    },
  ])
  return (
    <div>
      <RouterProvider router={router} />
      {/* <SignInPage/>  */}
    </div>
  );
}

export default App;
