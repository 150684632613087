import React, { useState, useEffect } from "react";
import Addfolder from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/create group icon.png'
import GridIconOn from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/grid icon on.png'
import GridIconOff from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/grid icon off.png'
import ListIconOn from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/list icon on.png'
import ListIconOff from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/list icon off.png'
import CheckBoxOn from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/PPTX check box on.png'
import CheckBoxOff from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/PPTX check box off.png'
import GamizignHIcon from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Gamizign head icon.png'
import CourseDoneIcon from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course done icon.png'
import CourseLock from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course locked icon.png'
import CourseUnLock from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course open icon.png'
import CourseActive from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course active icon.png'
import CourseNotActive from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/course not active icon.png'
import LearnerTotal from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/total learners number icon.png'
import LearnerDone from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/learners done number icon.png'
import EditQuestoin from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/edit icon.png'
import DeletQuestoin from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/delete icon.png'
import PdfImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/PDF icon hover off color.png'
import GameImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/game icon hover off color.png'
import VideoImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/video icon hover off color.png'
import ImageImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/image icon hover off color.png'
import AudioImage from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/Audio icon hover off color.png'
import NextIcon from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/Next icon.png'
import BackIcon from '../../../../../assets/icons/ActivityContentIcons//Gamizign_Courses/back iconB.png'
import CreateNewQuestion from '../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/New Course icon.png'
import AlertIcon from '../../../../../assets/icons/Alert-icon.png'
import { Link } from "react-router-dom";
import Modal from 'react-modal'
import LoginHeading from "../../../../LoginHeading/LoginHeading";


const Gamizign = () => {
  // Grid and List view icon toggle
  const [currentImageGrid, setCurrentImageGrid] = useState(GridIconOff);
  const toggleImageView = () => {
    setCurrentImageGrid((prevImage) => (prevImage === GridIconOff ? GridIconOn : GridIconOff));
  };
  // Courses checkBox in or out icon toggle
  const [coursesCheckbox, setCoursesCheckbox] = useState(CheckBoxOn);
  const toggleCeoursesCheckbox = () => {
    setCoursesCheckbox((prevImage) => (prevImage === CheckBoxOn ? CheckBoxOff : CheckBoxOn));
  };
  // Activities check in or out icon toggle
  const [activitiesCheckbox, setActivitiesCheckbox] = useState(CheckBoxOn);
  const toggleActivitiesCheckbox = () => {
    setActivitiesCheckbox((prevImage) => (prevImage === CheckBoxOn ? CheckBoxOff : CheckBoxOn));
  };

  // Course Status icon toggle
  const [CourseIndex, setCourseIndex] = useState(0);
  const [CourseStatus, setCourseStatus] = useState(CourseLock);
  const toggleCourseStatus = () => {
    const updatatedcourses = [...filteredCourses];
    updatatedcourses[CourseIndex].unlock = !updatatedcourses[CourseIndex].unlock;
    setFilteredCourses(updatatedcourses);
    setCourseStatus((prevImage) => (prevImage === CourseLock ? CourseUnLock : CourseLock));
    setBotmodalIsOpen(false);
  };
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  var instructor_id = parseInt(localStorage.getItem("instructor_id"));

  useEffect(() => {
    const getCourses = async () => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      };


      try {
        setLoading(true);

        const response = await fetch(`https://gamizign.co:8000/instructors/${instructor_id}/courses`, requestOptions);
        const data = await response.json();

        setLoading(false);
        setCourses(data);
        setFilteredCourses(data);
      } catch (error) {
        console.error('Error fetching courses:', error);
        // Handle the error if needed
      } finally {
        setLoading(false);
      }
    };

    getCourses();
  }, []);
  // Search Courses
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');


  const handleSearch = (e) => {
    setSearchTerm(e.target.value);

    const lowercasedSearchTerm = e.target.value.toLowerCase();

    const filteredLists = courses.filter((courses) => {
      return courses.name.toLowerCase().includes(lowercasedSearchTerm);
    });

    setFilteredCourses(filteredLists);
  };
  // PopUp here Course Lock and unlock
  const [CourseName, setCourseName] = useState('');
  const [botmodalIsOpen, setBotmodalIsOpen] = useState(false);
  const openBotModal = () => {
    setBotmodalIsOpen(true);
  };
  const BotcloseModal = () => {
    setBotmodalIsOpen(false);
  };
  // Bg color of course
  const ActivityCourseColors = ['#DBE6F5', '#E5F2E3', '#F5F9E6', '#FEFAE2', '#FEF7E2', '#FEF1DD', '#FDE4DC', '#EFE1EE'];

  // Delete course Request to API
  const [courseId, setCourseId] = useState();
  const handleDeleteCourse = () => {
    const updatedCourses = filteredCourses.filter(course => course.course_id !== courseId);
    setFilteredCourses(updatedCourses);
    setCoursebotmodalIsOpen(false);
    var req = {
        "course_id": courseId,
        "instructor_id":instructor_id, 
    };
    fetch(`https://gamizign.co:8000/courses/${courseId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(req),
    })
      .then(response => {
        if (response.ok) {
        }
        else {
          throw new Error(`Failed to delete course ${courseId}`);
        }
      })
      .catch(error => {
        console.error('Error deleting course:', error);
      });
  };
  // hide course
  const handleHideCourse = (CourseIndex) => {
    // const updatedCourses = filteredCourses.filter(course => course.course_id !== course_id);
    const updatatedcourses = [...filteredCourses];
    // updatatedcourses.splice(CourseIndex, 1)
    updatatedcourses[CourseIndex].view = !updatatedcourses[CourseIndex].view;
    setFilteredCourses(updatatedcourses);
    // setCoursebotmodalIsOpen(false);
  };
  // Delete Course PopUp
  const [CoursebotmodalIsOpen, setCoursebotmodalIsOpen] = useState(false);
  const openCurseBotModal = () => {
    setCoursebotmodalIsOpen(true);
  };
  const CourseBotcloseModal = () => {
    setCoursebotmodalIsOpen(false);
  };
  // Delete Activity PopUp
  const [ActivitybotmodalIsOpen, setActivitybotmodalIsOpen] = useState(false);
  const openActivityBotModal = () => {
    setActivitybotmodalIsOpen(true);
  };
  const ActivityBotcloseModal = () => {
    setActivitybotmodalIsOpen(false);
  };
  // delete Activities
  const [courseIndex, setcourseIndex] = useState(0);
  const [stageIndex, setStageIndex] = useState(0);
  const [activityIndex, setActivityIndex] = useState(0);
  const [Activity_id, setActivity_id] = useState(0);
  const [ActivityName, setActivityName] = useState('');
  const deleteActivities = () => {
    setActivitybotmodalIsOpen(false);
    const updatedActivities = [...activities];
    updatedActivities.splice(activityIndex, 1);
    setActivities(updatedActivities)
    fetch(`https://gamizign.co:8000/activities/${Activity_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        // You might need to include additional headers like authorization token
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to delete activities');
        }
        return response.json();
      })
      .then(data => {
        // Handle the successful response data here
      })
      .catch(error => {
        console.error('Error deleting activities:', error);
        // Handle errors here
      });
  }
  // seprate array for Activites
  const [activities, setActivities] = useState([]);

  // Function to extract all activities from filteredCourses and set them in state
  const extractActivities = () => {
    const allActivities = filteredCourses.flatMap(course =>
      course.stages.flatMap(stage =>
        stage.activities.map(activity => ({ ...activity, stageName: stage.name, courseName: course.name , lerners: course.students.length}))
      )
    );
    setActivities(allActivities);
  };

  // Call extractActivities when filteredCourses changes or when needed
  useEffect(() => {
    extractActivities();
  }, [filteredCourses]);
  // players of each course
  const getUniqueStudentCount = (course) => {
    const uniqueStudentIds = new Set();

    course.stages.forEach((stage) => {
      stage.activities.forEach((activity) => {
        activity.analyticsList.forEach((analytics) => {
          uniqueStudentIds.add(analytics.student_id);
        });
      });
    });

    return uniqueStudentIds.size;
  };
  // Make array for Pigination  of Activities table
  const [currentPageactivities, setCurrentPage] = useState(1);
  const rowsPerPageactivities = 5;

  const indexOfLastactivities = currentPageactivities * rowsPerPageactivities;
  const indexOfFirstactivities = indexOfLastactivities - rowsPerPageactivities;
  const activitiesPigination = activities.slice(indexOfFirstactivities, indexOfLastactivities);

  const totalPagesactivities = Math.ceil(activities.length / rowsPerPageactivities);

  const handleNextPageactivities = () => {
    if (currentPageactivities < totalPagesactivities) {
      setCurrentPage(currentPageactivities + 1);
    }
  };

  const handlePrevPageactivities = () => {
    if (currentPageactivities > 1) {
      setCurrentPage(currentPageactivities - 1);
    }
  };

  // Make array for Pigination  of courses table
  const [currentPageCourses, setCurrentPageCourses] = useState(1);
  const rowsPerPageCourses = 5;

  const indexOfLastCourses = currentPageCourses * rowsPerPageCourses;
  const indexOfFirstCourses = indexOfLastCourses - rowsPerPageCourses;
  const CoursesPigination = filteredCourses.slice(indexOfFirstCourses, indexOfLastCourses);

  const totalPagesCourses = Math.ceil(filteredCourses.length / rowsPerPageCourses);

  const handleNextPageCourses = () => {
    if (currentPageCourses < totalPagesCourses) {
      setCurrentPageCourses(currentPageCourses + 1);
    }
  };

  const handlePrevPageCourses = () => {
    if (currentPageCourses > 1) {
      setCurrentPageCourses(currentPageCourses - 1);
    }
  };
  return (
    <div className='w-full'>
      <div className='flex px-5 py-2 mb-2 relative ContentHeadingBg'>
        <h3 className="text-xl">Gamizign</h3>
        <LoginHeading />
      </div>
      <div className="m-7 bg-white">
        {/* PopUp for Course Delete */}
        <Modal
          isOpen={CoursebotmodalIsOpen}
          onRequestClose={CourseBotcloseModal}
          className="Modal flex justify-center mt-40"
        // overlayClassName="Overlay"
        >
          {/* Modal Content */}
          <div className="flex sm:ms-20" >
            <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-green-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
              <div className="mt-5">
                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                <h1 className={`sm:text-2xl `}>Are you sure you want to <b> Delete </b> the course <b>{CourseName}</b>?</h1>
                <div className="flex justify-center gap-8 my-4">
                  <button onClick={handleDeleteCourse}>Yes</button>
                  <button onClick={CourseBotcloseModal}>No</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* PopUp for Activity Delete */}
        <Modal
          isOpen={ActivitybotmodalIsOpen}
          onRequestClose={ActivityBotcloseModal}
          className="Modal flex justify-center mt-40"
        // overlayClassName="Overlay"
        >
          {/* Modal Content */}
          <div className="flex sm:ms-20" >
            <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-green-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
              <div className="mt-5">
                <div className="flex justify-center"><img width={50} src={AlertIcon} alt="" /></div>
                <h1 className={`sm:text-2xl `}>Are you sure you want to <b> Delete </b> the Activity <b>{ActivityName} </b> From <b>{CourseName}</b> course?</h1>
                <div className="flex justify-center gap-8 my-4">
                  <button onClick={deleteActivities}>Yes</button>
                  <button onClick={ActivityBotcloseModal}>No</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div className='flex justify-center static'><img className='absolute top-6' src={GamizignHIcon} alt="" /></div>
        <div className="px-7 pt-10">
          <div className="flex py-2 relative px-3" style={{ borderBottom: "2px gray solid" }}>
            <p><b>Name</b></p>
            <div className="flex gap-3 absolute right-3">
              {/* <img src={Addfolder} alt="1" /> */}
              <img
                src={currentImageGrid}
                alt='Grid View'
                onClick={toggleImageView}
              />
              <img src={currentImageGrid === GridIconOff ? ListIconOn : ListIconOff} alt="3" onClick={toggleImageView} />
            </div>
          </div>
        </div>
        {/* Grid view courses */}
        <div className={`${currentImageGrid === GridIconOn ? 'hidden' : ''}`}>
          <div className="px-7 pt-3 sm:flex gap-5">
            <span><input id="searchInput" value={searchTerm} onChange={handleSearch} className="px-2 lg:px-5 sm:px-5 lg:w-80 py-2 border  rounded " type="text" placeholder="Search Course Name" style={{ width: '' }} /></span>
            <div className="flex mt-2 gap-3">
              <div className="flex"><img src={coursesCheckbox} onClick={toggleCeoursesCheckbox} alt="" style={{ height: '30px' }} /> <span className="ps-1 mt-0.5"><b>Courses</b></span></div>
              <div className="flex sm:mt-0"><img src={coursesCheckbox === CheckBoxOff ? CheckBoxOn : CheckBoxOff} alt="" onClick={toggleCeoursesCheckbox} style={{ height: '30px' }} /> <span className="ps-1 mt-0.5"><b>Activities</b></span></div>
            </div>
          </div>
          <div className=" grid grid-cols-1 px-5 mt-10 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40">
            {loading ? (
              <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                <svg
                  className="text-gray-300 animate-spin"
                  viewBox="0 0 64 64"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100">
                  <path
                    d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                    stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                  </path>
                  <path
                    d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                    stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                  </path>
                </svg>
              </div>
            ) : (
              filteredCourses && Array.isArray(filteredCourses) ? (
                filteredCourses.map((course, CourseIndex) => (
                  <div key={CourseIndex} className={` ${coursesCheckbox === CheckBoxOff ? 'hidden' : 'mt-5'}`}>
                    {course.view ? (
                      <div>
                        <p className="flex justify-center pt-20 pb-3 sm:text-sm"><b>{course.name}</b></p>
                        <p className="flex justify-center"><img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" /></p>
                      </div>
                    ) : (
                      <div>
                        <div className={`border relative ${course.view ? 'hidden' : ''}`} style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[CourseIndex % ActivityCourseColors.length] }}>
                          <div className="bg-gray-900 text-white  absolute inset-x-0 bottom-0" style={{ borderRadius: '20px', height: "" }}>
                            <div className="flex justify-center" ><img className="cursor-pointer" onClick={() => { openBotModal(); setCourseName(course.name); setCourseIndex(CourseIndex) }} src={course.unlock ? CourseUnLock : CourseLock} alt="" style={{ marginTop: "-25px" }} /></div>
                            <Link to={`/Course?course_id=${course.course_id}`}>
                              <p className="flex justify-center pt-5 pb-3 sm:text-sm text-center"><b>{course.name}</b></p>
                              <p className="flex justify-center mt-3 sm:text-sm"></p>
                            </Link>
                          </div>
                        </div>
                        <div className="flex gap-9 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                          <img className="cursor-pointer" onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="" />
                          <img src={EditQuestoin} alt="" />
                          <img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { openCurseBotModal(); setCourseName(course.name); setCourseId(course.course_id) }} />
                        </div>
                        <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                          <span className="relative"><img src={LearnerTotal} alt="" /><span className="absolute inset-0 left-2.5 top-0.5">{getUniqueStudentCount(course)}</span> </span>
                          <p>Learners</p>
                          <span className="relative"><img src={LearnerDone} alt="" /><span className="absolute inset-0 left-2.5 top-0.5">
                      {course.students.length}
                        </span> </span>
                        </div>
                        {/*bot modal course unlock */}
                        <Modal
                          isOpen={botmodalIsOpen}
                          onRequestClose={BotcloseModal}
                          className="Modal flex justify-center mt-40"
                        // overlayClassName="Overlay"
                        >
                          {/* Modal Content */}
                          <div className="flex sm:ms-20" >
                            <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-red-100 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                              <div className="mt-10">
                                <h1 className={`text-2xl ${course.unlock ? "hidden" : ""}`}>Are you sure you want to <b> activate </b> the course <b>{CourseName}</b>?</h1>
                                <h1 className={`text-2xl ${!course.unlock ? "hidden" : ""}`}>Are you sure you want to <b> Lock </b> the course <b>{CourseName}</b>?</h1>
                                <div className="flex justify-center gap-8 my-4">
                                  <button onClick={toggleCourseStatus}>Yes</button>
                                  <button onClick={BotcloseModal}>No</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </div>)}

                  </div>

                ))
              ) : (
                <div className="text-gray-500">No courses available.</div>
              )
            )}
          </div>
          <div className="grid grid-cols-1 px-5 mt-10 sm:grid sm:grid-cols-2 md:grid-cols-3  sm:px-5 xl:grid-cols-5 gap-5 2xl:px-40 ">
            {/* Activities of courses  Grid view*/}
            {activities && Array.isArray(activities) ? (activities.map((activity, activityIndex) => (
              <div className={` ${coursesCheckbox === CheckBoxOff ? 'mt-5' : 'hidden'}`}>
                <div className="border relative" style={{ borderRadius: '20px', height: "250px", backgroundColor: ActivityCourseColors[activityIndex % ActivityCourseColors.length] }}>
                  <div className={`flex justify-center mt-3 ${activity.activityType === "pdf" ? "" : "hidden"}`}><img src={PdfImage} alt="" /></div>
                  <div className={`flex justify-center mt-3 ${activity.activityType === "game" ? "" : "hidden"}`}><img src={GameImage} alt="" /></div>
                  <div className={`flex justify-center mt-3 ${activity.activityType === "video" ? "" : "hidden"}`}><img src={VideoImage} alt="" /></div>
                  <div className={`flex justify-center mt-3 ${activity.activityType === "image" ? "" : "hidden"}`}><img src={ImageImage} alt="" /></div>
                  <div className={`flex justify-center mt-3 ${activity.activityType === "audio" ? "" : "hidden"}`}><img src={AudioImage} alt="" /></div>
                  <div className="bg-gray-900 text-white  absolute inset-x-0 bottom-0" style={{ borderRadius: '20px', height: "100px" }}>
                    <div className="flex justify-center"><img src={CourseLock} alt="" style={{ marginTop: "-25px" }} /></div>
                    <p className="flex justify-center text-center	 pt-2 text-sm"><b>{activity.name}</b></p>
                    <p className="flex justify-center text-center	 mt-2 text-sm"><b>{activity.courseName}</b></p>
                    <p className="flex justify-center mt-2 text-sm"><b>{activity.stageName}</b></p>
                  </div>
                </div>
                <div className="flex gap-7 justify-center mt-2 border py-2" style={{ borderRadius: "10px" }}>
                  {/* <img src={CourseNotActive} alt="" /> */}
                  {/* <img src={EditQuestoin} alt="" /> */}
                  <img className="cursor-pointer" src={DeletQuestoin} alt="" onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); setcourseIndex(CourseIndex); setStageIndex(stageIndex); setActivityIndex(activityIndex); openActivityBotModal() }} />
                </div>
                <div className="flex gap-5 justify-center border py-2" style={{ borderRadius: "10px" }}>
                  <span className="relative"><img src={LearnerTotal} alt="" /><span className="absolute inset-0 left-2.5 top-0.5">{activity.analyticsList.length}</span> </span>
                  <p>Learners</p>
                  <span className="relative"><img src={LearnerDone} alt="" /><span className="absolute inset-0 left-2.5 top-0.5">{activity.lerners}</span> </span>
                </div>
              </div>
            ))) : (<div className="text-gray-500">No activity available.</div>)}
          </div>
        </div>
        {/* List view courses */}
        <div className={`${currentImageGrid === GridIconOff ? 'hidden' : ''}`}>
          <div className="px-7 pt-3 sm:flex gap-5">
            <div>
              <span><input id="searchInput" value={searchTerm} onChange={handleSearch} className="px-2 sm:px-5 py-2 border  rounded lg:w-80" type="text" placeholder="Search Course Name" style={{ width: '' }} /></span>
            </div>
            <div className="flex mt-2 gap-3 sm:gap-10">
              <div className="flex "><img src={coursesCheckbox} onClick={toggleCeoursesCheckbox} alt="" style={{ height: '30px' }} /> <span className="ps-1 mt-0.5"><b>Courses</b></span></div>
              <div className="flex "><img src={coursesCheckbox === CheckBoxOff ? CheckBoxOn : CheckBoxOff} alt="" onClick={toggleCeoursesCheckbox} style={{ height: '30px' }} /> <span className="ps-1 mt-0.5"><b>Activities</b></span></div>
            </div>
          </div>
          <div className="gap-4  mt-5">
            {/* courses List view*/}
            <div className={`${coursesCheckbox === CheckBoxOff ? 'hidden' : ''}`}>
              {loading ? (
                <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                  <svg
                    className="text-gray-300 animate-spin"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100">
                    <path
                      d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                      stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                    </path>
                    <path
                      d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                      stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                    </path>
                  </svg>
                </div>
              ) :
                (
                  <div className={`relative mx-2 sm:mx-7 overflow-x-auto max-w-80 sm:max-w-full`}>
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" class="">
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Learners
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Completed
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Date Created
                          </th>
                          <th scope="col" class="">
                          </th>
                        </tr>
                      </thead>
                      <tbody>

                        {CoursesPigination && Array.isArray(CoursesPigination) ?
                          CoursesPigination.map((course, CourseIndex) => (
                            <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                              <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {course.name}
                              </th>
                              <td class="px-3 py-6">
                                {course.students.length}
                              </td>
                              <td class="px-3 py-6">
                                {getUniqueStudentCount(course)}
                              </td>
                              <td class="px-3 py-6">
                                {course.dateCreated}
                              </td>
                              <td class="px-3 py-6">
                                <div className="flex justify-center">
                                  <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                                    <Link to=''><img onClick={() => { setCourseName(course.name); handleHideCourse(CourseIndex) }} src={course.view ? CourseNotActive : CourseActive} alt="pdf" /></Link>
                                    <Link to={`/Course?course_id=${course.course_id}`}>
                                      <img src={EditQuestoin} alt="edit" />
                                    </Link>
                                    <span className="cursor-pointer" onClick={() => { openCurseBotModal(); setCourseName(course.name); setCourseId(course.course_id) }}><img src={DeletQuestoin} alt="delete" /></span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))

                          : (<div className="text-gray-500">No Course available.</div>)}

                      </tbody>
                    </table>
                    {/* courses pigination Next and prev page*/}
                    <div className={`flex justify-center gap-5 mt-4 ${coursesCheckbox === CheckBoxOff ? 'hidden' : ''}`}>
                      <button onClick={handlePrevPageCourses} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPageCourses === 1}>
                        <img className="mt-1 mr-2" src={BackIcon} alt="" /> Prev
                      </button>
                      <span className="px-4 py-2">{currentPageCourses} of {totalPagesCourses}</span>
                      <button onClick={handleNextPageCourses} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPageCourses === totalPagesCourses}>
                        Next <img className="mt-1 ms-2" src={NextIcon} alt="" />
                      </button>
                    </div>
                  </div>
                )}
            </div>
            {/* Activities of courses List view*/}
            <div className={`${coursesCheckbox === CheckBoxOff ? '' : 'hidden'}`}>
              {loading ? (
                <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                  <svg
                    className="text-gray-300 animate-spin"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100">
                    <path
                      d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                      stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                    </path>
                    <path
                      d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                      stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                    </path>
                  </svg>
                </div>
              ) :
                (
                  <div className={`relative  ms-2 sm:mx-7 overflow-x-auto max-w-80 sm:max-w-full`}>
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                      <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" class="">
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Course
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Stage
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Learners
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Completed
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Created By
                          </th>
                          <th scope="col" class="px-3 py-3">
                            Date Created
                          </th>
                          <th scope="col" class="">
                          </th>
                        </tr>
                      </thead>
                      <tbody>

                        {activitiesPigination && Array.isArray(activitiesPigination) ?
                          activitiesPigination.map((activity, activityIndex) => (
                            <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                              <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {activity.name}
                              </th>
                              <td class="px-3 py-6">
                                {activity.courseName}
                              </td>
                              <td class="px-3 py-6">
                                {activity.stageName}
                              </td>
                              <td class="px-3 py-6">
                                {activity.lerners}
                              </td>
                              <td class="px-3 py-6">
                                {activity.analyticsList.length}
                              </td>
                              <td class="px-3 py-6">
                                {activity.createdBy}
                              </td>
                              <td class="px-3 py-6">
                                {activity.dateCreated}
                              </td>
                              <td class="px-3 py-6">
                                <div className="flex justify-center">
                                  <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                                    <span className="cursor-pointer" onClick={() => { setActivityName(activity.name); setActivity_id(activity.activity_id); setcourseIndex(courseIndex); setStageIndex(stageIndex); setActivityIndex(activityIndex); openActivityBotModal() }}><img src={DeletQuestoin} alt="delete" /></span>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))

                          : (<div className="text-gray-500">No Activities available.</div>)}

                      </tbody>
                    </table>
                    {/* Activiies Pigination Next and prev page*/}
                    <div className={`flex justify-center gap-5 mt-4 ${coursesCheckbox === CheckBoxOff ? '' : 'hidden'}`}>
                      <button onClick={handlePrevPageactivities} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPageactivities === 1}>
                        <img className="mt-1 mr-2" src={BackIcon} alt="" /> Prev
                      </button>
                      <span className="px-4 py-2">{currentPageactivities} of {totalPagesactivities}</span>
                      <button onClick={handleNextPageactivities} className="px-4 py-2 BackBtn hover:bg-blue-200 rounded disabled:opacity-50 flex" disabled={currentPageactivities === totalPagesactivities}>
                        Next <img className="mt-1 ms-2" src={NextIcon} alt="" />
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <Link to='/Dashboard/CreateCourse'> <button className=" border py-2 px-6 my-10 font-bold flex BackBtn hover:bg-blue-200" style={{ borderRadius: '7px' }}>
            <img src={CreateNewQuestion} alt="AddNewQ" style={{ width: '30px', paddingRight: '5px' }} />
            Create New Course</button> </Link>
        </div>
      </div>
    </div>
  )
};

export default Gamizign;
