import React from "react";
import LoginHeading from "../../../../LoginHeading/LoginHeading";


const Rewards = () => {

    return (
        <div className='w-full'>
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Rewards</h3>
                <LoginHeading/>
                </div>
            <div className="m-7 bg-white contentSide">
                <h1 className="text-3xl">Rewards that you Got</h1>
            </div>

        </div>
    )
};

export default Rewards;