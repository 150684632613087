import React, { useEffect, useState } from "react";
import LoginHeading from "../../../../../../LoginHeading/LoginHeading";
import GamizignHIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Gamizign head icon.png'
import PdfImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/PDF icon hover off color.png'
import GameImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/game icon hover off color.png'
import VideoImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/video icon hover off color.png'
import AudioImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/Audio icon hover off color.png'
import ImageImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/image icon hover off color.png'
import UrlImage from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/URL icon hover off color.png'
import HideIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/Next icon.png'
import BackIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/back iconB.png'
import DeletIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/delete icon.png'
import EditIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/edit icon.png'
import EyeIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/course active icon.png'
import StarIconOn from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/star for rank icon on.png'
import StarIconOff from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/star for rank off icon.png'
import RadioOnIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/radio button on.png'
import RadioOffIcon from '../../../../../../../assets/icons/ActivityContentIcons/Gamizign_Courses/Course/HomeWork/radio button off.png'
import { Link, useLocation, useNavigate } from "react-router-dom";

// Function to combine students and activity answers
const addStudentInfoToActivityAnswers = (students, activityAnswers) => {
    return activityAnswers
        .map(answer => {
            const matchingStudent = students.find(student => student.student_id === answer.student_id);
            if (matchingStudent) {
                return {
                    ...answer,
                    firstName: matchingStudent.firstName,
                    lastName: matchingStudent.lastName
                };
            }
            // If no matching student, exclude the activity answer
            return null;
        })
        .filter(answer => answer !== null); // Filter out any null values
};;

const ActivityEvaluation = () => {
    const { activity, Course_name, Students, course_id } = useLocation().state;
    const [ActivityAnswers, setActivityAnswers] = useState(null);
    const [updatedActivityAnswers, setUpdatedActivityAnswers] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getActivityAnswers = async () => {
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };

            try {
                setLoading(true);

                const response = await fetch(`https://gamizign.co:8000/activities/${activity.activity_id}/answers/`, requestOptions);
                const data = await response.json();
                setLoading(false);
                setActivityAnswers(data);
            } catch (error) {
                console.error('Error fetching courses:', error);
                // Handle the error if needed
            } finally {
                setLoading(false);
            }
        };
        getActivityAnswers();
    }, []);
    useEffect(() => {
        if (!loading) {
            const updatedAnswers = addStudentInfoToActivityAnswers(Students, ActivityAnswers, activity);
            setUpdatedActivityAnswers(updatedAnswers);
            setFilteredLearners(updatedAnswers);
        }
    }, [loading, ActivityAnswers, Students]);


    const [activityType, setActivityType] = useState(null)
    const AddingActivityType = () => {
        switch (activity.activityType.toLowerCase()) {
            case "pdf":
                setActivityType(PdfImage);
                break
            case 'image':
                setActivityType(ImageImage);
                break
            case 'video':
                setActivityType(VideoImage);
                break
            case 'audio':
                setActivityType(AudioImage);
                break
            case 'game':
                setActivityType(GameImage);
                break
            case 'url':
                setActivityType(UrlImage);
                break
            default:
                setActivityType(ImageImage);
        }

    };
    useEffect(() => {
        AddingActivityType();
    }, [activity]);
    const [activityDescription, setActivityDescription] = useState(false)
    const ActivityDescription = () => {
        setActivityDescription((prevImage) => (prevImage === false ? true : false));
    };
    const [modelAnswer, setModelAnswer] = useState(false)
    const ModelAnswer = () => {
        setModelAnswer((prevImage) => (prevImage === false ? true : false));
    };
    // Search learners
    const [filteredLearners, setFilteredLearners] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');


    const handleSearch = (e) => {
        setSearchTerm(e.target.value);

        const lowercasedSearchTerm = e.target.value.toLowerCase();

        const filteredLists = updatedActivityAnswers.filter((courses) => {
            return courses.firstName.toLowerCase().includes(lowercasedSearchTerm);
        });

        setFilteredLearners(filteredLists);
    };
    // View Activity File
    const [ViewActivityFile, setViewActivityFile] = useState(false)
    const ViewActivityFileHandle = (activityType, url) => {
        setViewActivityFile((prevImage) => (prevImage === false ? true : false));
        if (activityType === 'url') {
            window.open(url, '_blank');
        }
    };

    const navigate = useNavigate();
    const sendDatLernerpage = (learner) => {
        navigate(`/Course/LearnerEvaluation?course_id=${course_id}`, { state: { activity: activity, Course_name: Course_name, Students: Students, course_id: course_id, learner: learner } })
    };

    return (
        <div className='w-full'>
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                <h3 className="text-xl">Students Homework</h3>
                <LoginHeading />
            </div>
            <div className="m-7 bg-white border-4 pb-5">
                <div className='flex justify-center static'><img className='absolute top-6' src={GamizignHIcon} alt="" /></div>
                <div className="p-5  bg-gray-50 border-b font-bold text-xl">{Course_name} / {activity.name}</div>
                <div className="ms-14 me-20 mt-10 flex relative">
                    <label htmlFor="Name of Course"><b>Title</b>
                        <span className="border py-3 px-5 rounded w-80 ms-2">{activity.name}</span>
                    </label>
                    <label className="flex-1 text-right" htmlFor="Starting Date"> <b>Date Created </b>
                        <span className="text-blue-600 ms-2">{activity.dateCreated}</span>
                    </label>
                </div>
                <div className="mx-20 mt-5">
                    <div className="flex">
                        <span className="mt-7">1. View activity</span>
                        <span className="ms-5 cursor-pointer"><img onClick={()=>ViewActivityFileHandle(activity.activityType , activity.url)} src={activityType} alt="" /></span>
                        {ViewActivityFile &&
                            <div> {activity.activityType != 'url' && <div>
                                <iframe width='500px' src={`https://gamizign.co:8000/uploads/${activity.activity_file_name}`} frameborder="0"></iframe>
                                <Link className="font-bold cursor-pointer mt-2 BackBtn flex p-2 px-7 hover:bg-blue-200" target="_blank" to={`https://gamizign.co:8000/uploads/${activity.activity_file_name}`}>View Full </Link>
                            </div>}
                            </div>
                        }
                    </div>
                    {/* activity question/description */}
                    <div className="flex mt-5">
                        <label className="mt-2.5 w-56" htmlFor="">2. The activity description </label>
                        <div className="flex-1 text-center">
                            {activityDescription === false ? (
                                <button onClick={ActivityDescription} className='BackBtn ms-5 flex p-2 px-7 hover:bg-blue-200'>show<img className='ml-5 mt-1 rotate-90' src={HideIcon} alt="Back" /></button>
                            ) : (
                                <button onClick={ActivityDescription} className='bg-green-50 ms-5 rounded border flex p-2 px-7 hover:bg-blue-200'>Hide<img className='ml-5 mt-1 rotate-90' src={BackIcon} alt="Back" /></button>
                            )}
                        </div>
                    </div>
                    {activityDescription &&
                        <div>
                            <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex justify-end">
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='Task' value={activity.activity_case}>
                                    <span>
                                        <img
                                            src={activity.activity_case === 'Task' ? RadioOnIcon : RadioOffIcon}
                                            alt="Task"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.activity_case === 'Task' ? 'font-bold' : 'font-light'}`}>Task</span>
                                </div>
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='Project' value={activity.activity_case}>
                                    <span>
                                        <img
                                            src={activity.activity_case === 'Project' ? RadioOnIcon : RadioOffIcon}
                                            alt="Project"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.activity_case === 'Project' ? 'font-bold' : 'font-light'}`}>Project</span>
                                </div>
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='Case' value={activity.activity_case}>
                                    <span>
                                        <img
                                            src={activity.activity_case === 'Case' ? RadioOnIcon : RadioOffIcon}
                                            alt="Case"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.activity_case === 'Case' ? 'font-bold' : 'font-light'}`}>Case</span>
                                </div>
                            </div>
                            <textarea className='w-full border p-5' value={activity.description} disabled id="ActivityAnswer" name="ActivityAnswer" placeholder='Text ....' rows="4" cols="95" ></textarea>
                        </div>
                    }
                    {/* Model Answer */}
                    <div className="flex mt-5">
                        <label className="mt-2.5 w-56" htmlFor="">3. Model answer </label>
                        <div className="flex-1 text-center">
                            {modelAnswer === false ? (
                                <button onClick={ModelAnswer} className='BackBtn ms-5 flex p-2 px-7 hover:bg-blue-200'>show<img className='ml-5 mt-1 rotate-90' src={HideIcon} alt="Back" /></button>
                            ) : (
                                <button onClick={ModelAnswer} className='bg-green-50 ms-5 rounded border flex p-2 px-7 hover:bg-blue-200'>Hide<img className='ml-5 mt-1 rotate-90' src={BackIcon} alt="Back" /></button>
                            )}
                        </div>
                    </div>
                    {modelAnswer &&
                        <div>
                            <div className="QUESTIONS-COUNT flex-initial w-100 py-2 flex justify-end">
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='is exactly' value={activity.answer_exact}>
                                    <span>
                                        <img
                                            src={activity.answer_exact === 'is exactly' ? RadioOnIcon : RadioOffIcon}
                                            alt="Task"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.answer_exact === 'is exactly' ? 'font-bold' : 'font-light'}`}>is exactly</span>
                                </div>
                                <div className="image-radio flex m-2 hover:cursor-pointer" name='contain' value={activity.answer_exact}>
                                    <span>
                                        <img
                                            src={activity.answer_exact === 'contain' ? RadioOnIcon : RadioOffIcon}
                                            alt="Project"
                                            className="radio-image mt-1"
                                        />
                                    </span>
                                    <span className={`ps-2 ${activity.answer_exact === 'contain' ? 'font-bold' : 'font-light'}`}>contain</span>
                                </div>
                            </div>
                            <textarea className='w-full border p-5' value={activity.answer_requirement} disabled id="ActivityAnswer" name="ActivityAnswer" placeholder='Text ....' rows="4" cols="95" ></textarea>
                        </div>
                    }
                    {/* Students Answers */}
                    <div className="flex mt-5">
                        <label className="w-56 mt-2.5" htmlFor="">4. Student answer</label>
                        <div className="flex flex-wrap gap-5">
                            <button className="p-2 bg-gray-100 border rounded">{ActivityAnswers?.length > 0 ? ActivityAnswers.length : 0}/{Students.length} done</button>
                            <button className="p-2 bg-gray-100 border rounded">{ActivityAnswers?.length > 0 ? Students.length - ActivityAnswers.length : Students.length}/{Students.length} Need your check</button>
                            {/* <button className="p-2 bg-gray-100 border rounded">10/25 Un completed</button> */}
                        </div>

                    </div>
                    {/* Learners table */}
                    <div className="border-y-4 py-5 my-10">
                        <div class="relative overflow-x-auto max-w-100">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead class="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" class="">
                                            <input type="text" placeholder="Search Learner Name" className="border rounded p-2 ms-5 outline-none w-60" value={searchTerm} onChange={handleSearch} name="" id="" />
                                        </th>
                                        <th scope="col" class="px-3 py-3">
                                            <b>Respond Date</b>
                                        </th>
                                        <th scope="col" class="px-3 py-3">

                                        </th>
                                        {/* <th scope="col" class="px-3 py-3">

                                        </th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading ? (
                                        <div className="grid min-h-[140px] w-full place-items-center overflow-x-scroll rounded-lg p-6 lg:overflow-visible">
                                            <svg
                                                className="text-gray-300 animate-spin"
                                                viewBox="0 0 64 64"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="100"
                                                height="100">
                                                <path
                                                    d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
                                                    stroke="#b6dff7" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round">
                                                </path>
                                                <path
                                                    d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
                                                    stroke="#FFFFFF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" className="text-gray-900">
                                                </path>
                                            </svg>
                                        </div>
                                    ) :
                                        (filteredLearners && Array.isArray(filteredLearners)) ?
                                            filteredLearners.map((Learner, index) => (
                                                <tr class="even:bg-white even:dark:bg-gray-900 odd:bg-gray-50 odd:dark:bg-gray-800 border-b dark:border-gray-700">
                                                    <th scope="row" class="px-3 py-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                                        <p className="cursor-pointer" onClick={() => sendDatLernerpage(Learner)}>{Learner.firstName} {Learner.lastName}</p>
                                                    </th>
                                                    <td class="px-3 py-6">
                                                        {Learner.answerTime.replace(/[-,]/g, '/')}
                                                    </td>
                                                    <td class="px-3 py-6">
                                                        <div className={`flex flex-wrap gap-2 ${Learner.evaluation === null ? '' : 'hidden'}`}>
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                        </div>
                                                        <div className={`flex flex-wrap gap-2 ${Learner.evaluation === '1' ? '' : 'hidden'}`}>
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                        </div>
                                                        <div className={`flex flex-wrap gap-2 ${Learner.evaluation === '2' ? '' : 'hidden'}`}>
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                        </div>
                                                        <div className={`flex flex-wrap gap-2 ${Learner.evaluation === '3' ? '' : 'hidden'}`}>
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                        </div>
                                                        <div className={`flex flex-wrap gap-2 ${Learner.evaluation === '4' ? '' : 'hidden'}`}>
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOff} alt="" />
                                                        </div>
                                                        <div className={`flex flex-wrap gap-2 ${Learner.evaluation === '5' ? '' : 'hidden'}`}>
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                            <img src={StarIconOn} alt="" />
                                                        </div>
                                                    </td>
                                                    {/* <td class="px-3 py-6">
                                                        <div className="flex justify-center gap-2  p-2" style={{ width: '120px', borderRadius: '8px' }}>
                                                            <img src={EyeIcon} alt="pdf" />
                                                            <img src={EditIcon} alt="edit" />
                                                            <span className="cursor-pointer" ><img src={DeletIcon} alt="delete" /></span>
                                                        </div>
                                                    </td> */}
                                                </tr>
                                            )

                                            ) : (<div className="text-gray-500 font-bold text-2xl flex justify-center">No one player Submitted answers.</div>)}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
                <div className="flex justify-center">
                    <Link to={`/Course/HomeWork?course_id=${course_id}`}><button className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button></Link>
                </div>
            </div>
        </div>
    )
};
export default ActivityEvaluation;