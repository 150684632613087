import React from "react";
import LoginHeading from "../../../../LoginHeading/LoginHeading";


const Users = () => {

    return (
        <div className='w-full'>
            <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
            <h3 className="text-xl">Users</h3>
            <LoginHeading/>
            </div>
            <div className="m-7 bg-white contentSide">
                <h1 className="text-3xl">Our happy Clients</h1>
            </div>
        </div>
    )
};

export default Users;