import React, { useState } from 'react';
import './style.css';
import IconGamizignWorld from '../../../assets/icons/left panel icons/The Gamizign World off icon.png'
import IconGamizignWorldOn from '../../../assets/icons/left panel icons/HoverState/The Gamizign World on icon.png'
// Normal black State Sidebar Icons 
import IconNewActivity from '../../../assets/icons/left panel icons/NormalState/New Activity off icon.png'
import IconNewActivityOn from '../../../assets/icons/left panel icons/HoverState/New Activity on icon.png'
import IconQuestionList from '../../../assets/icons/left panel icons/NormalState/Question Lists off icon.png'
import IconQuestionListOn from '../../../assets/icons/left panel icons/HoverState/Question Lists on icon.png'
import IconGamizign from '../../../assets/icons/left panel icons/NormalState/Gamizign off icon.png'
import IconGamizignOn from '../../../assets/icons/left panel icons/HoverState/Gamizign on icon.png'
import IconInsights from '../../../assets/icons/left panel icons/NormalState/Insights icon off.png'
import IconInsightsOn from '../../../assets/icons/left panel icons/HoverState/Insights icon on.png'
// import IconInstructor from '../../../assets/icons/left panel icons/NormalState/Instructors icon off.png'
// import IconInstructorOn from '../../../assets/icons/left panel icons/HoverState/Instructors icon on .png'
// import IconLearnerList from '../../../assets/icons/left panel icons/NormalState/Learner Lists icon off.png'
// import IconLearnerListOn from '../../../assets/icons/left panel icons/HoverState/Learner Lists icon on.png'
// import IconUsers from '../../../assets/icons/left panel icons/NormalState/Users icon off.png'
// import IconUsersOn from '../../../assets/icons/left panel icons/HoverState/Users icon on.png'
// import IconRewards from '../../../assets/icons/left panel icons/NormalState/Rewards off icon.png'
// import IconRewardsOn from '../../../assets/icons/left panel icons/HoverState/Rewards icon on.png'
// import IconSettings from '../../../assets/icons/left panel icons/NormalState/Settings icon off.png'
// import IconSettingsOn from '../../../assets/icons/left panel icons/HoverState/Settings icon on.png'
// import IconHelp from '../../../assets/icons/left panel icons/NormalState/Help icon off.png'
// import IconHelpOn from '../../../assets/icons/left panel icons/HoverState/Help icon on.png'
import LogoPic from '../../../assets/images/Gamizign-logo.png'
// import Activity from './sidbarOptionsContent/Activity/activity';
// import NewCourse from './sidbarOptionsContent/Activity/Newcourse/newCourse';
import { Link, Outlet } from 'react-router-dom';
// import LoginHeading from '../../LoginHeading/LoginHeading';




const Dashboard = () => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedTabName, setSelectedTabName] = useState('The Gamizign World');
  const menuItems = [
    { id: 11, icon: IconGamizignWorld, iconOn: IconGamizignWorldOn, label: 'The Gamizign World', style: 'BgcolorOf4 font-bold text-xl', link: '/Dashboard' },
    { id: 1, icon: IconNewActivity, iconOn: IconNewActivityOn, label: 'New Activity', style: 'BgcolorOf4', link: '/Dashboard/CreateCourse' },
    { id: 2, icon: IconQuestionList, iconOn: IconQuestionListOn, label: 'Question Lists', style: 'BgcolorOf4', link: '/Dashboard/questionList' },
    { id: 3, icon: IconGamizign, iconOn: IconGamizignOn, label: 'Gamizign', style: 'BgcolorOf4', link: '/Dashboard/Courses' },
    { id: 4, icon: IconInsights, iconOn: IconInsightsOn, label: 'Insights', style: '', link: '/Dashboard/insights' },
    // { id: 5, icon: IconInstructor, iconOn: IconInstructorOn, label: 'Instructors', style: '', link: '/Dashboard/instructors' },
    // { id: 6, icon: IconLearnerList, iconOn: IconLearnerListOn, label: 'Learner List', style: '', link: '/Dashboard/learnerList' },
    // { id: 7, icon: IconUsers, iconOn: IconUsersOn, label: 'Users', style: '', link: '/Dashboard/users' },
    // { id: 8, icon: IconRewards, iconOn: IconRewardsOn, label: 'Rewards', style: '', link: '/Dashboard/rewards' },
    // { id: 9, icon: IconSettings, iconOn: IconSettingsOn, label: 'Settings', style: '', link: '/Dashboard/settings' },
    // { id: 10, icon: IconHelp, iconOn: IconHelpOn, label: 'Help', style: '', link: '/Dashboard/help' },
  ];

  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleTabClick = (tabId, tabLabel) => {
    setSelectedTab(tabId);
    // setSelectedTabName(tabLabel);
  };

  return (
    <div>
      <div className="flex">
        {/* Sidebar Tabs*/}
        <div className="SIDEMENU flex-none FixedWidth256 hidden lg:block">
          <div className="SidbarBgColor textcolor">
            <div className="mb-8">
              {/* <div className='flex py-2 px-4 BgcolorOf4'><img className='mr-2' src={IconGamizignWorld} alt="" /><h3 className='font-bold text-xl'>The Gamizign World</h3>
              </div> */}
              {menuItems.map((item) => (
                <div className={item.style}>
                  <Link to={item.link}> <div
                    key={item.id}
                    className={`flex items-center py-2 px-4 border cursor-pointer hover:bg-blue-100 ${selectedTab === item.id ? 'SlectTabColor' : ''}`}
                    onClick={() => handleTabClick(item.id, item.label)}>
                    <div className="mr-2"><img src={selectedTab === item.id ? item.iconOn : item.icon} alt="" /></div>
                    <span>{item.label}</span>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
            <div className=" justify-center items-center">
              {/*Sidebar Footer */}
              <div className='ml-4 mb-7'>
                <img src={LogoPic} alt="Logo" className="w-20 h-20 mr-2" />
                <Link to='https://gamizign.com/privacy-policy/'><p className='font-bold'>Privacy Policy & Terms of Use</p></Link>
                <p>Subscription Agreement</p>
              </div>

            </div>
          </div>
          {/* Content Area of Sidebar Tabs*/}
          {/* <div className="flex-grow">
        <div className='px-5 py-2 mb-2 ContentHeadingBg'><h3 className="text-xl  ">{selectedTabName}</h3></div>
        <div className="flex  m-7 ContentAreaBg">
          {headerOptions[selectedTab] &&
            headerOptions[selectedTab].map((option, index) => (
              <div key={index} className="px-4 py-2 rounded">
                {option}
              </div>
            ))}
        </div>
      </div> */}
        </div>

        <div className='MAINMENU flex-1'>
          <Outlet></Outlet>
        </div>
        <div className="HAMBURGER-ICON lg:hidden absolute top-0 right-0 px-3 py-3" onClick={() => setIsNavOpen((prev) => !prev)}>
          <span className="block h-0.5 w-8 bg-gray-600"></span>
          <span className="block h-2 w-8"></span>
          <span className="block h-0.5 w-8 bg-gray-600"></span>
          <span className="block h-2 w-8"></span>
          <span className="block h-0.5 w-8 bg-gray-600"></span>
        </div>
        <div className="MOBILE-MENU">
          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div className="CLOSE-ICON absolute top-0 right-0 px-3 py-2" onClick={() => setIsNavOpen(false)}>
              <svg
                className="h-8 w-8 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <div className="MENU-ITEMS absolute top-10 w-full">
              {menuItems.map((item) => (
                <div className={item.style}>
                  <Link to={item.link}> <div
                    key={item.id}
                    className={`flex items-center py-2 px-4 border cursor-pointer hover:bg-blue-100 ${selectedTab === item.id ? 'SlectTabColor' : ''}`}
                    onClick={() => {
                      handleTabClick(item.id, item.label);
                      setIsNavOpen(false);
                    }}>
                    <div className="mr-2"><img src={selectedTab === item.id ? item.iconOn : item.icon} alt="" /></div>
                    <span>{item.label}</span>
                  </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <style>{`
                .hideMenuNav {
                  display: none;
                }
                .showMenuNav {
                  display: block;
                  position: absolute;
                  width: 100%;
                  height: 100vh;
                  top: 0;
                  left: 0;
                  background: white;
                  z-index: 10;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  align-items: center;
                }
            `}</style>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;