import React, { useState, useEffect } from 'react';
import '../style.css';
import DragDropIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Drag _ Drop icon.png'
import UploadIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Upload a File icon.png'
import LibraryIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Library icon.png'
import BackIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/back iconB.png'
import NextIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/Next icon.png'
import SelectedIcon from '../../../../../../assets/icons/ActivityContentIcons/NewCourse/select icon for poster.png'
import { Link, useNavigate } from 'react-router-dom';
import LoginHeading from '../../../../../LoginHeading/LoginHeading';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uploadFile from './activityType';
import Modal from 'react-modal'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

// Libarary images take from endpoint
const itemData = [
    {
        img: "https://gamizign.co:8000/uploads/art.png",
        title: 'art.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/board.png"
        ,
        title: 'board.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/boat.png",
        title: 'boat.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/butterfly.png",
        title: 'butterfly.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/cat.png",
        title: 'cat.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/cat2.png",
        title: 'cat2.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/cheer.png",
        title: 'cheer.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/circuit.png",
        title: 'circuit.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/conference.png",
        title: 'conference.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/dog.png",
        title: 'dog.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/dog2.png",
        title: 'dog2.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/English.png",
        title: 'English.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/focusing.png",
        title: 'focusing.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/Gamizingsplash.png",
        title: 'Gamizingsplash.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/grass2.png",
        title: 'grass2.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/hamster.png",
        title: 'hamster.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/hamster2.png",
        title: 'hamster2.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/hearts.png",
        title: 'hearts.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/howdy.png",
        title: 'howdy.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/island.png",
        title: 'island.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/island2.png",
        title: 'island2.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/leaves.png",
        title: 'leaves.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/maze.png",
        title: 'maze.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/mountain.png",
        title: 'mountain.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/mushroom.png",
        title: 'mushroom.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/nature.jpg",
        title: 'nature.jpg',
    },
    {
        img: "https://gamizign.co:8000/uploads/nature.png",
        title: 'nature.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/ocean.png",
        title: 'ocean.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/owl.png",
        title: 'owl.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/owl2.png",
        title: 'owl2.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/paint2.jpg",
        title: 'paint2.jpg',
    },
    {
        img: "https://gamizign.co:8000/uploads/paints.jpg",
        title: 'paints.jpg',
    },
    {
        img: "https://gamizign.co:8000/uploads/paints3.jpg",
        title: 'paints3.jpg',
    },
    {
        img: "https://gamizign.co:8000/uploads/pink.png",
        title: 'pink.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/rain.png",
        title: 'rain.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/rainy.png",
        title: 'rainy.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/seed.png",
        title: 'seed.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/seed2.png",
        title: 'seed2.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/spring.png",
        title: 'spring.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/squirrll.png",
        title: 'squirrll.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/student.png",
        title: 'student.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/students&books.png",
        title: 'students&books.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/trees.png",
        title: 'trees.png',
    },
    {
        img: "https://gamizign.co:8000/uploads/wave.png",
        title: 'wave.png',
    },
];
const CoverImage = () => {
    const inst_id = localStorage.getItem("instructor_id");
    const inst_name = localStorage.getItem("name");
    const [selectedFile, setSelectedFile] = useState(null);
    const [Name, setName] = useState('');
    const [date, setDate] = useState(new Date());
    const [courseFormatDate, setcourseFormatDate] = useState(new Date());
    const [Grade, setGrade] = useState('');
    const [classes, setClasses] = useState();
    const [MainTopic, setMainTopic] = useState('');
    const [background, setbackground] = useState('');
    const [uploading, setUploading] = useState(false);
      // Libarary
    const [ImageTitle, setImageTitle] = useState('');


    // Input validations
    const [nameError, setNameError] = useState('');
    const [gradeError, setGradeError] = useState('');
    const [mainTopicError, setMainTopicError] = useState('');

    const handleNameChange = (e) => {
        setName(e.target.value);
        setNameError('');
        setCourseDetail((prev) => ({ ...prev, Title: e.target.value }));
    };

    const handleGradeChange = (e) => {
        setGrade(e.target.value);
        setGradeError('');
        setCourseDetail((prev) => ({ ...prev, Grade: e.target.value }));
    };

    const handleMainTopicChange = (e) => {
        setMainTopic(e.target.value);
        setMainTopicError('');
        setCourseDetail((prev) => ({ ...prev, MainTopic: e.target.value }));
    };
    const getClasses = () =>{
        var organization = parseInt(localStorage.getItem('organization'));
        fetch(`https://gamizign.co:8000/organization/${organization}/classes/`).then((response)=>{return response.json()}).then((data)=>{
            setClasses(data.classes);
        });
    }

    const uploadFile = async (file) =>{
        return new Promise((resolve, reject) => {
            
            // Prepare the request
            const url = 'https://gamizign.co:8000/attachment?type=activity';
            const formData = new FormData();
            formData.append('file', file);
            const req = {
                method: 'POST',
                body: formData,
            };

            // Send the request
            fetch(url, req)
            .then(function(response) {
                if (response.ok) {
                    return response.json(); // Extract the file name from the response
                } else {
                    setUploading(false);
                    throw new Error('Error uploading file');
                }
            })
            .then(data => {
                setUploading(false);
                resolve(data.message); // Resolve with the file name
            })
            .catch(error => {
                setUploading(false);
                reject(error);
            });
        });
    };
    // validation and navigate 
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        e.preventDefault();
        let hasError = false;
        let fileName = background;

        // Perform validation
        if (!Name) {
            setNameError('Please fill in the course name.');
            hasError = true;
        }

        if (Grade === '') {
            setGradeError('Please select a grade.');
            hasError = true;
        }

        if (MainTopic === '') {
            setMainTopicError('Please select a main topic.');
            hasError = true;
        }

        if(selectedFile !== null){
            fileName = await uploadFile(selectedFile);
            setbackground(fileName);
        }

        if (!hasError) {
            var course = {
                name : Name,
                courseType: "normal",
                background : fileName,
                grade : Grade,
                mainTopic : MainTopic,
                startingDate: date.toLocaleString('en-GB').split(',')[0].trim(),
                stages : [
                    {   
                        name: `stage 1`,
                        startingDate: date.toLocaleString('en-GB').split(',')[0].trim(),
                        finishingDate: new Date(new Date(date).setMonth(new Date(date).getMonth() + 1)).toLocaleString('en-GB').split(',')[0].trim(),
                        activities : [],
                    }
                    
                ]
                
            };
            navigate('/Dashboard/activityType', {state:{course: course, courseFormatDate: courseFormatDate}});
        } };
    // Course cover Image
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
        setCourseDetail((prev) => ({ ...prev, fileInput1: file }));
    };

    const handleDrop = (e) => {
        e.preventDefault();

        const files = e.dataTransfer.files;
        const isValidFileType = Array.from(files).some(
            (file) => file.type === 'image/jpeg' || file.type === 'image/png'
        );

        if (isValidFileType) {
            const file = files[0];
            setSelectedFile(file);
            setCourseDetail((prev) => ({ ...prev, fileInput1: file }));
            // Handle the dropped file as needed
        } else {
            console.log('Invalid file type. Please drop a .jpg or .png file.');
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDateChange = (selectedDate) => {
        // const formattedDate = format(selectedDate, "dd/MM/yyyy");
        setDate(selectedDate);
        setcourseFormatDate(selectedDate);
    
        // setDate(event.target.value);
        // setCourseDetail((prev) => ({ ...prev, StartingDate: event.target.value }));
    };


    // Add Topics 
  const [editableFields, setEditableFields] = useState(['']);
  const handleAddField = () => {
    if (editableFields.length < 3) {
      setEditableFields([...editableFields, '']);
      setCourseDetail((prev) => ({ ...prev, OptionTopic: [...prev.OptionTopic, ''] }));
    }
  };
//   const handleAddField = () => {
//     if (editableFields.length < 3) {
//       setEditableFields([...editableFields, '']);
//     }
//   };

  const handleInputChange = (index, value) => {
    const updatedFields = [...editableFields];
    updatedFields[index] = value;
    setEditableFields(updatedFields);
    setCourseDetail((prev) => ({ ...prev, OptionTopic: updatedFields }));
  };
  // remove input 
  const handleRemoveField = (index) => {
    const updatedFields = [...editableFields];
    updatedFields.splice(index, 1);
    setEditableFields(updatedFields);
    setCourseDetail((prev) => ({ ...prev, OptionTopic: updatedFields }));
  };

  const  [CourseDetail, setCourseDetail] = useState(
    {
    Title: Name,
    StartingDate: date,
    Grade: '',
    MainTopic: '',
    OptionTopic: [''],
    fileInput1: null,
    instructor_id: inst_id,
});

const handleChange = (e) => {
    // setIsOpen(!isOpen);
    setDate(e);
  };

useEffect(() => {
    getClasses();
    localStorage.setItem('grade', Grade);
}, [Grade]);
localStorage.setItem('CourseDetail', JSON.stringify(CourseDetail))

    // selecte cover image of course from Libray PopUp
    const [CovermodalIsOpen, setCovermodalIsOpen] = useState(false);
    const openCoverModal = () => {
        setCovermodalIsOpen(true);
    };
    const CovercloseModal = () => {
        setbackground(ImageTitle)
        setCovermodalIsOpen(false);
    };


    return (
        <div className='w-full'>
            <form action="post" onSubmit={handleSubmit}>
                <div className='flex relative px-5 py-2 mb-2 ContentHeadingBg'>
                    <h3 className="text-xl">Create New Activity</h3>
                    <LoginHeading/>
                    </div>
                <div className='flex bg-white m-7 pb-5'>
                    <div className='flex-1'></div>
                    <div className='flex-1'>
                        <div className="flex flex-col items-center justify-center text-center">
                            <div className='text-center mt-5 mb-2'>
                                <div><h1><b>Cover Image</b></h1>
                                    <p className='text-sky-600'>Chose your course cover image</p></div>
                            </div>
                            <Modal
                                isOpen={CovermodalIsOpen}
                                onRequestClose={CovercloseModal}
                                className="Modal flex justify-center mt-40"
                            // overlayClassName="Overlay"
                            >
                                {/* Modal Content */}
                                <div className="flex sm:ms-20" >
                                    <div className="sm:ms-20 mx-20 sm:mx-0 drop-shadow-xl bg-sky-300 flex justify-center px-5" style={{ width: "", borderRadius: "20px 20px" }}>
                                        <div className="mt-5">
                                            <div className="App">
                                                <header className="App-header">
                                                    <h1>Choose your course cover image</h1>
                                                    <ImageList sx={{ width: 600, height: 450 }} cols={4} rowHeight={180}>
                                                        {itemData.map((item) => (
                                                            <ImageListItem className='relative' key={item.img}>
                                                                <img
                                                                className={`hover:scale-90 cursor-pointer ${item.title === ImageTitle ? "scale-90" : ""}`}
                                                                onClick={()=>setImageTitle(item.title)}
                                                                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                                                    alt={item.title}
                                                                    loading="lazy"
                                                                />
                                                                {item.title === ImageTitle && 
                                                                <span className='absolute top-5 left-5'><img src={SelectedIcon} alt="selected" /></span>
                                                                }
                                                                
                                                            </ImageListItem>
                                                        ))}
                                                    </ImageList>
                                                </header>
                                            </div>
                                            <h1 className={`sm:text-2xl `}></h1>
                                            <div className="flex justify-center gap-8 my-4">
                                                <button className='cursor-pointer' onClick={CovercloseModal}>Save</button>
                                                <button>
                                                    <label htmlFor="fileInput" className='cursor-pointer'>
                                                        <input
                                                            type="file"
                                                            accept='image/*'
                                                            id="fileInput"
                                                            className="absolute h-0 w-0 overflow-hidden"
                                                            onChange={handleFileChange} />

                                                        Upload from your gallery
                                                    </label>

                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                            <label
                                // htmlFor="fileInput"
                                className="InputBg w-100 relative cursor-pointer border-2 border-dashed border-gray-300 p-4 rounded-xl transition duration-300 hover:border-blue-500"
                                onDrop={handleDrop}
                                onClick={openCoverModal}
                                onDragOver={handleDragOver}>
                                {/* <input
                                    type="file"
                                    accept='image/*'
                                    id="fileInput"
                                    className="absolute h-0 w-0 overflow-hidden"
                                    onChange={handleFileChange}
                                /> */}
                                <div className="text-center md:flex gap-3">
                                    <div className='text-center mt-3 '>
                                        <div className='flex justify-center'><img src={DragDropIcon} alt="drag" /></div>
                                        <p>Drag & Drop</p>
                                    </div>
                                    <span className='mt-7'>OR</span>
                                    <div className='bg-white flex gap-2 p-3 px-5 rounded-xl'>
                                        <div className='px-5'>
                                            <div className='flex justify-center'><img src={UploadIcon} alt="uploadFile" /></div>
                                            <p>Upload a File</p>
                                        </div>
                                        <div className='px-5'>
                                            <div className='flex justify-center'><img src={LibraryIcon} alt="Library" /></div>
                                            <p>Library</p></div>
                                    </div>
                                </div>
                            </label>

                            {selectedFile && (
                                <div className="mt-4">
                                    <p className="text-lg font-semibold">Selected File:</p>
                                    <p className="text-gray-500">{selectedFile.name}</p>
                                </div>
                            )}
                        </div>
                        {/* Title , Date and Other Options is Here */}
                        <div className='border mt-5 rounded-xl p-5'>
                            <form action="post">
                                <div >
                                    <label htmlFor="Title">Title</label>
                                    <div className='bg-black'>
                                    <input className={`w-full border p-2 text-sky-600 ${nameError && 'border-red-500'}`} required value={Name} onChange={handleNameChange} type="text" placeholder='Course name' />
                                    </div>
                                    {nameError && <p className="text-red-500">{nameError}</p>}
                                </div>
                                {/* date */}
                                <div className='my-2'>
                                    <label htmlFor="date">Starting Date: </label>     
                                        <DatePicker className='text-sky-600' minDate={new Date()} selected={date} onChange={(date) => handleDateChange(date)} dateFormat={"dd/MM/yyyy"} />

                                       
                                </div>
                                <div className='flex flex-wrap gap-8 my-3'>
                                    {/* Select Grade */}
                                    <div>
                                    <div>
                                        {classes && Array.isArray(classes) && classes.length > 0 ? (
                                            <select
                                                className={`border w-32 p-2 SelectGradBg hover:bg-white ${gradeError && 'border-red-500'}`}
                                                required
                                                onChange={handleGradeChange}
                                                defaultValue='default'
                                                name=""
                                                id=""
                                            >
                                                <option value="default" disabled>Select Grade</option>
                                                {classes.map((classObj, index) => (
                                                    <option key={index} value={classObj.class_id}>{classObj.class_name}</option>
                                                ))}
                                            </select>
                                        ) : (
                                            <p>No classes available</p>
                                        )}
                                        {gradeError && <p className="text-red-500">{gradeError}</p>}
                                    </div>                                    </div>
                                    {/* Main Topic */}
                                   <div>
                                   <select className={`border p-2 SelectGradBg hover:bg-white ${mainTopicError && 'border-red-500'}`} required onChange={handleMainTopicChange} defaultValue='default' name="" id="">
                                        <option value="default" disabled>Main Topic</option>
                                        <option >Language Arts</option>
                                        <option >Math</option>
                                        <option >Science</option>
                                        <option >Social</option>
                                    </select>
                                    {mainTopicError && <p className="text-red-500">{mainTopicError}</p>}
                                   </div>
                                </div>
                                {/* Add Topic */}
                                <div className="grid grid-cols-2">
                                    {editableFields.map((field, index) => (
                                        <div className={`border flex rounded rounded-10r  my-5 mr-5 text-sky-600 ${field ? 'bg-orange-50' : 'bg-white'}`} key={index} style={{ position: 'relative' }}>
                                            <input
                                                className="p-2 w-32  focus:outline-none"
                                                value={field}
                                                placeholder="Add Topic"
                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                                style={{ backgroundColor: 'inherit' }}
                                            />
                                            {index === editableFields.length - 1 ? (
                                                <button type='button' className="text-2xl mx-2" onClick={handleAddField}>+</button>
                                            ) : (
                                                <button type='button'
                                                    className="text-2xl mx-2"
                                                    onClick={() => handleRemoveField(index)}
                                                    style={{ position: 'absolute', right: 0 }}
                                                >
                                                    x
                                                </button>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <p className='text-gray-400 ms-5'>Add max 3 topics</p>
                            </form>
                        </div>
                        <div className='flex text-center mt-10 items-center justify-center gap-3'>
                            <Link to='/Dashboard/CreateCourse'><button className='BackBtn flex p-2 px-7 hover:bg-blue-200'><b> Back </b><img className='ml-5 mt-1' src={BackIcon} alt="Back" /></button></Link>
                            <button type='submit' className='BackBtn flex p-2 px-7 hover:bg-blue-200' ><img className='mr-5 mt-1' src={NextIcon} alt="Next" /><b> Next</b></button>
                        </div>
                    </div>
                    <div className='flex-1'></div>
                </div>
            </form>
            {/* <NewCourse></NewCourse> */}
        </div>

    );
};

export default CoverImage;
// import React, { useState } from 'react';

// const CourseDataForm = () => {
//   // State to store course data
//   const [courseData, setCourseData] = useState([]);

//   // State for current stage and activity input
//   const [currentStageName, setCurrentStageName] = useState('');
//   const [currentStartDate, setCurrentStartDate] = useState('');
//   const [currentEndDate, setCurrentEndDate] = useState('');
//   const [currentActivity, setCurrentActivity] = useState(null);
//   const [currentFile, setCurrentFile] = useState('');
//   const [activities, setActivities] = useState([]);
  
//   // State for error messages
//   const [stageError, setStageError] = useState('');
//   const [activityError, setActivityError] = useState('');

//   // Handler for adding new stage
//   const handleAddStage = () => {
//     if (!currentStageName || activities.length === 0) {
//       setStageError(!currentStageName ? 'Stage name is required.' : '');
//       setActivityError(activities.length === 0 ? 'At least one activity is required.' : '');
//       return;
//     }
    
//     setCourseData([
//       ...courseData,
//       {
//         name: currentStageName,
//         startDate: currentStartDate,
//         endDate: currentEndDate,
//         activities: activities
//       }
//     ]);
//     setCurrentStageName('');
//     setCurrentStartDate('');
//     setCurrentEndDate('');
//     setActivities([]);
//     setStageError('');
//     setActivityError('');
//   };

//   // Handler for adding new activity
//   const handleAddActivity = () => {
//     if (!currentActivity) {
//       setActivityError('Activity name is required.');
//       return;
//     }

//     setActivities([
//       ...activities,
//       {
//         name: currentActivity,
//         file: currentFile,
//         complete: false
//       }
//     ]);
//     setCurrentActivity('');
//     setCurrentFile('');
//     setActivityError('');
//   };

//   // Handler for marking activity as complete
//   const handleCompleteActivity = (index) => {
//     const updatedActivities = [...activities];
//     updatedActivities[index].complete = !updatedActivities[index].complete;
//     setActivities(updatedActivities);
//   };

//   // Render
//   return (
//     <div>
//       <h2>Course Data Form</h2>
//       <div>
//         <input
//           type="text"
//           value={currentStageName}
//           onChange={(e) => setCurrentStageName(e.target.value)}
//           placeholder="Enter Stage Name"
//         />
//         <input
//           type="date"
//           value={currentStartDate}
//           onChange={(e) => setCurrentStartDate(e.target.value)}
//           placeholder="Enter Start Date"
//         />
//         <input
//           type="date"
//           value={currentEndDate}
//           onChange={(e) => setCurrentEndDate(e.target.value)}
//           placeholder="Enter End Date"
//         />
//         <button onClick={handleAddStage}>Add Stage</button>
//         {stageError && <p style={{ color: 'red' }}>{stageError}</p>}
//       </div>
//       <div>
//         <input
//           type="text"
//           value={currentActivity}
//           onChange={(e) => setCurrentActivity(e.target.value)}
//           placeholder="Enter Activity Name"
//         />
//         <input
//           type="file"
//           onChange={(e) => setCurrentFile(e.target.files[0])}
//         />
//         <button onClick={handleAddActivity}>Add Activity</button>
//         {activityError && <p style={{ color: 'red' }}>{activityError}</p>}
//       </div>
//       <div>
//         {activities.map((activity, index) => (
//           <div key={index}>
//             <input
//               type="checkbox"
//               checked={activity.complete}
//               onChange={() => handleCompleteActivity(index)}
//             />
//             <span>{activity.name}</span>
//           </div>
//         ))}
//       </div>
//       <div>
//         <h3>Course Data:</h3>
//         <pre>{JSON.stringify(courseData, null, 2)}</pre>
//       </div>
//     </div>
//   );
// };

// export default CourseDataForm;




