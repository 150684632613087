import React, { useEffect, useState } from "react";


const AnalyticsTable = ({ selectedCourse, currentStudents,studentId }) => {
    const [analyticsData, setAnalyticsData] = useState([]);
  
    useEffect(() => {
      const fetchData = async (student_id) => {
        try {
          let url = `https://gamizign.co:8000/analytics/courses/students/${student_id}`;
          if (typeof selectedCourse === 'object' && !Array.isArray(selectedCourse)) {
            url += `?course_id=${selectedCourse.course_id}`;
          }
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();
          return data;
        } catch (error) {
          console.error('Error fetching data:', error);
          return null;
        }
      };
  
      const fetchAnalyticsData = async () => {
        const results = await Promise.all(currentStudents.map(student => fetchData(student.student_id)));
        setAnalyticsData(results);
      };
  
      fetchAnalyticsData();
    }, [selectedCourse, currentStudents]);
  
    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
    
        return `${String(hours).padStart(2, '0')}h:${String(minutes).padStart(2, '0')}m:${String(
          Math.floor(remainingSeconds)
        ).padStart(2, '0')}s`;
      }
  
    return (
      <div className="relative overflow-x-auto mt-5 max-w-100">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Name</th>
              <th scope="col" className="px-6 py-3">E-mail Address</th>
              <th scope="col" className="px-6 py-3">Score</th>
              <th scope="col" className="px-6 py-3">Accuracy</th>
              <th scope="col" className="px-6 py-3">Sessions</th>
              <th scope="col" className="px-6 py-3">Average Time</th>
            </tr>
          </thead>
          <tbody>
            {currentStudents && currentStudents.length > 0 ? (
              currentStudents.map((student, studentIndex) => {
                const analytics = analyticsData[studentIndex];
                return (
                  <tr key={studentIndex} className={` ${studentId === student.student_id ? 'bg-red-200' : 'even:bg-white even:dark:bg-gray-900 odd:bg-gray-50'} odd:dark:bg-gray-800 border-b dark:border-gray-700`}>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {student.firstName} {student.lastName}
                    </td>
                    <td className="px-6 py-4">{student.email}</td>
                    <td className="px-6 py-4">
                      {analytics ? (
                        analytics.score.toFixed(0)
                      ) : (0)}
                    </td>
                    <td className="px-6 py-4">
                      {analytics?.correctAnswers > 0 ? (
                        <div>
                          {(
                            (analytics.correctAnswers /
                              (analytics.correctAnswers + analytics.incorrectAnswers)) * 100
                          ).toFixed(2)}%
                        </div>
                      ) : (<>0.00%</>)}
                    </td>
                    <td className="px-6 py-4">
                      {analytics ? (
                        analytics.sessions
                      ) : (0)}
                    </td>
                    <td className="px-6 py-4">
                      {analytics?.time > 0 ? 
                       
                          formatTime(
                            analytics.time /
                            analytics.sessions
                          )
                        
                       : (<>00h:00m:00s</>)}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="6" className="px-6 py-4 text-center">No students in this course with "<b></b>"</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };
  export default AnalyticsTable;