import React, { useEffect, useState, useRef } from 'react';
import Plotly from 'plotly.js-dist';

const PlotlyChart = ({ coursesData, colors, highlight }) => {
  const [courseDetails, setCourseDetails] = useState([]);
  const chartRef = useRef(null);

  useEffect(() => {
    const details = coursesData?.map(course => {
      let totalCorrectAnswers = 0;
      let totalIncorrectAnswers = 0;
      let totalGameWon = 0;
      let totalSessions = 0;

      course.stages.forEach(stage => {
        stage.activities.forEach(activity => {
          activity.analyticsList.forEach(analytic => {
            totalCorrectAnswers += analytic.correctAnswers;
            totalIncorrectAnswers += analytic.incorrectAnswers;
            totalGameWon += analytic.gamesWon;
            totalSessions += analytic.sessions;
          });
        });
      });

      const accuracy = totalCorrectAnswers > 0
        ? Math.round((totalCorrectAnswers / (totalCorrectAnswers + totalIncorrectAnswers)) * 100)
        : 2;
      const GameWon = totalGameWon > 0
        ? Math.round((totalGameWon / totalSessions) * 100)
        : 2;

      return {
        name: course.name,
        correctAnswers: totalCorrectAnswers,
        incorrectAnswers: totalIncorrectAnswers,
        accuracy: accuracy,
        gameWon: GameWon,
      };
    });

    setCourseDetails(details);
  }, [coursesData]);

  useEffect(() => {
    let detailsToRender = courseDetails;

    if (highlight !== null && highlight >= 0 && highlight < courseDetails.length) {
      detailsToRender = [courseDetails[highlight]];
    }

    if (chartRef.current) {
      const traces = detailsToRender.map((item, index) => ({
        y: [0,item.accuracy, item.gameWon],
        type: 'box',
        name: item.name,
        marker: {
          color: colors[index % colors.length],
        },
      }));

      const layout = {
        title: 'Accuracy Per Course',
        showlegend: false, // Remove the legend
      };

      const config = {
        modeBarButtonsToRemove: ['toImage', 'pan2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian'],
        displaylogo: false,
      };

      Plotly.newPlot(chartRef.current, traces, layout, config).then(() => {
        if (highlight !== null && highlight >= 0 && highlight < courseDetails.length) {
          Plotly.Fx.hover(chartRef.current, [
            {
              curveNumber: 0, // Since we only have one item in detailsToRender
              pointNumber: 0,
            },
          ]);
        }
      });

      return () => {
        if (chartRef.current) {
          Plotly.purge(chartRef.current);
        }
      };
    }
  }, [coursesData, highlight, colors, courseDetails]);

  return <div id="myDiv" ref={chartRef}></div>;
};

export default PlotlyChart;